<template>
    <v-container>
        <v-row>

            <v-col>
                <v-timeline
                        dense
                        clipped
                >
                    <v-timeline-item
                            v-for="(item,index) in logs"
                            :key="index"
                            small
                            fill-dot
                            :color="item.action|resolveColor"
                    >
                        <v-card class="pa-1 ma-0">
                            <v-card-text class="pa-0">
                                <v-list-item class="grow">
                                    <v-list-item-avatar color="grey darken-3">
                                        <img src="https://i.pravatar.cc/64">
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title v-if="item.user != null">
                                            {{item.user.name}}
                                            {{item.user.family}}
                                            <v-spacer></v-spacer>
                                            <span class="caption">
                                                {{item.user.email}}
                                            </span>

                                        </v-list-item-title>

                                    </v-list-item-content>


                                </v-list-item>

                            </v-card-text>
                            <v-card-text class="pa-0">
                                <v-list>
                                    <div v-for="(sentence,i) in item.description" :key="i">
                                        <v-divider></v-divider>
                                        <v-list-item>
                                            {{sentence}}
                                        </v-list-item>
                                    </div>

                                </v-list>

                            </v-card-text>
                            <v-card class="elevation-0">
                                <v-divider></v-divider>
                                <v-card-text>
                                    <template v-if="item.type=='session'">
                                        <v-row class="pa-0 ma-0">
                                            <v-col class="pa-0 ma-0" v-if="item.loggable.user != null">
                                                {{item.type|resolveItemType}}
                                                {{item.loggable.user.full_name}}
                                                {{item.loggable.user.email}}
                                            </v-col>
                                            <v-spacer></v-spacer>
                                            <!--<v-btn @click="$router.push({name:'clientDetail',params:{clientId:item.loggable.user.id}})"-->
                                            <!--small class="blue white&#45;&#45;text" text>-->
                                            <!--رفتن به پروفایل کاربر-->
                                            <!--</v-btn>-->
                                        </v-row>

                                    </template>
                                    <template v-if="item.type=='coupon'">
                                        <v-row class="pa-0 ma-0">
                                            <v-col class="pa-0 ma-0">
                                                {{item.type|resolveItemType}}
                                                {{item.loggable.code}}
                                            </v-col>
                                            <v-spacer></v-spacer>
                                            <!--<v-btn @click="$router.push({name:'coupons'})"-->
                                            <!--small class="blue white&#45;&#45;text" text>-->
                                            <!--رفتن به کدهای تخفیف-->
                                            <!--</v-btn>-->
                                        </v-row>

                                    </template>
                                    <template v-if="item.type=='user'">
                                        <v-row class="pa-0 ma-0">
                                            <v-col class="pa-0 ma-0">
                                                {{item.type|resolveItemType}}
                                                {{item.loggable.name}}
                                                {{item.loggable.family}}
                                                {{item.loggable.email}}

                                            </v-col>
                                            <v-spacer></v-spacer>
                                            <!--<v-btn @click="$router.push({name:'clientDetail',params:{clientId:item.loggable.id}})"-->
                                            <!--small class="blue white&#45;&#45;text" text>-->
                                            <!--رفتن به پروفایل کاربر-->
                                            <!--</v-btn>-->
                                        </v-row>

                                    </template>
                                </v-card-text>
                            </v-card>

                            <!--<v-card class="elevation-0" v-else>-->
                            <!--<v-divider></v-divider>-->
                            <!--<v-card-text>-->
                            <!---->
                            <!--</v-card-text>-->
                            <!--</v-card>-->

                        </v-card>

                        <!--<v-row-->
                        <!--align="center"-->
                        <!--justify="end"-->
                        <!--&gt;-->
                        <!--<v-icon class="mr-1">-->
                        <!--mdi-heart-->
                        <!--</v-icon>-->
                        <!--<span class="subheading mr-2">256</span>-->
                        <!--<span class="mr-1">·</span>-->
                        <!--<v-icon class="mr-1">-->
                        <!--mdi-share-variant-->
                        <!--</v-icon>-->
                        <!--<span class="subheading">45</span>-->
                        <!--</v-row>-->

                    </v-timeline-item>
                </v-timeline>
            </v-col>

            <v-col cols="12">
                <div class="text-center">
                    <v-pagination
                            v-model="page"
                            :length="total"
                    ></v-pagination>
                </div>
            </v-col>
        </v-row>
    </v-container>

</template>

<script>
    /*eslint-disable*/
    export default {
        created() {
            this.getAdminLog();
        },
        methods: {
            getAdminLog() {
                this.$store.dispatch('getAdminLog', {page: this.page}).then((resp) => {
                    this.total = resp.data.total;
                    this.logs = resp.data.logs;
                })
            }
        },
        data() {
            return {
                page: 1,
                total: 1,
                logs: []
            }
        },
        watch: {
            page() {
                this.getAdminLog();
            }
        },
        filters: {
            resolveColor(action) {
                let color = '';
                switch (action) {
                    case 'create':
                        color = 'green';
                        break;
                    case 'update':
                        color = 'blue darken-1';
                        break;
                    case 'delete':
                        color = 'red';
                        break;
                }
                return color
            },
            resolveItemType(type) {
                let resolved = '';
                switch (type) {
                    case 'user':
                        resolved = 'کاربر';
                        break;
                    case 'session':
                        resolved = 'جلسه';
                        break;
                    case 'coupon':
                        resolved = 'کد تخفیف';
                        break;
                }
                return resolved
            }
        }
    }
</script>

<style scoped>

</style>