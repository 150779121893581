<template>
    <v-container v-if="session!= null">
        <v-alert
                dark
                dense
                class="primary mt-5 py-0 elevation-1"
        >
            <!--<v-row align="center">-->
            <!---->
            <!--<v-col class="grow">-->
            <!--تا {{session.diff_time}} دیگر جلسه ۴۵ دقیقه ای مشاوره شما با-->
            <!--<strong class="secondary&#45;&#45;text">{{ session.user.full_name }}</strong>-->
            <!--آغاز می شود. لطفا پیش از شروع جلسه، از کیفیت سرعت اینترنت خود اطمینان حاصل فرمایید.-->
            <!--</v-col>-->
            <!--</v-row>-->
            <v-row align="center">
                <template v-if="isPassed == 0">
                    <v-col class="grow">
                        تا {{session.diff_time}} دیگر جلسه {{session.doctor.full_name}} با <strong
                            class="secondary--text"> {{
                        session.user.full_name}}</strong> آغاز می شود.
                    </v-col>
                </template>

                <template v-if="isPassed == 1">
                    <v-col class="grow">
                        جلسه {{ session.doctor.full_name}} با <strong class="secondary--text">{{
                        session.user.full_name}}</strong>
                        در حال برگزاری می باشد.
                    </v-col>
                </template>
                <template v-if="isPassed == 2">
                    <v-col class="grow">
                        جلسه {{ session.doctor.full_name}} با <strong class="secondary--text">{{
                        session.user.full_name}}</strong>
                        برگزار شده است.
                    </v-col>
                </template>
            </v-row>
        </v-alert>
        <v-card>
            <v-card-text class="text-center">
                <v-avatar
                        class="profile"
                        color="grey"
                        size="164"
                >
                    <img :src="session.doctor.avatar"/>
                </v-avatar>
                <div class="pa-2 font-weight-bold">
                    {{session.doctor.full_name}}
                </div>
            </v-card-text>
        </v-card>
        <CounselorClientProfile :client="clientStartSession" :cycles="[]"></CounselorClientProfile>

        <v-card>
            <v-card-title>
                دوره درمان
            </v-card-title>
            <v-card-text>
                <v-expansion-panels>
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            موضوع : {{session.cycle.heading}}
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <div>شرح :</div>
                            {{session.cycle.problem_description}}
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-card-text>
        </v-card>

    </v-container>
</template>

<script>
    /*eslint-disable*/
    import CounselorClientProfile from '@/components/clients/CounselorClientProfile';
    // import Countdown from "../Countdown";
    import moment from 'moment'
    // import Net from "../Net";
    // import {mapGetters} from 'vuex';

    export default {
        data() {
            return {
                isPassed: 0,
                currentDateTime: null,
                startDateTime: null,
                endDateTime: null,
                loadingG2M: false,
                loadingBBB: false,
                startSession: null,
                clientStartSession: null,
                nonce: null,
                session: null
            }
        },
        components: {
            CounselorClientProfile,
            // Countdown,
            // Net,
            // EnterSessionBtn
        },
        computed: {
            // ...mapGetters({
            // startSession: 'getStartSession',
            // clientStartSession: 'getClientStartSession'
            // })
        },
        created() {
            this.getSessionDetails();

            // this.getClient();
            // this.getCountdownInputs();
            // console.log('Counselor start session: ', this.startSession)
        },
        methods: {
            getSessionDetails() {

                this.$store.dispatch('adminGetSession', this.$route.params.sessionId).then((resp) => {


                    this.clientStartSession = resp.data.user;
                    this.session = resp.data;
                    console.log(this.session);


                    if (this.session.fixed_date_time_g != null && this.session.state != 'used') {
                        let sessionTime = moment(this.session.fixed_date_time_g, 'YYYY-MM-DD HH:mm');
                        let sessionOffset = moment(this.session.fixed_date_time_g, 'YYYY-MM-DD HH:mm');
                        sessionOffset.add(45, 'minutes');
                        let now = moment();
                        if (now.isBefore(sessionTime)) {
                            this.isPassed = 0;
                        } else if (now.isBetween(sessionTime, sessionOffset)) {
                            this.isPassed = 1;
                        } else if (now.isAfter(sessionOffset)) {
                            this.isPassed = 2;
                        }
                    } else if (this.session.state == 'used') {
                        this.isPassed = 2;
                    }

                });
            },
            getCountdownInputs() {
                this.$store.dispatch('getStartSession', this.$route.params.sessionId).then((data) => {
                    this.startSession = data.data;
                    this.currentDateTime = moment().utc().format('YYYY/MM/DD HH:MM');
                    this.startDateTime = this.startSession.countDownDate;
                    this.endDateTime = moment(this.startDateTime).add(45, 'm').toDate();
                    // this.getClient();

                    this.nonce = data.data.nonce;
                })
                // console.log('end', this.endDateTime);
            },
            getClient() {
                this.$store.dispatch('getClientStartSession', this.startSession.customerId).then((data) => {
                    this.clientStartSession = data.data;
                })
            },
            joinG2M() {
                this.loadingG2M = true;
                this.$store.dispatch('joinG2M', this.$route.params.sessionId).then((data) => {
                    window.open(data.data, '_blank');
                    this.loadingG2M = false;
                })
            },
            joinBBB() {
                this.loadingBBB = true;
                this.$store.dispatch('joinBBB', this.$route.params.sessionId).then((data) => {
                    window.open(data.data, '_blank');
                    this.loadingBBB = false;
                })
            }
        }
    }
</script>

<style>

</style>