<template>
    <v-container fluid>
        <v-card id="clientDetail" class="my-8" outlined>
            <v-toolbar flat class="mb-12">
                <template v-slot:extension>
                    <v-tabs
                            v-model="tabs"
                            fixed-tabs
                    >
                        <v-tabs-slider></v-tabs-slider>
                        <v-tab
                                href="#mobile-tabs-5-1"
                                class="primary--text"
                        >
                            <v-icon>fas fa-user</v-icon>
                            <p class="mt-4 px-3 text--secondary">مشخصات</p>
                        </v-tab>

                        <v-tab
                                href="#mobile-tabs-5-2"
                                class="primary--text"
                        >
                            <v-icon>fas fa-list-alt</v-icon>
                            <p class="mt-4 px-3 text--secondary">جلسات</p>
                        </v-tab>

                        <v-tab
                                href="#mobile-tabs-5-3"
                                class="primary--text"
                        >
                            <v-icon>fas fa-money-bill</v-icon>
                            <p class="mt-4 px-3 text--secondary">بسته‌ها</p>
                        </v-tab>


                      <v-tab
                          href="#questions"
                          class="primary--text"
                      >
                        <v-icon>fas fa-question</v-icon>
                        <p class="mt-4 px-3 text--secondary">سوالات و تست ها</p>
                      </v-tab>



                        <v-tab
                                href="#request-session"
                                class="primary--text"
                        >
                            <v-icon>fas fa-tv</v-icon>
                            <p class="mt-4 px-3 text--secondary">درخواست جلسه</p>
                        </v-tab>
                        <v-tab
                                href="#conversations"
                                class="primary--text"
                        >
                            <v-icon>fas fa-tv</v-icon>
                            <p class="mt-4 px-3 text--secondary">گفتگو ها</p>
                        </v-tab>
                        <!--<v-tab-->
                        <!--href="#mobile-tabs-5-4"-->
                        <!--class="primary&#45;&#45;text"-->
                        <!--&gt;-->
                        <!--<v-icon>fas fa-user-friends</v-icon>-->
                        <!--<p class="mt-4 px-3 text&#45;&#45;secondary">مشاوره</p>-->
                        <!--</v-tab>-->
                    </v-tabs>
                </template>
            </v-toolbar>

            <v-tabs-items v-model="tabs">
                <!--<v-tab-item-->
                <!--v-for="(comp, index) in comps"-->
                <!--:key="index"-->
                <!--:value="'mobile-tabs-5-' + (index+1)"-->
                <!--&gt;-->

                <!--<v-card flat class="pb-12">-->
                <!--<keep-alive>-->
                <!--<component :is="comp" ></component>-->

                <!--</keep-alive>-->
                <!--</v-card>-->
                <!--</v-tab-item>-->

                <v-tab-item
                        :value="'mobile-tabs-5-1'"
                >
                    <v-card flat class="pb-12">
                        <clientProfile></clientProfile>
                    </v-card>
                </v-tab-item>
                <v-tab-item
                        :value="'mobile-tabs-5-2'"
                >
                    <AdminClientSessionTabs></AdminClientSessionTabs>
                </v-tab-item>
                <v-tab-item
                        :value="'mobile-tabs-5-3'"
                >
                    <v-card flat class="pb-12">
                        <AdminOrders></AdminOrders>
                    </v-card>
                </v-tab-item>
                <v-tab-item
                        :value="'mobile-tabs-5-4'"
                >
                    <v-card flat class="pb-12">
                        <AdminCalendar v-if="clientIsLoaded" :customer="$route.params.clientId" :profile="profile"
                                       :activeCounselor="activeCounselorId"></AdminCalendar>
                    </v-card>
                </v-tab-item>
                <v-tab-item
                        :value="'request-session'"
                >
                    <v-card flat class="pb-12">

                        <AdminClientCycles
                                v-if="clientIsLoaded" :customer="$route.params.clientId" :profile="profile"
                                :getProfile="false"
                        ></AdminClientCycles>
                    </v-card>
                </v-tab-item>
                <v-tab-item
                        :value="'conversations'"
                >
                    <AdminClientMessenger :clientId="$route.params.clientId" :mode="'admin-client'"></AdminClientMessenger>
                </v-tab-item>



              <v-tab-item
                        :value="'questions'"
                >

                    <AdminQuestions/>
                </v-tab-item>
            </v-tabs-items>
        </v-card>
    </v-container>
</template>

<script>
    /*eslint-disable*/
    import clientProfile from "../../components/profiles/clientProfile";
    import AdminSessions from "../../components/sessions/AdminSessions";
    import AdminClientSessionTabs from "../../components/sessions/AdminClientSessionTabs";

    import AdminOrders from "../../components/orders/AdminClientPayments";
    import AdminCalendar from "../../components/orders/AdminCalendar";
    import {EventBus} from "../../event-bus";
    import AdminClientCycles from "./AdminClientCycles";
    import {mapGetters} from 'vuex';
    import AdminClientMessenger from '@/components/chat/AdminClientMessenger'
    import AdminQuestions from "@/components/Admin/questions/AdminQuestions";

    export default {
        created() {
            EventBus.$on('clientProfileUpdated', this.updateClientInfo);

        },
        beforeDestroy() {
            EventBus.$off('clientProfileUpdated', this.updateClientInfo);
        },
        mounted() {

        },
        computed: {
            ...mapGetters({
                profile: 'getClientProfile'
            })
        },
        data() {
            return {

                clientIsLoaded: false,
                activeCounselorId: null,
                tabs: '',
                client: {},
                comps: [
                    clientProfile,
                    AdminSessions,
                    AdminOrders,
                    AdminCalendar
                ]
            }
        },
        components: {
          AdminQuestions,
            AdminClientMessenger,
            AdminClientSessionTabs,
            AdminClientCycles,
            clientProfile,
            AdminSessions,
            AdminOrders,
            AdminCalendar
        },
        methods: {
            updateClientInfo() {
                // let client = this.$store.getters.getClientProfile;
                // this.profile = client;
                this.activeCounselorId = this.profile.activeCounselorId;
                this.clientIsLoaded = true;
            }
        },
    }
</script>

<style>
    #clientDetail .v-toolbar__content {
        height: 0 !important;
    }

    #clientDetail header {
        height: auto !important;
        background-color: #e0e0e0;
    }
</style>