<template>
    <v-container class="fill-height" fluid>
        <v-dialog v-model="imageModal" width="800px" scrollable>
            <v-card class="pa-0 ma-0">
                <v-card-text class="pa-0 ma-0">
                    <v-img :src="modalImg" v-if="modalImg!=null"></v-img>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-row class="no-gutters mt-10 elevation-2 " align="center" justify="center">

            <v-col
                    cols="12" sm="3"
                    class="flex-grow-1 flex-shrink-0"
            >
                <v-responsive
                        class="overflow-y-auto fill-height"
                        height="500"
                        style="border-left:1px solid rgba(0,0,0,.1);"
                >

                    <MessengerConversations
                            :conversations="contacts"
                            @conversationSelected="setActiveChat"
                            @conversationDeSelected="deselectActiveChat"
                    >
                    </MessengerConversations>

                </v-responsive>
            </v-col>
            <v-col

                    class="flex-grow-1 flex-shrink-0"

            >
                <v-responsive
                        v-if="getActiveChat.id!=null"
                        class="overflow-y-hidden fill-height"
                        height="500"
                >
                    <v-card
                            flat
                            class="d-flex flex-column fill-height"

                    >
                        <v-card-title class="pa-3">
                            {{getActiveChat.title}}
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-card-text class="flex-grow-1 overflow-y-auto" id="messages-window">
                            <div id="messages-list">
                                <div class="text-center" style="position:relative;">
                                    <div v-if="!hasNextPage">
                                        <v-chip small class="grey--text lighten-4">ابتدای پیام ها</v-chip>
                                    </div>
                                    <div v-else>
                                        <v-btn small text rounded class="primary" @click="handleScroll"
                                               :loading="loadingPreviousMessages">پیامهای پیشین
                                        </v-btn>
                                    </div>
                                </div>

                                <template v-for="msg in getMessages">
                                    <template v-if="msg.type=='text'">
                                        <TextMessage :mode="mode" :msg="msg" :key="msg.id"></TextMessage>
                                    </template>

                                    <template v-else-if="msg.type=='fileupload'">
                                        <FileUpload :mode="mode" :msg="msg" :key="msg.id"></FileUpload>
                                    </template>

                                    <template v-else-if="msg.type=='file'">
                                        <FileMessage :mode="mode" @magnify="showImage" :msg="msg"
                                                     :key="msg.id"></FileMessage>
                                    </template>

                                </template>
                            </div>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-text class="flex-shrink-1">

                            <v-textarea
                                    class="pa-0 ma-0"
                                    v-model="messageForm.contents"
                                    placeholder="نوشتن پیام..."
                                    type="text"
                                    no-details
                                    append-outer-icon="send"
                                    @keyup.enter="sendMessage"
                                    @click:append-outer="sendMessage"
                                    hide-details
                                    no-resize

                                    :rows="rowCount"
                            >

                                <template v-slot:prepend>
                                    <label>
                                        <v-icon large>mdi-attachment</v-icon>
                                        <input style="display: none;" type="file" id="file" ref="fileInput"
                                               v-on:change="handleFileUpload()"/>
                                    </label>
                                    <!--<button v-on:click="submitFile()">Submit</button>-->
                                </template>

                            </v-textarea>
                        </v-card-text>

                    </v-card>
                </v-responsive>

                <v-responsive v-else
                              class="overflow-y-hidden fill-height"
                              height="500">
                    <v-card
                            flat
                            class="d-flex flex-column fill-height"

                    >
                        <v-row align="center" justify="center">
                            <v-col>
                                <v-card-text class="text-center fill-height">
                                    <v-chip class="grey--text text--darken-1 grey  lighten-3" label>یک گفتگو انتخاب
                                        نمایید
                                    </v-chip>
                                </v-card-text>
                            </v-col>
                        </v-row>

                    </v-card>
                </v-responsive>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    /*eslint-disable*/
    import MessengerConversations from '@/components/chat/MessengerConversations';

    import TextMessage from '@/components/chat/TextMessage';
    import FileUpload from '@/components/chat/FileUpload';
    import FileMessage from '@/components/chat/FileMessage';
    import {EventBus} from "../../event-bus";
    import moment from 'moment-jalaali';
    import {mapGetters} from 'vuex';

    export default {
        created() {
            this.$store.dispatch(this.contactsGetAction).then((resp) => {
                this.contacts = resp.data.contacts;
            })
        },
        props: {
            mode: {
                default: 'customer'
            },
            contactsGetAction: {
                default: 'userGetContacts'
            },
            sendMessageAction: {
                default: 'userSendConversationMessage'
            },
            getConversationAction: {
                default: 'userGetConversationMessages'
            },
        },
        mounted() {

        },

        components: {
            MessengerConversations,
            TextMessage,
            FileUpload,
            FileMessage
        },
        computed: {
            ...mapGetters(['getMessages', 'getActiveChat', 'getConversationId', 'getScrollToBottom']),
            options() {
                return {
                    duration: this.duration,
                    offset: this.offset,
                    easing: 'easeInOutQuad',
                }
            },

            rowCount() {
                if (this.messageForm.contents.length) {
                    let ratio = Math.ceil(this.messageForm.contents.length / 150);
                    return (ratio < 5) ? ratio : 5;
                }
                return 1;
            }
        },
        methods: {
            handleNewMessage(payload) {
                console.log('payload', payload);
            },
            showImage(imgData) {
                this.imageModal = true;
                this.modalImg = imgData;
            },
            smoothScroll() {

                // window.requestAnimationFrame(this.smoothScroll);
            },
            scrollToBottom() {


                let messageElem = document.getElementById('messages-list');

                document.getElementById('messages-window').scrollTop = messageElem.clientHeight;

            },
            addListeners() {
                let messageWindow = document.getElementById('messages-window');
                messageWindow.addEventListener('scroll', this.handleScroll);

            },
            handleScroll(event) {
                if (event.target.scrollTop <= 10) {
                    if (!this.loadingPreviousMessages && this.hasNextPage) {
                        this.loadingPreviousMessages = true;

                        this.page++;
                        this.getConversationMessages(this.activeChat);

                    }
                }
            },
            sendMessage() {
                let options = {
                    id: this.activeConversation.id,
                    payload: {
                        contents: this.messageForm.contents,
                        type: 'text'
                    }
                };


                this.messageForm.date = new moment().format('jYYYY/jMM/jDD HH:mm');
                let clone = JSON.parse(JSON.stringify(this.messageForm));

                this.messages.push(clone);

                this.$nextTick().then(() => {
                    this.scrollToBottom();
                })

                this.messageForm = {
                    contents: "",
                    is_mine: true,
                    date: "",
                    type: 'text'
                };
                this.$store.dispatch(this.sendMessageAction, options).then((resp) => {
                    console.log(resp);
                })

            },
            getConversationMessages(chat) {
                EventBus.$emit('setLoading', true);
                let options = {
                    id: chat.id,
                    page: this.page
                };

                // let windowElem = document.getElementById('messages-window');
                let messageElem = document.getElementById('messages-list');
                if (messageElem != null) {
                    // console.log('height', messageElem.clientHeight);
                    this.$nextTick(() => {
                        this.lastHeight = messageElem.clientHeight
                    })
                } else {

                }
                this.$store.dispatch(this.getConversationAction, options).then((resp) => {
                    if (resp.data.pagination.next_page_url != null) {
                        this.hasNextPage = true;
                    } else {
                        this.hasNextPage = false;
                    }
                    this.activeConversation.id = resp.data.conversation.id;
                    this.messages.unshift(...resp.data.messages.reverse());
                    this.$store.commit('SET_MESSAGES', {
                        messages: this.messages,
                        conversationId: resp.data.conversation.id
                    });
                    this.loadingPreviousMessages = false;
                    EventBus.$emit('setLoading', false);

                    this.$nextTick().then(() => {
                        let messageElem = document.getElementById('messages-list');
                        let top = messageElem.clientHeight - this.lastHeight;
                        document.getElementById('messages-window').scrollTop = top;
                        this.addListeners();
                    });
                })
            },

            setActiveChat(chat) {
                this.$store.commit('SET_ACTIVE_CHAT', chat);
                this.$store.commit('SET_MESSAGES', {messages: [], conversationId: null});
                this.messages = [];
                this.page = 1;
                this.hasNextPage = false;
                this.lastHeight = 0;
                this.loadingPreviousMessages = false;
                this.getConversationMessages(chat);
                this.activeChat.id = chat.id;
                this.activeChat.title = chat.title;
            },
            deselectActiveChat() {
                this.$store.commit('SET_ACTIVE_CHAT', {id: null, title: null});
                this.$store.commit('SET_MESSAGES', {messages: [], conversationId: null});
                this.activeChat.id = null;
                this.activeChat.title = null;
            },
            handleFileUpload() {

                this.file = this.$refs.fileInput.files[0];


                let now = new Date();
                let message = {
                    contents: this.file.name,
                    is_mine: true,
                    created_at: new moment().format('jYYYY/jMM/jDD HH:mm'),
                    type: 'fileupload',
                    mime: this.file.type,
                    body: this.file,
                    conversation: this.activeConversation
                };
                this.messages.push(message);
            }
        },

        data: () => ({
            contacts: [],
            modalImg: null,
            imageModal: false,
            lastHeight: 0,
            hasNextPage: false,
            loadingPreviousMessages: false,
            activeConversation: {
                id: null
            },
            page: 1,

            activeChat: {
                id: null,
                title: null
            },


            messages: [],
            messageForm: {
                contents: "",
                is_mine: true,
                date: "11:11am",
                type: 'text'
            }
        }),

        watch: {
            getScrollToBottom(newVal) {
                if (newVal) {
                    this.$nextTick().then(() => {
                        this.scrollToBottom();
                    });
                    this.$store.commit('SET_SCROLL_FALSE');
                }
            }
        }
    }
</script>

<style scoped>
    /*body::-webkit-scrollbar {*/
    /*width: 12px; !* width of the entire scrollbar *!*/
    /*}*/

    /*body::-webkit-scrollbar-track {*/
    /*background: orange !important; !* color of the tracking area *!*/
    /*}*/

    /*body::-webkit-scrollbar-thumb {*/
    /*background-color: blue; !* color of the scroll thumb *!*/
    /*border-radius: 20px; !* roundness of the scroll thumb *!*/
    /*border: 3px solid orange; !* creates padding around scroll thumb *!*/
    /*}*/
    .smooth-transition {

    }

    .previous-loader {
        position: absolute;
        left: 50%;
        top: 0;
        -webkit-transition: all .3s ease-in-out;
        -moz-transition: all .3s ease-in-out;
        -ms-transition: all .3s ease-in-out;
        -o-transition: all .3s ease-in-out;
        transition: all .3s ease-in-out;
    }
</style>