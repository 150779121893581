<template>
    <v-app>
        <router-view></router-view>
    </v-app>
</template>

<script>
    import {EventBus} from './event-bus.js'

    export default {
        mounted() {

        },
        created() {
            this.setDirection();

            EventBus.$on('languageIsChanged', () => {
                this.setDirection();
            });
        },
        data() {
            return {}
        },
        computed: {},
        methods: {
            setDirection() {
                if (this.$store.getters.getDirection == 'rtl') {
                    this.$vuetify.rtl = true;
                } else {
                    this.$vuetify.rtl = false;
                }
            }
        }
    };
</script>

<style>
    /*@font-face {*/
    /*font-family: 'WeblogmaYekan';*/
    /*src: url('../src/assets/fonts/WeblogmaYekan.eot');*/
    /*src: url('../src/assets/fonts/WeblogmaYekan.eot?#iefix') format('embedded-opentype'),*/
    /*url('../src/assets/fonts/WeblogmaYekan.woff2') format('woff2'),*/
    /*url('../src/assets/fonts/WeblogmaYekan.woff') format('woff'),*/
    /*url('../src/assets/fonts/WeblogmaYekan.ttf') format('truetype'),*/
    /*url('../src/assets/fonts/WeblogmaYekan.svg#WeblogmaYekan') format('svg');*/
    /*font-weight: normal;*/
    /*font-style: normal;*/
    /*}*/

    /*@font-face {*/
    /*font-family: 'WeblogmaYekan';*/
    /*src: url('../src/assets/fonts/WeblogmaYekan_1.eot');*/
    /*src: url('../src/assets/fonts/WeblogmaYekan_1.eot?#iefix') format('embedded-opentype'),*/
    /*url('../src/assets/fonts/WeblogmaYekan_1.woff2') format('woff2'),*/
    /*url('../src/assets/fonts/WeblogmaYekan_1.woff') format('woff'),*/
    /*url('../src/assets/fonts/WeblogmaYekan_1.ttf') format('truetype'),*/
    /*url('../src/assets/fonts/WeblogmaYekan_1.svg#WeblogmaYekan') format('svg');*/
    /*font-weight: normal;*/
    /*font-style: normal;*/
    /*}*/

    /*@font-face {*/
    /*font-family: 'YekanWeb';*/
    /*src: url('../src/assets/fonts/YekanWeb-Regular.eot');*/
    /*src: url('../src/assets/fonts/YekanWeb-Regular.eot?#iefix') format('embedded-opentype'),*/
    /*url('../src/assets/fonts/YekanWeb-Regular.woff2') format('woff2'),*/
    /*url('../src/assets/fonts/YekanWeb-Regular.woff') format('woff'),*/
    /*url('../src/assets/fonts/YekanWeb-Regular.ttf') format('truetype'),*/
    /*url('../src/assets/fonts/YekanWeb-Regular.svg#YekanWeb-Regular') format('svg');*/
    /*font-weight: normal;*/
    /*font-style: normal;*/
    /*}*/

    @font-face {
        font-family: "iran-sans";
        src: url("./assets/fonts/iransans-light.ttf");
    }

    * {
        font-family: iran-sans, "Roboto", sans-serif;
    }

    @media screen and (max-width: 360px) {
        * {
            font-size: .75rem;
        }
    }

</style>