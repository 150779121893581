<template>
    <v-container>
        <v-card>
            <CouponTemplate :title="'افزودن کد تخفیف جدید'" buttonText="افزودن" @proceed="addCoupon"></CouponTemplate>
        </v-card>

    </v-container>
</template>

<script>
    /*eslint-disable*/
    import CouponTemplate from '@/components/Admin/coupons/CouponTemplate';
    import {EventBus} from "../../event-bus";

    export default {
        components: {
            CouponTemplate
        },
        methods: {
            addCoupon(data) {
                EventBus.$emit('setLoading',true);
                this.$store.dispatch('addAdminCoupons', data).then((resp) => {
                    console.log(resp);
                    EventBus.$emit('notify', 'green', undefined, 'با موفقیت به ثبت رسید');
                }).finally(() => {
                    EventBus.$emit('setLoading',false);
                })
            }
        }
    }
</script>

<style scoped>

</style>