<template>
    <v-expansion-panels>
        <v-expansion-panel>
            <v-expansion-panel-header v-slot="{ open }">
                <span>
                    <v-icon small class="ma-3">
                        fas fa-search
                    </v-icon>فیلتر
                </span>
                <!--<v-text-field-->
                <!--dense outlined-->
                <!--:label="expandableItem.title"-->
                <!--v-model="queries[expandableItem.key].text"-->
                <!--&gt;-->
                <!--</v-text-field>-->
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <v-card class="elevation-0">
                    <v-divider></v-divider>
                    <v-card-text>
                        <v-row>
                            <template v-for="(option,key) in searchFilters">

                                <template v-if="option.type == 'multiselect' || option.type == 'singleselect' ">
                                    <v-col cols="12" sm="6" md="4" lg="3" :key="key">
                                        <v-autocomplete
                                                outlined
                                                dense
                                                :items="option.options"
                                                :label="option.title"
                                                v-model="queries[option.key].checkboxes"
                                                :multiple="option.type=='multiselect'"
                                                chips
                                                item-text="title"
                                                item-value="value"
                                                @change="selectChanged(option)"
                                        >
                                            <template v-slot:selection="{item}">
                                                <v-chip small class="pa-1 ">{{item.title}}</v-chip>
                                            </template>
                                        </v-autocomplete>
                                    </v-col>

                                </template>

                                <template v-if="option.type=='datepicker'">
                                    <v-col cols="12" sm="6" md="4" lg="3" :key="key">
                                        <v-text-field
                                                outlined
                                                dense
                                                :value="queries[option.key].text | toJalali"
                                                :label="option.title"
                                                prepend-icon="event"
                                                readonly
                                                @click="queries[option.key].showDialog=true"
                                        ></v-text-field>
                                        <AdminDatePicker @close="queries[option.key].showDialog=false"
                                                         :dialog="queries[option.key].showDialog"
                                                         @input="queries[option.key].showDialog = false"
                                                         v-model="queries[option.key].text"
                                        ></AdminDatePicker>


                                    </v-col>
                                </template>

                                <template v-if="option.type=='text'">
                                    <v-col cols="6" md="4" lg="3" :key="key">
                                        <v-text-field
                                                dense outlined
                                                :label="option.title"
                                                v-model="queries[option.key].text"
                                        >
                                        </v-text-field>
                                    </v-col>
                                </template>
                            </template>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer>

                        </v-spacer>
                        <v-btn class="blue white--text" @click="sendFilters(true)" v-if="hasExport">
                            <v-icon small class="mx-1">fas fa-download</v-icon>
                            دانلود
                        </v-btn>
                        <v-btn class="blue white--text" @click="sendFilters(true,'user-join')"
                               v-if="custom=='user-join'">
                            <v-icon small class="mx-1">fas fa-download</v-icon>
                            مشتری ها
                        </v-btn>
                        <v-btn class="blue white--text" @click="sendFilters(true,'sepidar')" v-if="hasSepidarExport">
                            <v-icon small class="mx-1">fas fa-download</v-icon>
                            دانلود سپیدار
                        </v-btn>
                        <v-btn class="red white--text" @click="resetFilters">
                            <v-icon small class="mx-1">fas fa-times</v-icon>
                            لغو فیلتر
                        </v-btn>
                        <v-btn class="primary" @click="sendFilters(false)">
                            <v-icon small class="mx-1">fas fa-check</v-icon>
                            اعمال فیلتر
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>

</template>

<script>
    /*eslint-disable*/
    import Vue from 'vue';
    import {EventBus} from "../event-bus";
    import {mapGetters} from "vuex";
    import AdminDatePicker from '@/components/AdminDatePicker';
    import moment from 'moment-jalaali';

    export default {
        props: {
            custom: {
                default: null
            },
            hasSepidarExport: {
                default: false
            },
            searchFilters: {
                default: []
            },
            prefix: {
                default: ''
            },
            hasExport: {
                default: false
            },
            /*sortBy: {
                default: []
            },
            sortDesc: {
                default: []
            },*/

        },
        components: {AdminDatePicker},
        beforeDestroy() {

            EventBus.$off('applySort', this.applySort);

        },
        created() {

            EventBus.$on('applySort', this.applySort);
            let emitSort = null;

            this.searchFilters.forEach((filterItem) => {

                let itemQuery = {
                    text: null,
                    checkboxes: [],
                    showDialog: false,
                    type: filterItem.type,
                    options: filterItem.options
                };

                if (filterItem.hasOwnProperty('default')) {

                    filterItem.options.forEach((option) => {

                        if (option.hasOwnProperty('default')) {

                            if (filterItem.type == 'singleselect') {

                                itemQuery.checkboxes = option.value;

                                if (filterItem.key == "orderBy" && this.prefix != '') {
                                    emitSort = this.reverseResolveFilter(option.value, this.prefix);
                                }

                                return;

                            } else if (filterItem.type == 'multiselect') {

                                itemQuery.checkboxes.push(option.value);

                            }
                        }
                    });
                }

                Vue.set(this.queries, filterItem.key, itemQuery);


            });
            // if (this.queries.hasOwnProperty('orderBy')) {
            //     console.log(this.queries.orderBy);
            //     // this.queries.orderBy.options.forEach((item) => {
            //     //     if (item.value == filter) {
            //     //         console.log(item.value);
            //     //         this.queries.orderBy.checkboxes = item.value;
            //     //     }
            //     // })
            // }
            if (emitSort != null) {
                this.$emit('setOrderAndDesc', emitSort);
            } else {
                this.sendFilters(false);
            }


        },
        data() {
            return {
                appliableFilter: {},
                queries: {},
                modal: false,
                date: null
            }
        },
        watch: {
            queries: {
                handler: function () {
                    console.log('queries', this.queries);
                },
                deep: true
            }
        },
        computed: {
            ...mapGetters(['resolveFilter', 'resolveOrder', 'reverseResolveFilter', 'reverseResolveOrder']),
            expandableItem() {
                return this.searchFilters.find((item) => {
                    if (item.hasOwnProperty('expandable')) {
                        return item;
                    }
                })
            }
        },
        methods: {
            selectChanged(item) {
                if (item.key == 'orderBy') {
                    this.$emit('setOrderAndDesc', this.reverseResolveFilter(this.queries.orderBy.checkboxes, this.prefix));
                }
            },
            applySort(data) {

                if (data.sortBy.length) {
                    let resolved = this.resolveFilter(data.prefix + '_' + data.sortBy[0]);
                    let order = this.resolveOrder(data.sortDesc);
                    if (resolved != null) {
                        let filter = resolved + ':' + order;
                        if (this.queries.hasOwnProperty('orderBy')) {
                            this.queries.orderBy.options.forEach((item) => {
                                if (item.value == filter) {
                                    console.log(item.value);
                                    this.queries.orderBy.checkboxes = item.value;
                                }
                            })
                        }
                    }

                } else {
                    this.queries.orderBy.checkboxes = null;
                }

                this.sendFilters(false);
            },
            downloadResult() {

            },
            resetFilters() {
                Object.keys(this.queries).forEach((item) => {
                    this.queries[item].checkboxes = [];
                    this.queries[item].text = "";
                });

                this.sendFilters(false);
            },
            calculateQueries() {

                Object.keys(this.queries).forEach((query) => {

                    let value = null;
                    let type = this.queries[query].type;

                    if (type == 'multiselect') {
                        value = this.queries[query].checkboxes.join('|');
                    } else if (type == 'singleselect') {
                        value = this.queries[query].checkboxes;
                    }
                    else {
                        value = this.queries[query].text;
                    }
                    if (value != null && value != "") {
                        this.appliableFilter[query] = value;
                    } else {
                        if (this.appliableFilter[query] != undefined) {
                            delete this.appliableFilter[query];
                        }
                    }

                });
            },
            sendFilters(download = false, exportType = null) {
                this.calculateQueries();
                if (!download) {
                    this.$emit('applyFilters', this.appliableFilter);
                } else {
                    if (exportType == null) {
                        this.$emit('applyFilters', {...this.appliableFilter, wantsExcel: true});
                    } else if (exportType == 'sepidar') {
                        this.$emit('applyFilters', {...this.appliableFilter, wantsExcel: true, exportType});
                    } else if(exportType == 'user-join') {
                        this.$emit('applyFilters', {...this.appliableFilter, wantsExcel: true, exportType});
                    }
                }
            }
        },
        filters: {
            toJalali(date) {
                if (date) {
                    return moment(date, "YYYY-M-DD").format('jYYYY/jMM/jDD');
                }
                return date;

            },
        }
    }
</script>

<style scoped>

</style>