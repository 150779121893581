<template>
    <div>
        <v-container v-show="$router.currentRoute.name != 'addCounselors'">
            <v-card>
                <v-card-title>
                    <v-btn color="primary" :to="{name: 'counselorCalendar'}">ثبت زمان</v-btn>
                    <v-spacer></v-spacer>
                    <ChangePassword :changePasswordAction="'adminChangeCounselorPassword'" :userId="$route.params.counselorId"></ChangePassword>
                </v-card-title>
            </v-card>


        </v-container>

        <counselor-profile></counselor-profile>
    </div>
</template>

<script>
    import CounselorProfile from "../../components/profiles/counselorProfile";
    import ChangePassword from '@/components/ChangePassword';

    export default {
        components: {
            CounselorProfile,
            ChangePassword
        }
    }
</script>

<style>

</style>