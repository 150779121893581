<template>
    <v-container fluid>
        <admin-orders v-if="$store.getters.userHasRole('admin')"></admin-orders>
        <client-orders v-if="$store.getters.userHasRole('customer')"></client-orders>
    </v-container>
</template>

<script>
    import AdminOrders from '@/components/orders/AdminOrders.vue'
    import ClientOrders from '@/components/orders/ClientOrders.vue'

    export default {
        components: {
            AdminOrders,
            ClientOrders
        }
    }
</script>

<style scoped>

</style>