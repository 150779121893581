<template>
    <div>
        <v-toolbar-items v-if="showTopMenu">
            <v-btn class="py-5" text :to="{name:'dashboard'}">{{$vuetify.lang.t('$vuetify.navDashboard')}}</v-btn>
            <v-btn class="py-5" text :to="{name:'adminSessionRequests'}">درخواست جلسات</v-btn>
            <v-btn class="py-5" text :to="{name:'sessions'}">{{$vuetify.lang.t('$vuetify.navSessionsLabel')}}</v-btn>
            <v-btn class="py-5" text :to="{name:'clients'}">{{$vuetify.lang.t('$vuetify.navClientsLabel')}}</v-btn>
            <v-btn class="py-5" text :to="{name:'counselors'}">{{$vuetify.lang.t('$vuetify.navCounselorsLabel')}}</v-btn>
            <v-btn class="py-5" text :to="{name:'orders'}">{{$vuetify.lang.t('$vuetify.userOrders')}}</v-btn>
            <v-btn class="py-5" text :to="{name:'coupons'}">کدهای تخفیف</v-btn>
            <v-btn class="py-5" text :to="{name:'giftCodes'}">کد هدیه</v-btn>
            <v-btn class="py-5" text :to="{name:'packageManagement'}">بسته ها</v-btn>
            <!--<v-btn class="py-5" text :to="{name:'adminLogger'}">لاگر</v-btn>-->
            <!--<v-btn class="py-5" text :to="{name:'adminFinances'}">-->

            <v-menu
                    open-on-hover
                    close-on-content-click

                    offset-y
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn class="py-5" text v-on="on" :to="{name:'finance'}">
                        مالی
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item :to="{name:'adminTransactions'}">
                        <v-list-item-title>درخواست ها</v-list-item-title>
                    </v-list-item>
                    <v-list-item :to="{name:'adminAddPayment'}">
                        <v-list-item-title>افزودن پرداخت</v-list-item-title>
                    </v-list-item>
                    <v-list-item :to="{name:'adminPaymentMethods'}">
                        <v-list-item-title>متدهای پرداخت</v-list-item-title>
                    </v-list-item>
                    <v-list-item :to="{name:'adminFinancialRecords'}">
                        <v-list-item-title>رکورد ها</v-list-item-title>
                    </v-list-item>
                    <v-list-item :to="{name:'adminFinancialRefund'}">
                        <v-list-item-title>استرداد</v-list-item-title>
                    </v-list-item>
                    <v-list-item :to="{name:'adminFinancialManageCurrencies'}">
                        <v-list-item-title>مدیریت ارز ها</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>

            <v-menu
                    open-on-hover
                    close-on-content-click
                    offset-y
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn class="py-5" text v-on="on" :to="{name:'adminSepidarPayments'}">
                        گزارشات
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item :to="{name:'adminSepidarPayments'}">
                        <v-list-item-title>گزارشات مالی سپیدار</v-list-item-title>
                    </v-list-item>
                    <v-list-item :to="{name:'adminSepidarSessions'}">
                        <v-list-item-title>گزارشات جلسات سپیدار</v-list-item-title>
                    </v-list-item>
                    <v-list-item :to="{name:'adminSepidarUsers'}">
                        <v-list-item-title>گزارشات کاربران سپیدار</v-list-item-title>
                    </v-list-item>
                    <!--<v-list-item :to="{name:'temppath'}">-->
                        <!--<v-list-item-title>نمودار کاربران</v-list-item-title>-->
                    <!--</v-list-item>-->
                </v-list>
            </v-menu>
            <!--</v-btn>-->
        </v-toolbar-items>

        <v-list v-else-if="showNavIcon" id="adminNavIconItems" dense>
            <v-list-item>
                <v-list-item-avatar>
                    <img
                            style="object-fit: cover"
                            :src="require('./../../assets/avatar-default.png')"/>
                </v-list-item-avatar>

                <v-list-item-title>
                    <v-btn text :to="{name:'profile'}">{{ $store.state.userInfo.firstname + ' ' +
                        $store.state.userInfo.lastname }}
                    </v-btn>
                    <p class="secondary--text px-4" style="font-size: .6rem">مشاهده پروفایل
                        <v-icon color="secondary" small>mdi-chevron-left</v-icon>
                    </p>
                </v-list-item-title>
            </v-list-item>
            <v-divider></v-divider>

            <template v-for="item in items">
                <v-row
                        v-if="item.heading"
                        :key="item.heading"
                        align="center"
                >
                    <v-col cols="6">
                        <v-subheader v-if="item.heading">
                            {{ item.heading }}
                        </v-subheader>
                    </v-col>
                    <v-col
                            cols="6"
                            class="text-center"
                    >
                        <a
                                href="#!"
                                class="body-2 black--text"
                        >EDIT</a>
                    </v-col>
                </v-row>
                <v-list-group
                        v-else-if="item.children"
                        :key="item.text"
                        v-model="item.model"
                        :prepend-icon="item.model ? item.icon : item['icon-alt']"
                        append-icon=""
                >
                    <template v-slot:activator>
                        <v-list-item-content>
                            <v-list-item-title>
                                {{ item.text }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </template>
                    <v-list-item
                            v-for="(child, i) in item.children"
                            :key="i"
                            link
                            class="mx-3"
                            :to="{name:child.routeName}"
                    >
                        <v-list-item-action v-if="child.icon">
                            <v-icon>{{ child.icon }}</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>
                                {{ child.text }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-group>
                <v-list-item
                        v-else
                        :key="item.text"
                        link
                        :to="{name:item.routeName}"
                >
                    <v-list-item-action>
                        <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>
                            {{ item.text }}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </template>
        </v-list>
        <!--<v-list v-else-if="showNavIcon" id="adminNavIconItems">-->
        <!--<v-list-item>-->
        <!--<v-list-item-avatar>-->
        <!--<img-->
        <!--style="object-fit: cover"-->
        <!--:src="require('./../../assets/avatar-default.png')"/>-->
        <!--</v-list-item-avatar>-->

        <!--<v-list-item-title>-->
        <!--<v-btn text :to="{name:'profile'}">{{ $store.state.userInfo.firstname + ' ' +-->
        <!--$store.state.userInfo.lastname }}-->
        <!--</v-btn>-->
        <!--<p class="secondary&#45;&#45;text px-4" style="font-size: .6rem">مشاهده پروفایل-->
        <!--<v-icon color="secondary" small>mdi-chevron-left</v-icon>-->
        <!--</p>-->
        <!--</v-list-item-title>-->
        <!--</v-list-item>-->
        <!--<v-divider></v-divider>-->
        <!--<v-list-item class="py-0">-->
        <!--<v-list-item-content class="py-0">-->
        <!--<v-btn text :to="{name:'dashboard'}">{{$vuetify.lang.t('$vuetify.navDashboard')}}</v-btn>-->
        <!--</v-list-item-content>-->
        <!--</v-list-item>-->
        <!--<v-list-item class="py-0">-->
        <!--<v-list-item-content class="py-0">-->
        <!--<v-btn text :to="{name:'adminSessionRequests'}">درخواست جلسات</v-btn>-->
        <!--</v-list-item-content>-->
        <!--</v-list-item>-->
        <!--<v-list-item>-->
        <!--<v-list-item-content class="py-0">-->
        <!--<v-btn text :to="{name:'sessions'}">{{$vuetify.lang.t('$vuetify.navSessionsLabel')}}</v-btn>-->
        <!--</v-list-item-content>-->
        <!--</v-list-item>-->
        <!--<v-list-item>-->
        <!--<v-list-item-content class="py-0">-->
        <!--<v-btn text :to="{name:'clients'}">{{$vuetify.lang.t('$vuetify.navClientsLabel')}}</v-btn>-->
        <!--</v-list-item-content>-->
        <!--</v-list-item>-->
        <!--<v-list-item>-->
        <!--<v-list-item-content class="py-0">-->
        <!--<v-btn text :to="{name:'counselors'}">{{$vuetify.lang.t('$vuetify.navCounselorsLabel')}}</v-btn>-->
        <!--</v-list-item-content>-->
        <!--</v-list-item>-->
        <!--<v-list-item>-->
        <!--<v-list-item-content class="py-0">-->
        <!--<v-btn text :to="{name:'orders'}">{{$vuetify.lang.t('$vuetify.userOrders')}}</v-btn>-->
        <!--</v-list-item-content>-->
        <!--</v-list-item>-->
        <!--<v-list-item>-->
        <!--<v-list-item-content class="py-0">-->
        <!--<v-btn class="py-5" text :to="{name:'coupons'}">کدهای تخفیف</v-btn>-->

        <!--</v-list-item-content>-->
        <!--</v-list-item>-->
        <!--<v-list-item>-->
        <!--<v-list-item-content class="py-0">-->
        <!--<v-btn class="py-5" text :to="{name:'packageManagement'}">بسته ها</v-btn>-->
        <!--</v-list-item-content>-->
        <!--</v-list-item>-->
        <!--</v-list>-->
    </div>
</template>

<script>
    export default {
        data() {
            return {
                items: [
                    {routeName: 'dashboard', icon: 'mdi-view-dashboard', text: 'داشبورد'},
                    {routeName: 'sessions', icon: 'mdi-calendar', text: 'جلسات'},
                    {routeName: 'adminSessionRequests', icon: 'mdi-calendar-question', text: 'درخواست جلسات'},
                    {routeName: 'counselors', icon: 'mdi-doctor', text: 'مشاوران'},
                    {routeName: 'clients', icon: 'mdi-account-group', text: 'مراجعان'},
                    {routeName: 'orders', icon: 'mdi-cash', text: 'سفارشات'},
                    {
                        icon: 'mdi-chevron-up',
                        'icon-alt': 'mdi-chevron-down',
                        text: 'کدهای تخفیف',
                        model: false,
                        children: [
                            {routeName: 'coupons', icon: 'mdi-barcode', text: 'لیست کدها'},
                            {routeName: 'addNewCoupons', icon: 'mdi-plus', text: 'کد جدید'},
                        ],
                    },
                    {
                        icon: 'mdi-chevron-up',
                        'icon-alt': 'mdi-chevron-down',
                        text: 'بسته ها',
                        model: false,
                        children: [
                            {routeName: 'addNewPackage', icon: 'mdi-plus', text: 'بسته جدید'},
                            {routeName: 'packageManagement', icon: 'mdi-format-list-bulleted', text: 'لیست بسته ها'},
                        ],
                    },
                    {
                        icon: 'mdi-chevron-up',
                        'icon-alt': 'mdi-chevron-down',
                        text: 'مالی',
                        model: false,
                        children: [
                            {routeName: 'adminTransactions', icon: 'mdi-file-search-outline', text: 'درخواست ها'},
                            {routeName: 'adminAddPayment', icon: 'mdi-plus', text: 'افزودن پرداخت'},
                            {routeName: 'adminPaymentMethods', icon: 'mdi-format-list-bulleted', text: 'متدهای پرداخت'},
                            {routeName: 'adminFinancialRecords', icon: 'mdi-format-list-bulleted', text: 'رکورد ها'},
                            {
                                routeName: 'adminFinancialRefunds',
                                icon: 'mdi-format-list-bulleted',
                                text: 'درخواست استرداد'
                            },
                        ],
                    },
                    {
                        icon: 'mdi-chevron-up',
                        'icon-alt': 'mdi-chevron-down',
                        text: 'سپیدار',
                        model: false,
                        children: [
                            {routeName: 'adminSepidarPayments', icon: 'mdi-cash', text: 'گزارشات مالی'},
                            {
                                routeName: 'adminSepidarUsers',
                                icon: 'mdi-account-group-outline',
                                text: 'گزارشات کاربران'
                            },
                            {
                                routeName: 'adminSepidarSessions',
                                icon: 'mdi-monitor-multiple',
                                text: 'گزارشات جلسات'
                            },

                        ],
                    },
                ],
            }
        },
        computed: {
            showNavIcon() {
                // return true;
                return this.$vuetify.breakpoint.mdAndDown;
            },
            showTopMenu() {
                return this.$vuetify.breakpoint.mdAndUp;
            }
        }
    }
</script>

<style>
    #adminNavIconItems .v-btn__content {
        justify-content: right;
    }
</style>