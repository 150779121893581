<template>
    <v-container>
        <v-dialog
                v-model="editState"
                v-if="itemToEdit!=null"
                persistent
                max-width="800"
        >
            <changeState @close="closeEditStateForm" @done="finishedEditingState" :itemToEdit="itemToEdit"
                         :sessionStates="sessionStates"></changeState>
        </v-dialog>

        <v-dialog
                v-model="showResetDialogSwitch"
                max-width="320px"
        >
            <v-card class="pa-5">
                <v-card-text>
                    <h3 class="text-center">از حذف این مورد اطمینان دارید؟</h3>
                    <v-spacer></v-spacer>
                </v-card-text>
                <v-card-text></v-card-text>
                <v-card-actions class="">
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="showResetDialogSwitch = false">
                        خیر
                    </v-btn>
                    <v-btn class="red white--text elevation-0" @click="resetSession">
                        بله
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-card class="my-8" v-if="rerender">
            <v-card-title ref="adminSessions">
                درخواست های جلسات
                <!--<v-spacer></v-spacer>-->
                <!--<v-text-field-->
                <!--solo-->
                <!--v-model="search"-->
                <!--append-icon="mdi-magnify"-->
                <!--label="جستجو"-->
                <!--single-line-->
                <!--hide-details-->
                <!--&gt;</v-text-field>-->
            </v-card-title>
            <v-progress-linear
                    indeterminate
                    :active="requesting"
                    color="blue "
            ></v-progress-linear>
            <v-card-text>
                <TableOptions :searchFilters="searchFilters"

                              @applyFilters="applyFilters"></TableOptions>
            </v-card-text>
            <v-data-table
                    hide-default-footer
                    :headers="headers"
                    :items="sessions"
                    :search="search"
            >

                <template v-slot:item="{item}">
                    <tr :class="{
                        'orange black--text darken-1':item.state=='session_requested',
                        'red lighten-1':item.state=='doctor_assigned' && (!item.has_time),
                        'blue lighten-5':item.state=='doctor_assigned' && (item.has_time),
                    }">
                        <td>
                            <div style="position: relative;">
                                <div v-if="item.needsToRequestSession">
                                    <a target="_blank" :href="'clients/'+item.user.id" class="new-user-badge"
                                       style="text-decoration: none">new</a>
                                </div>
                                <v-chip :href="'clients/'+item.user.id">
                                    <a @click.prevent="getClientProfile(item.user.id)" :href="'clients/'+item.user.id"
                                       style="text-decoration: none">{{
                                        item.user.full_name }}</a>
                                </v-chip>
                            </div>

                            <!--<v-chip>-->

                            <!--<a :href="'clients/'+item.user.id" style="text-decoration: none">-->
                            <!--{{item.user.full_name}}-->
                            <!--</a>-->
                            <!--</v-chip>-->
                        </td>
                        <td>
                            <template v-if="item.state == 'doctor_assigned'">
                                {{item.doctor.full_name}}
                            </template>
                            <template v-else-if="item.state == 'session_requested'">
                                <v-chip small>
                                    انتخاب نشده
                                </v-chip>
                            </template>
                        </td>
                        <td>{{item.request_date}}</td>
                        <!--<td>{{item.cycle.heading}}</td>-->
                        <td class="align-center justify-center ma-auto" style="position:relative">
                            <span class="partially-paid" v-if="item.factorIsPartiallyPaid">پیش پرداخت</span>
                            {{resolveState(item.state)}}
                        </td>
                        <td>{{types[item.type] || item.type}}</td>
                        <td>{{item.user.timezone}}</td>
                        <td>{{item.user.country}}</td>
                        <td>
                            <v-btn @click="editSessionState(item)" x-small text fab class="white--text primary">
                                <v-icon small>fas fa-edit</v-icon>
                            </v-btn>


                            <v-btn x-small class="primary mx-1" text fab
                                   :to="`assignment/user/${item.user.id}/cycle/${item.cycle.id}/request/${item.id}`">
                                <v-icon small>fas fa-tv</v-icon>
                            </v-btn>

                            <template v-if="item.state != 'used' ">
                                <v-btn x-small text fab class="red white--text">
                                    <v-icon small @click="showResetDialog(item)">
                                        fas fa-trash
                                    </v-icon>
                                </v-btn>

                            </template>
                            <template v-if="item.state=='doctor_assigned'">

                                <v-btn x-small class="primary" text fab
                                       v-if="!item.has_time"
                                       @click="$router.push({name:'counselorCalendar',params:{counselorId:item.doctor.id}})"
                                >
                                    <v-icon>fas fa-clock</v-icon>
                                </v-btn>

                            </template>
                        </td>

                    </tr>
                </template>

            </v-data-table>
        </v-card>
        <div class="text-center" v-if="!fiveSession">
            <v-pagination
                    v-model="page"
                    :length="total"
            ></v-pagination>
        </div>
    </v-container>
</template>

<script>
    /*eslint-disable*/
    import {mapGetters} from 'vuex';
    import moment from 'moment-jalaali';
    import {EventBus} from "../../event-bus";
    import {adminSessionRequests} from "../../searchFilters";
    import TableOptions from '@/components/TableOptions.vue';
    import ChangeState from '@/components/ChangeState.vue';
    import constants from "@/constants/constants";


    export default {
        components: {
            TableOptions,
            ChangeState
        },

        props: {
            title: {
                default: 'جلسات',
                type: String
            },
            fiveSession: Boolean
        },
        data() {
            return {
                itemToEditDoctor: null,
                sessionToEditDate: null,
                sessionToEditTime: null,
                sessionToEditTimeMenu: false,
                sessionToEditDateMenu: false,
                itemToEditNewState: null,
                itemToEdit: null,
                editState: false,
                sessionStates: [
                    {title: 'درخواست جلسه', value: 'session_requested'},
                    {title: 'دکتر ست شده', value: 'doctor_assigned'},
                    {title: 'زمان ست شده', value: 'date_fixed'},
                    {title: 'برگزار شده', value: 'used'},
                ],
                options: {},
                searchFilters: [],
                requesting: false,
                resetItem: null,
                showResetDialogSwitch: false,
                rerender: false,
                search: '',
                headers: [
                    {
                        text: 'نام و نام خانوادگی',
                        align: 'start',
                        value: 'user.full_name'
                    },
                    {text: 'مشاور', value: 'doctor'},
                    {text: 'تاریخ درخواست', value: 'request_date'},
                    // {text: 'دوره', value: 'cycle.heading'},
                    {text: 'وضعیت', value: 'user.timezone'},
                    {text: 'نوع', value: 'type'},
                    {text: 'منطقه زمانی', value: 'user.timezone'},
                    {text: 'کشور', value: 'user.country'},
                    {text: 'عملیات', value: 'actions'},
                ],
                desserts: [],
                date: moment().format("YYYY-MM-DD"),
                path: this.$router.currentRoute.name,
                dates: [
                    {
                        id: 1,
                        title: 'امروز',
                        code: moment().format("YYYY-MM-DD")
                    },
                    {
                        id: 2,
                        title: 'فردا',
                        code: moment(new Date()).add(1, 'days').format("YYYY-MM-DD")
                    },

                ],
                page: 1,
                total: 1,
                sessions: []
            }
        },

        computed: {
            types(){
              return constants.packages.types.reduce(function(map, obj) {
                map[obj.value] = obj.title;
                return map;
              }, {});
            },

            ...mapGetters({
                allDoctors: 'getAdminAllDoctors'
                // sessions: 'getAdminSessions',
                // page: 'page',
                // total: 'total',
            }),
            requiredRules() {
                return [v => !!v || this.$vuetify.lang.t('$vuetify.fillIsRequired')];
            },
            requiresEnteringTime() {
                if (this.itemToEdit.state == 'session_requested' || this.itemToEdit.state == 'doctor_assigned') {
                    if (this.itemToEditNewState == 'date_fixed' || this.itemToEditNewState == 'used') {
                        return true;
                    }
                }
                return false;
            },
            requiresEnteringDoctor() {
                if (this.itemToEdit.state == 'session_requested') {
                    if (this.itemToEditNewState == 'date_fixed' || this.itemToEditNewState == 'used' || this.itemToEditNewState == 'doctor_assigned') {
                        return true;
                    }
                }
                return false;
            },
        },
        watch: {
            '$route': function () {
                this.getAdminSessions();
            },
            page() {
                this.getAdminSessions();
                this.$vuetify.goTo(this.$refs.adminSessions.offsetTop);
            }
        },
        created() {

            this.fillDoctors();


        },
        methods: {
            alertUs() {
                // alert('xx');
            },
            fillDoctors() {

                let promise = new Promise((resolve, reject) => {
                    if (!this.allDoctors.length) {
                        this.$store.dispatch("getAllDoctors").then(res => {
                            this.doctors = res.data.doctors;
                            resolve();
                        }).catch(err => {
                            console.log(err);
                        });
                    } else {

                        this.doctors = this.allDoctors;
                        resolve();
                    }
                })

                promise.then(() => {
                    let mappedDoctors = this.doctors.map((doctor) => {
                        return {
                            title: doctor.full_name, value: doctor.doctor_profile.id
                        };
                    });

                    adminSessionRequests.find((item) => {
                        if (item.key == 'doctor_ids') {
                            item.options = mappedDoctors;
                        }
                    });
                    this.searchFilters = adminSessionRequests;
                    this.rerender = true;
                });
            },
            resolveState(state) {
                switch (state) {
                    case 'used':
                        return 'برگزار شده';
                        break;
                    case 'session_requested':
                        return 'درخواست جلسه';
                        break;
                    case 'doctor_assigned':
                        return 'تخصیص یافته';
                        break;
                    case 'date_fixed':
                        return 'زمان ست شده';
                        break;
                }
            },
            finishedEditingState() {
                EventBus.$emit('notify', 'green', undefined, 'با موفقیت تغییر یافت');
                this.closeEditStateForm();
                this.getAdminSessions();
            },
            closeEditStateForm() {
                this.itemToEdit = null;
                this.editState = false;
            },
            getDoctors() {
                this.isLoading = true;
                this.$store
                    .dispatch("getAllDoctors")
                    .then(res => {
                        this.doctors = res.data.doctors;
                        this.isLoading = false;
                    })
                    .catch(err => {
                        console.log(err);
                    }).finally(() => {

                });
            },

            cancelEditing() {
                this.itemToEditNewState = null;
                this.itemToEdit = null;
                // this.$refs['session-' + item.id].internalValue = item.state;
            },
            // changeState() {
            //     if (this.$refs.editStateForm.validate()) {
            //
            //         this.$store.dispatch('adminSessionChangeState', {
            //             session: this.itemToEdit.id,
            //             state: this.itemToEditNewState,
            //             doctorId: this.itemToEditDoctor,
            //             date: this.sessionToEditDate,
            //             time: this.sessionToEditTime,
            //         }).then((resp) => {
            //             this.itemToEdit = null;
            //             this.itemToEditNewState = null;
            //             this.getAdminSessions();
            //             console.log(resp);
            //         })
            //
            //     }
            // },
            changeItemToEditState(state) {
                this.itemToEditNewState = state;
            },

            editSessionState(session) {
                this.itemToEdit = session;
                this.editState = true;
            },
            applyFilters(options) {
                this.page = 1;
                this.options = options;
                this.getAdminSessions();
            },
            resetSession() {
                this.$store.dispatch('adminResetSession', this.resetItem.id).then(() => {
                    EventBus.$emit('notify', 'green', undefined, 'با موفقیت حذف شد');
                    this.showResetDialogSwitch = false;
                    this.getAdminSessions();
                })
            },
            showResetDialog(item) {
                this.resetItem = item;
                this.showResetDialogSwitch = true;
            },
            getClientProfile(id) {
                this.$router.push('clients/' + `${id}`);
            },
            getAdminSessions() {
                let options = {
                    state: 'session_requested|doctor_assigned',
                    ...this.options
                };
                EventBus.$emit('setLoading', true);
                this.$store.dispatch('getAdminSessions', {
                    page: this.page, options: {
                        state: 'session_requested|doctor_assigned',
                        ...this.options
                    }
                }).then((data) => {
                    console.log(data);
                    this.sessions = data.data.sessions;
                    this.total = data.data.pagination.total_pages;
                }).finally(() => {
                    EventBus.$emit('setLoading', false);
                })
            },

        }
    }
</script>

<style>

    .new-user-badge {
        font-size: .7em;
        cursor: pointer;
        font-weight: bolder;
        position: absolute;
        right: -5px;
        top: -5px;
        padding: 2px 3px;
        background-color: rgba(230, 0, 0, 1);
        color: white !important;
        border-radius: 5px;
        z-index: 1;
    }

    .partially-paid {
        font-size: .6em;
        cursor: pointer;
        font-weight: bolder;
        position: absolute;
        right: -5px;
        top: -5px;
        padding: 2px 3px;
        background-color: rgba(230, 0, 0, 1);
        color: white;
        border-radius: 5px;
        z-index: 1;
    }
</style>
