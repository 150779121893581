<template>
  <v-card class="elevation-0">
    <v-card-title>
      {{ title }}
    </v-card-title>
    <v-card-text>
      <v-form ref="addPackage">
        <v-row>
          <v-col cols="12" md="4" lg="3" sm="6">
            <v-text-field
                v-model="pack.title"
                outlined label="عنوان"
                :rules="requiredRules"
            >
            </v-text-field>
          </v-col>

          <v-col cols="12" md="4" lg="3" sm="6">
            <v-text-field
                :rules="requiredRules"
                v-model="pack.session_count"
                type="number"
                outlined label="تعداد "></v-text-field>
          </v-col>


          <v-col cols="12" md="4" lg="3" sm="6">
            <v-select

                :items="packageTypes"
                item-value="value"
                item-text="title"
                :rules="requiredRules"
                v-model="pack.type"
                label="نوع "
                outlined>
            </v-select>
          </v-col>

          <v-col cols="12" md="4" lg="3" sm="6">
            <v-autocomplete
                v-model="pack.doctor_id"
                :items="doctors"
                :loading="isLoading"
                hide-no-data
                hide-selected
                item-text="full_name"
                item-value="doctor_profile.id"
                outlined
                label="دکتر"
            ></v-autocomplete>
          </v-col>
        </v-row>

        <AddPriceComponent :allPrices="pack.prices" @updatePrices="updatePrices"></AddPriceComponent>

      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn v-if="closable" class="red white--text" @click="$emit('close')">
        بستن
      </v-btn>
      <v-btn class="primary" @click="addPackage" :disabled="sending">
        {{ submitTitle }}
      </v-btn>

    </v-card-actions>
    <v-row>
      <v-col>
        <AlertsComponent></AlertsComponent>
      </v-col>
    </v-row>
  </v-card>

</template>

<script>

import cons from "@/constants/constants";
import {EventBus} from "../../../event-bus";
import AlertsComponent from "@/components/AlertsComponent";
import AddPriceComponent from "@/components/Admin/AddPriceComponent";

export default {
  props: {
    title: {
      default: 'افزودن بسته'

    },
    submitTitle: {
      default: 'افزودن'
    },
    sending: {
      default: false
    },
    closable: {
      default: false
    },
    myPack: {
      default: function () {
        return {
          session_count: null,
          title: null,
          payment_unit: null,
          payment_amount: null,
          doctor_id: null,
          type: null,
          prices: []
        };
      }
    }
  },
  components: {
    AlertsComponent,
    AddPriceComponent
  },
  data() {
    return {
      clientsLoading: false,
      isLoading: false,
      doctors: [],
      types: [
        {title: 'دلار', value: 'dollar'},
        {title: 'تومان', value: 'rial'}
      ]
    }
  },
  created() {
    this.pack = Object.assign({}, this.myPack);
    this.getDoctors();
  },
  mounted() {

  },
  computed: {
    packageTypes() {
      return cons.packages.types
    },
    requiredRules() {
      return [v => !!v || this.$vuetify.lang.t('$vuetify.fillIsRequired')];
    },
  },
  methods: {
    updatePrices(prices) {
      this.pack.prices = prices;
    },
    getDoctors() {
      this.isLoading = true;
      this.$store
          .dispatch("getAllDoctors")
          .then(res => {
            this.doctors = res.data.doctors;
            this.isLoading = false;
          })
          .catch(err => {
            console.log(err);
          }).finally(() => {

      });
    },
    addPackage() {

      if (this.$refs.addPackage.validate()) {

        if (!this.pack.prices.length) {
          EventBus.$emit('notify', 'red', undefined, 'لطفا حداقل یک ارز پرداختی برای بسته انتخاب نمایید');
          return;
        }

        this.$emit('addPackage', this.pack);


      }

    }
  }
}
</script>

<style scoped>

</style>