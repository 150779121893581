<template>
    <div>
        <v-dialog v-model="editGiftCodeDialog" scrollable max-width="900">
            <v-card class="elevation-0" v-if="itemToEdit != null">
                <v-card-text>
                    <AdminEditGiftCode :giftCode="itemToEdit" @close="cancelEditing"
                                       @done="doneEditing"></AdminEditGiftCode>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog v-model="showDialog" max-width="400">
            <v-card v-if="itemToEdit != null">
                <v-card-title>
                    <template v-if="itemToEdit.state == 'available'">
                        غیرفعال سازی کد هدیه
                    </template>
                    <template v-else>
                        فعالسازی کد هدیه
                    </template>
                </v-card-title>
                <v-divider></v-divider>

                <v-card-text class="pa-5">
                    آیا نسبت به عملیات مورد نظر اطمینان دارید؟
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="error elevation-0" text @click="showDialog = false">
                        خیر
                    </v-btn>
                    <v-btn class="primary elevation-0" text @click="toggleGiftCode">
                        بله
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-container fluid>
            <v-card class="my-8">
                <v-card-title>
                    {{ title }}
                    <v-spacer></v-spacer>
                    <v-btn color="primary" class="mt-5" :to="{name: 'addNewGiftCode'}">افزودن کد هدیه جدید</v-btn>
                </v-card-title>
                <v-card-text>
                    <TableOptions :hasExport="true" :searchFilters="searchFilters"
                                  @applyFilters="applyFilters"></TableOptions>
                </v-card-text>
                <v-data-table
                        hide-default-footer
                        :headers="headers"
                        :items="giftCodes"
                        :search="search"
                        show-expand

                >
                    <template v-slot:expanded-item="{ headers, item }">
                        <td :colspan="headers.length" class="pa-0 ma-0">
                            <GiftCodeCurrenciesComponent :allPrices="item.currencies"></GiftCodeCurrenciesComponent>
                        </td>
                    </template>

                    <template v-slot:item.state="{item}">
                        {{item.state == 'available' ? 'فعال' : 'غیر فعال'}}
                    </template>

                    <template v-slot:item.max_usage="{item}">
                        {{ (item.max_usage) == 0 ? 'نامحدود' : item.max_usage}}
                    </template>

                    <template v-slot:item.exp_date="{item}">
                        {{ (item.exp_date) == null ? 'همیشگی' : item.exp_date}}
                    </template>
                    <template v-slot:item.action="{item}">
                        <template v-if="item.state =='unavailable'">
                            <v-btn small fab text @click="showToggleDialog(item)">
                                <v-icon class="blue--text"> fas fa-eye</v-icon>
                            </v-btn>
                        </template>
                        <template v-else>
                            <v-btn small fab text @click="showToggleDialog(item)">
                                <v-icon class="red--text"> fas fa-eye-slash</v-icon>
                            </v-btn>
                        </template>
                        <v-btn fab x-small text class="primary" @click="openEditDialog(item)">
                            <v-icon small>fas fa-edit</v-icon>
                        </v-btn>
                    </template>

                </v-data-table>
            </v-card>
            <div class="text-center" v-if="!fiveSession">
                <v-pagination
                        v-model="page"
                        :length="total"
                ></v-pagination>
            </div>
        </v-container>
        <!--<v-row v-if="request != null">-->
        <!--<v-dialog-->
        <!--v-model="dialog"-->
        <!--max-width="450"-->
        <!--&gt;-->
        <!--<v-card class="d-flex flex-column justify-center align-center">-->
        <!--<v-card-title>درخواست {{ request.type }} از {{ request.user.full_name }}</v-card-title>-->

        <!--<v-card-text>-->
        <!--{{ request.description }}-->
        <!--</v-card-text>-->

        <!--<v-card-actions>-->
        <!--&lt;!&ndash;                        <v-spacer></v-spacer>&ndash;&gt;-->

        <!--<v-btn-->
        <!--dark-->
        <!--color="red darken-1"-->
        <!--@click="dialog = false"-->
        <!--&gt;-->
        <!--بستن-->
        <!--</v-btn>-->

        <!--<v-btn-->
        <!--dark-->
        <!--color="green darken-1"-->
        <!--@click="requestUpdate"-->
        <!--:loading="loading"-->
        <!--&gt;-->
        <!--تایید-->
        <!--</v-btn>-->
        <!--</v-card-actions>-->
        <!--</v-card>-->
        <!--</v-dialog>-->
        <!--</v-row>-->
    </div>
</template>

<script>
    /*eslint-disable*/
    import {mapGetters} from 'vuex';
    import {adminGiftCodeFilters} from "../../searchFilters";
    import TableOptions from '@/components/TableOptions.vue';
    import AdminEditGiftCode from '@/components/Admin/giftCodes/AdminEditGiftCode.vue';
    import GiftCodeCurrenciesComponent from '@/components/Admin/giftCodes/GiftCodeCurrenciesComponent.vue';
    import {EventBus} from "../../event-bus";

    export default {
        components: {
            TableOptions,
            AdminEditGiftCode, GiftCodeCurrenciesComponent

        },
        name: 'requests',
        props: {
            title: {
                default: 'کدهای تخفیف',
                type: String
            },
            fiveSession: Boolean
        },
        data() {

            return {
                showDialog: false,
                itemToEdit: null,
                editGiftCodeDialog: false,
                options: {},
                requesting: false,
                searchFilters: [],
                total: 1,
                page: 1,
                loading: false,
                loadingBtn: false,
                dialog: false,
                search: '',
                headers: [
                    {text: 'کد', value: 'code'},
                    {text: 'حداکثر استفاده', value: 'max_usage_count'},
                    {text: 'حداکثر استفاده هر کاربر', value: 'max_usage_count_per_user'},
                    {text: 'تعداد استفاده', value: 'usage_count'},
                    {text: 'تاریخ انقضا', value: 'exp_date'},
                    {text: 'وضعیت', value: 'state'},
                    {text: 'عملیات', value: 'action'},
                ],
                desserts: [],
                request: null,
                giftCodes: []
            }
        },
        computed: {
            ...mapGetters({
                // requests: 'getAdminGiftCodes',
                // page: 'page',
                // total: 'total',
            })
        },
        created() {
            // this.getAdminGiftCodes();
            this.searchFilters = adminCouponFilters;
        },
        methods: {
            showToggleDialog(item) {
                this.itemToEdit = item;
                this.showDialog = true;
            },
            toggleGiftCode() {
                let id = this.itemToEdit.id;
                EventBus.$emit('setLoading', true);
                this.$store.dispatch('adminToggleGiftCode', id).then((resp) => {
                    console.log(resp);
                    this.showDialog = false;
                    this.itemToEdit = null;
                    this.getAdminGiftCodes();
                    EventBus.$emit('notify', 'green', undefined, 'با موفقیت انجام شد');

                }).finally(() => {
                    EventBus.$emit('setLoading', false);
                })
            },
            doneEditing() {
                this.itemToEdit = null;
                this.editGiftCodeDialog = false;

                this.getAdminGiftCodes()
            },
            cancelEditing() {
                this.itemToEdit = null;
                this.editGiftCodeDialog = false;
            },
            openEditDialog(item) {
                this.itemToEdit = item;
                this.editGiftCodeDialog = true;
            },
            applyFilters(options) {
                this.page = 1;
                this.options = options;
                this.getAdminGiftCodes();
            },
            getAdminGiftCodes() {
                EventBus.$emit('setLoading', true);
                this.$store.dispatch('getAdminGiftCodes', {page: this.page, options: this.options}).then((data) => {
                    this.giftCodes = data.data.giftCodes;
                    this.total = data.data.pagination.total_pages;

                }).finally(() => {
                    EventBus.$emit('setLoading', false);
                })
            },
            // nextPage() {
            //     this.$store.dispatch('pageChangeHandle', {value: 'next', page: this.page, url: '/admin/users/requests', commitName: 'setAdminRequests'});
            //     console.log('page', this.page)
            // },
            // previousPage() {
            //     this.$store.dispatch('pageChangeHandle', {value: 'previous', page: this.page, url: '/admin/users/requests', commitName: 'setAdminRequests'});
            //     console.log('page', this.page)
            // },
            // specificPage(page) {
            //     this.$store.commit('SET_SPECIFIC_PAGE', page);
            //     this.$store.dispatch('pageChangeHandle', {value: '', page: page, url: '/admin/users/requests', commitName: 'setAdminRequests'});
            //     console.log('page', page)
            // },
            requestDetail(item) {
                this.dialog = true;
                this.request = item;
                // console.log('request', this.request)
            },
            requestUpdate() {
                this.loading = true;
                this.$store.dispatch('updateTicketState', this.request.id).then(() => {
                    this.dialog = false;
                    this.requests.splice(this.requests.indexOf(this.request), 1);
                }).finally(() => {
                    this.loading = false;
                })

            }
        },
        watch: {
            editGiftCodeDialog(newVal) {
                if (!newVal) {
                    this.itemToEdit = null;
                }
            },
            page() {

                this.getAdminGiftCodes();
                // this.$vuetify.goTo(this.$refs.adminSessions.offsetTop);
            }
        },
        filters: {
            resolveCouponType(input) {
                return input == 'fixed' ? 'ثابت' : 'درصدی';
            },
            tooltip(input) {
                let tooltip = '';
                switch (input) {
                    case 'nopayments' :
                        tooltip = 'پرداخت اول';
                        break;
                    case 'limitedpackages':
                        tooltip = 'چند بسته ای';
                        break;
                }
                return tooltip;
            },
            toIcon(input) {

                let icon = '';

                switch (input) {
                    case 'nopayments' :
                        icon = 'fas fa-gift';
                        break;
                    case 'limitedpackages':
                        icon = 'fas fa-cubes';
                        break;
                }
                return icon;
            }
        }
    }


</script>

<style scoped>

</style>
