<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <router-view></router-view>
            </v-col>
        </v-row>

    </v-container>
</template>

<script>
    export default {}
</script>

<style scoped>

</style>