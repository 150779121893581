<template>
  <div>

    <v-dialog v-model="addCycle" width="700">
      <AddNewCycle
          :clientId="$store.getters.getClientProfile.id"
          :addCycleAction="'adminAddClientNewCycle'"
          :isAdmin="true"
          :getProfile="false"
          @cycleAdded="cycleAdded"
          :dialog="true"
          @close="addCycle=false"
      ></AddNewCycle>
    </v-dialog>

    <v-container>
      <v-row>
        <v-col  cols="12">
          <v-card-title
          >
            دوره های درمان
            <v-spacer></v-spacer>
            <v-btn class="elevation-0" color="primary" small @click="addCycle=true">
              افزودن دوره جدید
              <v-icon class="ma-1" small>fas fa-plus</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider></v-divider>
        </v-col>


        <v-col cols="12">
          <AdminCreateQuestion/>
        </v-col>


      </v-row>


    </v-container>


  </div>
</template>

<script>
import AddNewCycle from "@/components/clients/AddNewCycle";
import AdminCreateQuestion from "@/components/Admin/questions/AdminCreateQuestion";
// import {EventBus} from "../../../event-bus";

export default {
  name: "AdminQuestions",
  components: {AdminCreateQuestion, AddNewCycle},
  data() {
    return {
      addCycle: false,
      addedCycle: false
    }
  },
  computed: {
    hasCycle() {
      return this.$store.getters.getClientProfile.cycles &&
          this.$store.getters.getClientProfile.cycles.length === 0 &&
          !this.addedCycle;
    },
  },
  methods: {
    cycleAdded() {
      this.$store.dispatch('getUserProfile', {
        userid: this.$router.currentRoute.params.clientId
      });
      this.addCycle = false;
      this.addedCycle = true;
    }
  },

}
</script>

<style scoped>

</style>