<template>
    <nav>
        <v-dialog v-model="inviteModel" max-width="800" scrollable>
            <v-card>
                <v-card-title>
                    دعوت از دوستان
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" class="text-center">

                            <v-row align="center" justify="center">
                                <v-col cols="12" sm="6">
                                    <v-carousel
                                            cycle
                                            width="300"
                                            height="380"
                                            :show-arrows="false"
                                            style="direction:ltr"
                                            reverse
                                    >
                                        <v-carousel-item
                                                v-for="(slide, i) in slides"
                                                :key="i"
                                        >
                                            <v-img
                                                    :src="slide.src"
                                            ></v-img>
                                        </v-carousel-item>
                                    </v-carousel>

                                </v-col>
                            </v-row>
                            <v-row align="center" justify="center">
                                <v-col cols="12">
                                    با ارسال لینک زیر به دوستان خود، آنها را به خانواده {{getPlatform.slug}} دعوت
                                    نمایید.
                                </v-col>
                                <v-col cols="10" class="text-center">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field v-bind="attrs" v-on="on" readonly
                                                          ref="mylink"
                                                          @click="copyToClipboard"
                                                          :value="getPlatform.domain+'/fa/panel/login?inviter='+getUserInfo.code"
                                                          style="direction: ltr;"
                                                          outlined>
                                            </v-text-field>

                                        </template>
                                        <span>برای کپی کلیک کنید</span>
                                    </v-tooltip>
                                </v-col>
                            </v-row>
                            <v-row class="pa-0 ma-0">
                                <v-col cols="12">
                                    همچنین می توانید با استفاده از شبکه های اجتماعی زیر، لینک را به اشتراک بگذارید.
                                </v-col>
                                <v-col cols="12">
                                    <ShareNetwork
                                            v-for="(network,index) in networks"
                                            :key="index"
                                            :network="network.title"
                                            :url="'https://app.simiaroom.com/fa/panel/login?inviter='+getUserInfo.code"
                                            :title="network.text"
                                            :description="network.description"
                                            :quote="network.quote"
                                            hashtags="simiaroom,simiaservice"
                                    >
                                        <v-btn
                                                fab class="px-0 ma-1" :class="network.color" small>
                                            <v-icon class="">{{network.icon}}</v-icon>
                                        </v-btn>
                                    </ShareNetwork>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions></v-card-actions>
            </v-card>
        </v-dialog>


        <v-app-bar app class="elevation-2" color="white">

            <template v-if="showNavIcon">
                <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
                <div>
                    <!--<LanguageSelect></LanguageSelect>-->
                </div>

                <v-btn text :to="{name:'clientCart'}" class="" v-if="$store.getters.userHasRole('customer')">
                    <div class="myBadge" v-if="cartItems">{{cartItems}}</div>
                    <v-icon>mdi-cart-outline</v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <router-link :to="{name:'dashboard'}">
                    <v-toolbar-title>
                        <v-img :src="require('../assets/logo2.png')" max-width="100"></v-img>
                    </v-toolbar-title>
                </router-link>
            </template>

            <template v-if="showTopMenu">
                <router-link :to="{name:'dashboard'}">
                    <v-toolbar-title class="mr-12 ml-12">
                        <v-img :src="require('../assets/logo2.png')" max-width="100"></v-img>
                    </v-toolbar-title>
                </router-link>

                <admin-nav-items v-if="$store.getters.userHasRole('admin') && isLoggedin"></admin-nav-items>
                <counselor-nav-items
                        v-else-if="$store.getters.userHasRole('counselor') && isLoggedin"></counselor-nav-items>
                <client-nav-items
                        v-else-if="$store.getters.userHasRole('customer') && isLoggedin"></client-nav-items>

                <v-btn class="info mx-3"
                       v-if="$store.getters.userHasRole('admin') && isLoggedin"
                       text
                       @click="$router.push({name:'express-entry'})"
                >
                    ورود اکسپرس
                </v-btn>

                <v-spacer></v-spacer>

                <!--<SearchUser class="pt-5" v-if="$store.getters.userHasRole('admin') && isLoggedin"></SearchUser>-->


                <v-btn class="info mx-3"
                       v-if="$store.getters.userHasRole('customer') && isLoggedin"
                       text
                       small
                       @click="openSessionRequestDialog">
                    درخواست جلسه
                </v-btn>

                <v-menu
                        v-model="walletMenu"
                        :close-on-content-click="false"
                        :nudge-width="200"
                        offset-y
                        open-on-hover
                        transition="slide-y-transition"
                        close-delay="200"
                        v-if="getUserInfo != null && isLoggedin"


                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn text :to="{name:'wallet'}" class="mx-1" fab small
                               v-on="on"
                               v-bind="attrs"
                               v-if="$store.getters.userHasRole('customer') && isLoggedin"
                        >
                            <v-badge :content="totalWalletItems"
                                     :value="totalWalletItems"
                                     left
                                     :offset-x="-5"
                                     :offset-y="-5"
                                     color="green"
                            >
                            </v-badge>
                            <v-icon>fas fa-heartbeat</v-icon>
                        </v-btn>
                    </template>


                    <v-card>
                        <v-list>
                            <v-list-item dense>
                                <v-list-item-content>
                                    <v-list-item-title class="font-weight-bold">جلسات باقی مانده
                                    </v-list-item-title>
                                    <v-list-item-subtitle>مجموع : {{getUserInfo.wallet.total}}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-divider></v-divider>

                            <v-list-item v-for="drpack in getUserInfo.wallet.drPackages" :key="drpack.id" dense
                                         :class="{'red lighten-1':drpack.total < 0}">
                                <v-list-item-avatar class="elevation-2">
                                    <v-img :src="drpack.avatar" alt="John"></v-img>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title :class="{'white--text':drpack.total < 0}"><span>دکتر {{drpack.full_name}}</span>
                                    </v-list-item-title>
                                    <v-list-item-subtitle :class="{'white--text':drpack.total < 0}">تعداد جلسات :
                                        {{drpack.total}}
                                    </v-list-item-subtitle>
                                </v-list-item-content>

                            </v-list-item>

                            <v-list-item v-if="walletGlobalItems" dense
                                         :class="{'red lighten-1':walletGlobalItems < 0}">
                                <v-list-item-avatar class="elevation-2">
                                    <v-img :src="getPlatform.logo"></v-img>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title :class="{'white--text':walletGlobalItems < 0}">
                                        <span>جلسات روانشناسی</span>
                                    </v-list-item-title>
                                    <v-list-item-subtitle :class="{'white--text':walletGlobalItems < 0}">
                                        تعداد جلسات : {{walletGlobalItems}}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                        </v-list>
                    </v-card>
                </v-menu>

                <v-btn small fab text @click="toggleSideChat" v-if="$route.name!='messenger'">

                    <v-badge :content="getNewMessageCount"
                             left
                             :value="getNewMessageCount">
                        <v-icon>mdi-message</v-icon>
                    </v-badge>

                </v-btn>

                <v-btn text :to="{name:'clientCart'}" class="mx-1" fab small
                       v-if="$store.getters.userHasRole('customer') && isLoggedin">
                    <v-badge :content="cartItems"
                             left
                             :value="cartItems">
                        <v-icon>mdi-cart-outline</v-icon>
                    </v-badge>

                </v-btn>
                <!--<v-menu-->
                <!--v-model="monetaryWalletMenu"-->
                <!--:close-on-content-click="false"-->
                <!--:nudge-width="200"-->
                <!--offset-y-->
                <!--open-on-hover-->
                <!--transition="slide-y-transition"-->
                <!--close-delay="200"-->
                <!--v-if="getUserInfo != null && isLoggedin"-->


                <!--&gt;-->
                <!--<template v-slot:activator="{ on, attrs }">-->
                <!--<v-btn text :to="{name:'userMonetaryWallet'}" class="mx-1" fab small-->
                <!--v-on="on"-->
                <!--v-bind="attrs"-->
                <!--v-if="$store.getters.userHasRole('customer') && isLoggedin"-->
                <!--&gt;-->
                <!--<v-icon>fas fa-wallet</v-icon>-->
                <!--</v-btn>-->
                <!--</template>-->

                <!--<v-card>-->
                <!--<v-list>-->
                <!--<v-list-item dense>-->
                <!--<v-list-item-content>-->
                <!--<v-list-item-title class="font-weight-bold">وضعیت کیف پول-->
                <!--</v-list-item-title>-->
                <!--<v-list-item-subtitle>-->
                <!--</v-list-item-subtitle>-->
                <!--</v-list-item-content>-->
                <!--</v-list-item>-->
                <!--<v-divider></v-divider>-->

                <!--<v-list-item v-for="(item,currency) in getUserInfo.monetary_wallet" :key="currency" dense>-->
                <!--<v-list-item-avatar class="elevation-2">-->
                <!--<v-icon v-if="currency=='dollar'" class="primary white&#45;&#45;text">-->
                <!--fas fa-dollar-sign-->
                <!--</v-icon>-->
                <!--<v-btn fab v-else class="white&#45;&#45;text primary">-->
                <!--تومان-->
                <!--</v-btn>-->
                <!--&lt;!&ndash;<v-icon v-else class="primary white&#45;&#45;text">&ndash;&gt;-->
                <!--&lt;!&ndash;mdi-currency-rial&ndash;&gt;-->
                <!--&lt;!&ndash;</v-icon>&ndash;&gt;-->
                <!--</v-list-item-avatar>-->
                <!--<v-list-item-content>-->
                <!--<v-list-item-title>-->
                <!--{{currency | resolveCurrency}}-->
                <!--</v-list-item-title>-->
                <!--<v-list-item-subtitle-->
                <!--:class="{'blue&#45;&#45;text':item.balance>0,'red&#45;&#45;text':item.balance<0}">-->
                <!--{{item.balance | resolvePrice(currency)}}-->
                <!--</v-list-item-subtitle>-->
                <!--</v-list-item-content>-->

                <!--</v-list-item>-->

                <!--</v-list>-->
                <!--</v-card>-->
                <!--</v-menu>-->
                <!---->
                <!--<v-btn text :to="{name:'userMonetaryWallet'}" class="mx-1" fab small-->
                <!--v-if="$store.getters.userHasRole('customer') && isLoggedin">-->
                <!--<v-icon>fas fa-wallet</v-icon>-->
                <!--</v-btn>-->
                <div>
                    <!--<LanguageSelect></LanguageSelect>-->
                </div>
                <template v-if="isLoggedin">
                    <div class="text-center">


                        <v-menu
                                v-if="getUserInfo!=null"
                                :close-on-content-click="false"
                                :nudge-width="200"
                                offset-y
                                open-on-hover
                                transition="slide-y-transition"
                                close-delay="200"

                        >
                            <template v-slot:activator="{ on }">
                                <v-btn class="elevation-0 transparent mx-5" fab>
                                    <v-avatar>
                                        <img v-on="on"
                                             v-if="$store.getters.userHasRole('admin') || $store.getters.userHasRole('customer')"
                                             src='./../assets/avatar-default.png'/>
                                        <img
                                                v-else
                                                style="object-fit: cover"
                                                :src="getUserInfo.avatar ? getUserInfo.avatar : require('./../assets/avatar-default.png')"
                                                alt="avatar"
                                                v-on="on"
                                        >
                                    </v-avatar>
                                </v-btn>
                            </template>

                            <v-card>
                                <v-list>
                                    <v-list-item>

                                        <v-list-item-content>
                                            <v-list-item-title v-if="getUserInfo">{{
                                                getUserInfo.firstname + ' ' +
                                                getUserInfo.lastname }}
                                            </v-list-item-title>
                                        </v-list-item-content>

                                    </v-list-item>
                                </v-list>

                                <v-divider></v-divider>

                                <v-list dense>
                                    <template
                                            v-if="getUserInfo != null && isLoggedin && $store.getters.userHasRole('customer')">
                                        <v-list-item v-for="(item,currency) in getUserInfo.monetary_wallet"
                                                     :key="currency" dense>
                                            <v-list-item-title
                                                    :class="{'blue--text':item.balance>0,'red--text':item.balance<0}">
                                                کیف پول شما
                                                <div style="direction:ltr;display: inline-block;">
                                                    {{item.balance | resolvePrice(currency)}}
                                                </div>
                                                {{currency | resolveCurrency}}
                                            </v-list-item-title>
                                            <!--<v-list-item-content>-->
                                            <!---->
                                            <!--</v-list-item-content>-->

                                        </v-list-item>

                                    </template>
                                    <v-list-item>
                                        <v-list-item-title>
                                            <v-btn text rounded :to="{name:'profile'}">حساب کاربری</v-btn>
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item v-if="$store.getters.userHasRole('customer')">
                                        <v-list-item-title>
                                            <v-btn text rounded @click="inviteModel = true">دعوت از دوستان</v-btn>
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item>

                                        <v-list-item-title>
                                            <v-btn text rounded @click="logOut">خروج
                                            </v-btn>
                                        </v-list-item-title>
                                    </v-list-item>
                                </v-list>

                            </v-card>
                        </v-menu>
                    </div>
                </template>
                <div v-else>
                    <v-btn text class="mx-3" small :to="{name:'login'}" dark>
                        <span class="black--text">
                            {{$vuetify.lang.t('$vuetify.loginLabel')}} | {{$vuetify.lang.t('$vuetify.signUpLabel')}}
                        </span>
                    </v-btn>
                </div>


            </template>

        </v-app-bar>

        <v-navigation-drawer
                v-model="drawer"
                temporary
                app
                :right="isRTL"
        >
            <default-nav-items v-if="!isLoggedin"></default-nav-items>
            <client-nav-items v-if="$store.getters.userHasRole('customer') && isLoggedin"></client-nav-items>
            <counselor-nav-items v-if="$store.getters.userHasRole('counselor') && isLoggedin"></counselor-nav-items>
            <admin-nav-items v-if="$store.getters.userHasRole('admin') && isLoggedin"></admin-nav-items>
            <v-list-item v-if="isLoggedin">
                <v-list-item-icon>
                    <v-icon>mdi-logout-variant</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>
                        <v-btn text @click="logOut">خروج</v-btn>
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-navigation-drawer>
    </nav>
</template>
<script>
    // import LanguageSelect from '@/components/LanguageSelect'
    import AdminNavItems from '@/components/navs/AdminNavItems'
    import CounselorNavItems from '@/components/navs/CounselorNavItems'
    import ClientNavItems from '@/components/navs/ClientNavItems'
    import DefaultNavItems from "./navs/DefaultNavItems";
    // import SearchUser from "./SearchUser";
    import {mapGetters} from 'vuex';
    import {EventBus} from "../event-bus";
    import {resolveCurrency} from "../utilities";


    export default {
        components: {

            // LanguageSelect,
            AdminNavItems,
            CounselorNavItems,
            ClientNavItems,
            DefaultNavItems,
            // SearchUser
        },
        data() {
            return {

                slides: [
                    {
                        src: require('../assets/1.gif'),
                    },
                    {
                        src: require('../assets/2.gif'),
                    },
                    {
                        src: require('../assets/3.gif'),
                    },
                ],
                networks: [
                    {
                        title: 'email',
                        text: '',
                        icon: 'mdi-email',
                        url: '',
                        description: 'لبت خندون، دلت آروم\n' +
                            'سیمیاروم؛ یه تجربه خوب از مشاوره آنلاین روانشناسیه، با حرفه‌ای‌ترین روان‌شناس‌ها و روان‌کاوهای فارسی زبان.\n' +
                            'حالِ من خیلی بهتره، تو هم حال دلتو خوب کن',
                        quote: 'quote',
                        hashtags: 'hashtags',
                        color: 'blue white--text'
                    },
                    {
                        title: 'facebook',
                        text: '',
                        icon: 'mdi-facebook',
                        url: '',
                        description: 'description',
                        quote: 'لبت خندون، دلت آروم\n' +
                            'سیمیاروم؛ یه تجربه خوب از مشاوره آنلاین روانشناسیه، با حرفه‌ای‌ترین روان‌شناس‌ها و روان‌کاوهای فارسی زبان.\n' +
                            'حالِ من خیلی بهتره، تو هم حال دلتو خوب کن',
                        hashtags: 'hashtags',
                        color: 'blue white--text'
                    },
                    {
                        title: 'twitter',
                        text: 'لبت خندون، دلت آروم\n' +
                            'سیمیاروم؛ یه تجربه خوب از مشاوره آنلاین روانشناسیه، با حرفه‌ای‌ترین روان‌شناس‌ها و روان‌کاوهای فارسی زبان.\n' +
                            'حالِ من خیلی بهتره، تو هم حال دلتو خوب کن',
                        icon: 'mdi-twitter',
                        url: '',
                        description: 'description',
                        quote: 'quote',
                        hashtags: 'hashtags',
                        color: 'blue white--text lighten-1'
                    },
                    {
                        title: 'telegram',
                        text: '',
                        icon: 'mdi-telegram',
                        url: '',
                        description: 'لبت خندون، دلت آروم\n' +
                            'سیمیاروم؛ یه تجربه خوب از مشاوره آنلاین روانشناسیه، با حرفه‌ای‌ترین روان‌شناس‌ها و روان‌کاوهای فارسی زبان.\n' +
                            'حالِ من خیلی بهتره، تو هم حال دلتو خوب کن',
                        quote: 'quote',
                        hashtags: 'hashtags',
                        color: 'blue white--text darken-1'
                    },
                    {
                        title: 'whatsapp',
                        text: '',
                        icon: 'mdi-whatsapp',
                        url: '',
                        description: 'لبت خندون، دلت آروم\n' +
                            'سیمیاروم؛ یه تجربه خوب از مشاوره آنلاین روانشناسیه، با حرفه‌ای‌ترین روان‌شناس‌ها و روان‌کاوهای فارسی زبان.\n' +
                            'حالِ من خیلی بهتره، تو هم حال دلتو خوب کن',
                        quote: 'quote',
                        hashtags: 'hashtags',
                        color: 'green white--text'
                    },
                ],
                inviteModel: false,
                monetaryWalletMenu: false,
                walletMenu: false,
                path: this.$route.name,
                drawer: false,
            };
        },
        computed: {
            ...mapGetters(['getUserInfo', 'getCart', 'getPlatform','getNewMessageCount']),
            showNavIcon() {
                // return true;
                return this.$vuetify.breakpoint.smAndDown;
            },
            showTopMenu() {
                return this.$vuetify.breakpoint.mdAndUp;
            },
            isRTL() {
                return this.$store.getters.isRTL;
            },
            walletGlobalItems() {

                return this.getUserInfo.wallet.global_packages;
            },
            totalWalletItems() {

                return this.getUserInfo.wallet.total;
            },
            walletDoctorItems() {
                return this.getUserInfo.wallet.dr_packages;
            },
            // cartItems() {
            //     let sum = 0;
            //     const items = this.$store.getters.getCartItems;
            //     items.forEach(item => {
            //         sum += item.qty;
            //     });
            //     return sum;
            // },
            cartItems() {
                return this.$store.getters.cartQuantity;

            },
            isLoggedin() {
                return this.$store.getters.userIsLoggedIn;
            }
        },
        created() {
            EventBus.$on('openInvite', this.openInviteModal);
            // console.log(this.$store.state.userInfo);
        },
        beforeDestroy() {
            EventBus.$off('openInvite', this.openInviteModal);
        },
        filters: {
            resolveCurrency(input) {
                return resolveCurrency(input);
                // return input == 'dollar' ? 'دلار' : 'تومان';
            },
            resolvePrice(amount, currency) {
                return currency == 'dollar' ? amount.toFixed(2) : amount.toLocaleString();
            }
        },
        watch: {},
        methods: {
            toggleSideChat() {
                EventBus.$emit('toggleSideChat');
            },
            openSessionRequestDialog() {
                EventBus.$emit('showAddSessionDialog');
            },
            openInviteModal() {

                this.inviteModel = true;
            },
            copyToClipboard() {
                let textToCopy = this.$refs.mylink.$el.querySelector('input');
                textToCopy.select();

                document.execCommand("copy");
                // textToCopy.selection.empty();
                // if (document.selection) {
                //     document.selection.empty();
                // }
            },
            logOut() {
                this.$store.dispatch('logOut').then(() => {
                    this.$router.push({name: 'login'});
                });
            }
        }
    }
</script>
<style>
    .myBadge {
        position: absolute;
        top: -8px;
        right: 0px;
        background-color: red;
        width: 17px;
        height: 17px;
        line-height: 19px;
        text-align: center;
        border-radius: 30px;
        color: white;
    }
</style>