<template>
    <v-container class="py-12 " fluid>
        <v-row justify="center" align="center">
            <!--<DashboardCard title="تعداد جلسات" :info="dashboard.allSessionsCount"-->
            <!--:img="require('../../assets/dashboard/session.svg')"></DashboardCard>-->
            <!--<DashboardCard title="مراجعان" :info="dashboard.allPatientsCount"-->
            <!--:img="require('../../assets/dashboard/userCount.svg')"></DashboardCard>-->

            <template v-if="dashboard.combinedSales.length==0">
                <v-col
                        v-for="i in 8"
                        :key="i"
                        cols="6"
                        sm="4"
                        md="3"
                        lg="2"
                        class="pa-1"
                >
                    <v-skeleton-loader
                            class="mx-auto"
                            max-height="70"
                            type="card"
                    ></v-skeleton-loader>

                </v-col>
            </template>
            <template v-else>
                <v-col
                        v-for="(item, currency) in dashboard.combinedSales"
                        :key="currency"
                        cols="6"
                        sm="4"
                        md="3"
                        lg="2"
                        class="pa-1"
                >

                    <v-card
                            class="px-0 py-0 elevation-1 gradient-monetary"
                            dark
                    >
                        <v-list-item>
                            <v-list-item-icon>
                                <span style="text-transform: uppercase;" class="caption font-weight-bold">
                                    <!--<v-icon>mdi-currency-{{currency | resolveIcon}}</v-icon>-->
                                    <span>{{currency|resolveCurrency}}</span>
                                </span>
                            </v-list-item-icon>
                            <v-list-item-content>

                                <v-row class="pa-0 ma-0 caption font-weight-bold">
                                    <v-col cols="12" class="pa-0 ma-0">
                                        جمع:{{Number(Number(item.total).toFixed(2)).toLocaleString()}}
                                    </v-col>
                                    <v-col class="pa-0 ma-0">
                                        امروز:{{Number(Number(item.today).toFixed(2)).toLocaleString()}}
                                    </v-col>
                                </v-row>
                            </v-list-item-content>
                        </v-list-item>
                    </v-card>
                </v-col>
                <v-col
                        cols="6"
                        sm="4"
                        md="3"
                        lg="2"
                        class="pa-1"
                >
                    <v-card
                            class="px-0 py-2 elevation-1 gradient"
                            dark
                    >
                        <v-list-item>
                            <v-list-item-icon>
                                <span style="text-transform: uppercase;" class="caption font-weight-bold">
                                    <span>تعداد جلسات</span>
                                </span>
                            </v-list-item-icon>
                            <v-list-item-content>

                                <v-row class="pa-0 ma-0 subtitle-2">
                                    <v-col class="pa-0 ma-0">
                                        {{dashboard.allSessionsCount}}
                                    </v-col>
                                </v-row>
                            </v-list-item-content>

                        </v-list-item>
                    </v-card>
                </v-col>
                <v-col
                        cols="6"
                        sm="4"
                        md="3"
                        lg="2"
                        class="pa-1"
                >
                    <v-card
                            class="px-0 py-2 elevation-1 gradient"
                            dark
                    >
                        <v-list-item>
                            <v-list-item-icon>
                                <span style="text-transform: uppercase;" class="caption font-weight-bold">
                                    <!--<v-icon>mdi-currency-{{currency | resolveIcon}}</v-icon>-->
                                    <span>تعداد کاربران</span>
                                </span>

                            </v-list-item-icon>
                            <v-list-item-content>

                                <v-row class="pa-0 ma-0 subtitle-2">
                                    <v-col class="pa-0 ma-0">
                                        {{dashboard.allPatientsCount}}
                                    </v-col>

                                </v-row>
                            </v-list-item-content>

                        </v-list-item>
                    </v-card>
                </v-col>
            </template>

        </v-row>

        <v-container>
            <v-row>
                <v-col>
                    <v-card class="mt-12 pa-0">
                        <v-card-title class="secondary--text font-weight-bold">گزارشات</v-card-title>
                        <v-divider></v-divider>
                        <v-row class="pa-2" justify="center">
                            <v-col cols="6" sm="4">
                                <v-card class="pa-1 pa-md-5" to="sessionrequests">
                                    <v-row>
                                        <v-col cols="8" class="subtitle-2">
                                            <span>درخواست های جلسات</span>
                                        </v-col>
                                        <v-col cols="4">
                                            <v-chip class="px-2">
                                                {{ dashboard.sessionRequests }}
                                            </v-chip>
                                        </v-col>
                                    </v-row>
                                </v-card>
                            </v-col>
                            <v-col cols="6" sm="4">
                                <v-card class="pa-1 pa-md-5" to="nocycle">
                                    <v-row>
                                        <v-col cols="8" class="subtitle-2">
                                            <span>تعیین مشاور نشده</span>
                                        </v-col>
                                        <v-col cols="4">
                                            <v-chip class="px-2">
                                                {{ dashboard.notCycle }}
                                            </v-chip>
                                        </v-col>
                                    </v-row>
                                </v-card>
                            </v-col>
                            <v-col cols="6" sm="4">
                                <v-card class="pa-1 pa-md-5" to="notime">
                                    <v-row>
                                        <v-col cols="8" class="subtitle-2">
                                            <span>تعیین وقت نشده</span>
                                        </v-col>
                                        <v-col cols="4">
                                            <v-chip class="px-2">
                                                {{ dashboard.notTimeBook }}
                                            </v-chip>
                                        </v-col>
                                    </v-row>
                                </v-card>
                            </v-col>

                            <v-col cols="6" sm="4">
                                <v-card class="pa-1 pa-md-5" :to="'sessions/' + `${today}`">
                                    <v-row>
                                        <v-col cols="8" class="subtitle-2">
                                            <span>جلسات روز</span>
                                        </v-col>
                                        <v-col cols="4">
                                            <v-chip class="px-2">
                                                {{ dashboard.todaySessionsCount }}
                                            </v-chip>
                                        </v-col>
                                    </v-row>
                                </v-card>
                            </v-col>
                            <v-col cols="6" sm="4">
                                <v-card class="pa-1 pa-md-5" :to="{name: 'requests'}">
                                    <v-row>
                                        <v-col cols="8" class="subtitle-2">
                                            <span>درخواست ها</span>
                                        </v-col>
                                        <v-col cols="4">
                                            <v-chip class="px-2">
                                                {{ dashboard.ticketsCount }}
                                            </v-chip>
                                        </v-col>
                                    </v-row>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-container>

</template>

<script>
    // import {mapGetters} from 'vuex';
    import moment from 'moment-jalaali';
    import DashboardCard from "../DashboardCard";
    import {EventBus} from "../../event-bus";
    import {resolveCurrency} from "../../utilities";
    /*eslint-disable*/
    export default {
        components: {
            DashboardCard
        },
        data() {
            return {
                today: moment().format("YYYY-MM-DD"),
                dashboard: {
                    usersCount: '',
                    totalSession: '',
                    allDoctorsCount: '',
                    todaySales: [],
                    totalSales: [],
                    combinedSales: []

                },
                todaySales: [],
            }
        },
        created() {
            EventBus.$emit('setLoading', true);
            this.$store.dispatch('getAdminDashboard').then((resp) => {

                this.dashboard = resp.data;
                this.dashboard.todaySales = resp.data.today_sales;
                this.dashboard.combinedSales = resp.data.combined;


                // this.dashboard.todaySales = [
                //     {value: resp.data.todayDollarAmount.toFixed(2), title: 'دلار'},
                //     {value: Number(resp.data.todayRialAmountPayment).toLocaleString(), title: 'تومان'}];
                //
                //
                // this.dashboard.totalSales = [
                //     {value: resp.data.dollarAmount, title: 'دلار'},
                //     {value: Number(resp.data.rialAmountPayment).toLocaleString(), title: 'تومان'}
                // ];

            }).finally(() => {
                EventBus.$emit('setLoading', false);
            })
        },
        computed: {
            // ...mapGetters({
            //     dashboard: 'getAdminDashboard'
            // })
        },
        filters: {
            resolveIcon(currency) {
                let result = '';
                switch (currency) {
                    case 'rial':
                        result = 'rial';
                        break;
                    case 'dollar':
                        result = 'usd';
                        break;
                    case 'euro':
                        result = 'eur';
                        break;
                    case 'aud':
                        result = 'usd';
                        break;
                    case 'cad':
                        result = 'usd';
                        break;
                    case 'gbp':
                        result = 'gbp';
                        break;
                }
                return result;
            },
            resolveCurrency(input) {
                return resolveCurrency(input);
                // let result = '';
                // switch (currency) {
                //     case 'rial':
                //         result = 'تومان';
                //         break;
                //     case 'dollar':
                //         result = 'دلار';
                //         break;
                //     case 'euro':
                //         result = 'یورو';
                //         break;
                //     case 'aud':
                //         result = 'دلار استرالیا';
                //         break;
                //     case 'cad':
                //         result = 'دلار کانادا';
                //         break;
                //     case 'gbp':
                //         result = 'پوند';
                //         break;
                // }
                // return result;
            }
        }
    }
</script>

<style scoped>

    .gradient-monetary {
        /*background: #00416A;  !* fallback for old browsers *!*/
        /*background: -webkit-linear-gradient(to bottom, #00416A, #00126A);  !* Chrome 10-25, Safari 5.1-6 *!*/
        background: linear-gradient(45deg, #0081aa, #00619A); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    }

    .gradient {
        /*background: #00416A;  !* fallback for old browsers *!*/
        /*background: -webkit-linear-gradient(to bottom, #00416A, #00126A);  !* Chrome 10-25, Safari 5.1-6 *!*/
        background: linear-gradient(35deg, #00ccca, #00619A); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    }

    /*.gradient-reverse {*/
    /*background: linear-gradient(215deg, #00ccca, #00619A); !* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ *!*/
    /*}*/
</style>