<template>
    <v-form ref="editStateForm">

        <v-card>
            <v-card-text class="pa-5">
                <v-row>
                    <v-col>
                        <v-select label="وضعیت"
                                  outlined
                                  item-text="title"
                                  item-value="value"
                                  v-model="itemToEditNewState"
                                  :items="sessionStates"
                                  :value="itemToEdit.state"
                                  @change="changeItemToEditState"
                        ></v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col v-if="requiresEnteringDoctor">
                        <v-autocomplete

                                v-model="itemToEditDoctor"
                                :items="doctors"
                                :loading="isLoading"
                                hide-no-data
                                hide-selected
                                item-text="full_name"
                                item-value="doctor_profile.id"
                                outlined
                                label="دکتر"
                                :rules="requiredRules"
                        ></v-autocomplete>
                    </v-col>
                    <v-col v-if="requiresEnteringTime">
                        <!--<v-menu-->
                        <!--ref="sessionToEditDateRef"-->
                        <!--v-model="sessionToEditDateMenu"-->
                        <!--:close-on-content-click="false"-->
                        <!--:return-value.sync="sessionToEditDate"-->
                        <!--transition="scale-transition"-->
                        <!--offset-y-->
                        <!--min-width="290px"-->
                        <!--&gt;-->
                        <!--<template v-slot:activator="{ on, attrs }">-->

                        <v-text-field
                                :value="sessionToEditDate | toJalali"
                                outlined
                                label="تاریخ جلسه"
                                prepend-icon="event"
                                readonly
                                @click="showPicker=true"
                                :rules="requiredRules"
                        >
                        </v-text-field>
                        <AdminDatePicker @close="showPicker=false" :dialog="showPicker"
                                         v-model="sessionToEditDate"></AdminDatePicker>
                        <!--</template>-->
                        <!--<v-date-picker v-model="sessionToEditDate" no-title scrollable>-->
                        <!--<v-spacer></v-spacer>-->
                        <!--<v-btn text color="primary" @click="sessionToEditDateMenu = false">Cancel-->
                        <!--</v-btn>-->
                        <!--<v-btn text color="primary"-->
                        <!--@click="$refs.sessionToEditDateRef.save(sessionToEditDate)">OK-->
                        <!--</v-btn>-->
                        <!--</v-date-picker>-->
                        <!--</v-menu>-->
                    </v-col>
                    <v-col v-if="requiresEnteringTime">
                        <v-menu
                                ref="menu"
                                v-model="sessionToEditTimeMenu"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                :return-value.sync="sessionToEditTime"
                                transition="scale-transition"
                                max-width="250px"
                                min-width="250px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                        v-model="sessionToEditTime"
                                        label="ساعت جلسه"
                                        prepend-icon="access_time"
                                        readonly
                                        v-bind="attrs"
                                        outlined
                                        :rules="requiredRules"
                                        v-on="on"
                                ></v-text-field>
                            </template>
                            <v-time-picker
                                    v-if="sessionToEditTimeMenu"
                                    format="24hr"
                                    width="250"
                                    v-model="sessionToEditTime"
                                    @click:minute="$refs.menu.save(sessionToEditTime)"
                            ></v-time-picker>
                        </v-menu>
                    </v-col>

                </v-row>
                <v-row v-if="itemToEdit.state=='doctor_assigned' && itemToEditNewState == 'doctor_assigned'">

                    <!--<v-row align="center" justify="center" v-if="doctorTimes!=null" class="pa-0 ma-0">-->
                    <!--<v-col cols="12" class="text-center">-->
                    <!--<template v-if="Object.keys(doctorTimes).length">-->
                    <!--زمان مورد نظر خود را انتخاب نمایید-->
                    <!--<DoctorTimes @timeSelected="selectTime"-->
                    <!--:doctorTimes="doctorTimes"></DoctorTimes>-->
                    <!--</template>-->
                    <!--<template v-else>-->
                    <!--مشاور شما در حال حاضر زمان خالی ندارد، لطفا تا خالی شدن زمان مشاور صبر-->
                    <!--کنید-->
                    <!--و بعدا اقدام به ثبت زمان نمایید-->
                    <!--</template>-->

                    <!--</v-col>-->
                    <!--</v-row>-->

                    <v-col>
                        <v-card :loading="requesting" class="elevation-0 ma-3 pa-3 mt-0 pt-0">

                            <template v-if="requesting">
                                <v-row class="text-center">
                                    <v-col class="text-center">
                                        <span>لطفا صبر کنید</span>
                                    </v-col>
                                </v-row>
                            </template>
                            <template v-else>
                                <v-row align="center" justify="center" v-if="doctorTimes!=null">
                                    <v-col cols="12" class="text-center">
                                        <template v-if="Object.keys(doctorTimes).length">
                                            زمان مورد نظر خود را انتخاب نمایید
                                            <DoctorTimes @timeSelected="selectTime"
                                                         :doctorTimes="doctorTimes"></DoctorTimes>
                                        </template>
                                        <template v-else>
                                            مشاور شما در حال حاضر زمان خالی ندارد، لطفا تا خالی شدن زمان مشاور صبر
                                            کنید
                                            و بعدا اقدام به ثبت زمان نمایید
                                        </template>

                                    </v-col>
                                </v-row>
                            </template>
                            <v-card-actions>

                                <v-spacer></v-spacer>
                                <v-btn
                                        class=" elevation-0 primary white--text"
                                        @click="saveTimeForRequest"
                                >
                                    ذخیره
                                </v-btn>
                            </v-card-actions>

                        </v-card>
                    </v-col>
                </v-row>

            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn fab x-small
                       @click="cancelEditing"
                       class="mx-1 red--text"

                >
                    <v-icon small>
                        fas fa-times
                    </v-icon>
                </v-btn>
                <v-btn fab x-small
                       @click="changeState"
                       class="mx-1 green--text"

                >
                    <v-icon small>
                        fas fa-check
                    </v-icon>
                </v-btn>
            </v-card-actions>
        </v-card>

    </v-form>
</template>

<script>

    import DoctorTimes from '@/components/clients/DoctorTimes';
    import {EventBus} from "../event-bus";
    import AdminDatePicker from '@/components/AdminDatePicker';
    import moment from 'moment-jalaali';

    export default {
        props: ['itemToEdit'],
        components: {
            DoctorTimes, AdminDatePicker
        },
        created() {
            this.itemToEditNewState = this.itemToEdit.state;
            this.getDoctors();
            if (this.itemToEdit.state == 'doctor_assigned') {
                this.getTimes();
            }
        },

        data() {
            return {
                showPicker: false,
                sessionStates: [
                    {title: 'درخواست جلسه', value: 'session_requested'},
                    {title: 'دکتر ست شده', value: 'doctor_assigned'},
                    {title: 'زمان ست شده', value: 'date_fixed'},
                    {title: 'برگزار شده', value: 'used'},
                ],
                requesting: false,
                selectedTime: null,
                doctorTimes: {},
                isLoading: false,
                itemToEditDoctor: null,
                sessionToEditDate: null,
                sessionToEditTime: null,
                sessionToEditTimeMenu: false,
                sessionToEditDateMenu: false,
                itemToEditNewState: null,

                editState: false,

            }
        },
        methods: {
            saveTimeForRequest() {
                if (this.selectedTime == null || this.selectedTime.length == 0) {
                    EventBus.$emit('notify', 'red', undefined, 'لطفا یکی از زمانهای مشاور را انتخاب نمایید');
                    return;
                }

                let payload = {
                    time: this.selectedTime
                };

                this.$store.dispatch('adminSelectSessionRequestTime', {id: this.itemToEdit.id, payload}).then(() => {
                    EventBus.$emit('notify', 'green', undefined, 'با موفقیت به ثبت رسید');
                    this.$emit('done');
                })
            },

            selectTime(hour) {
                this.selectedTime = hour[0];
            },
            getTimes() {
                this.requesting = true;
                this.$store.dispatch('getSessionRequestTimes', {id: this.itemToEdit.id}).then((resp) => {
                    this.doctorTimes = resp.data.doctorTimes;
                    console.log(this.doctorTimes);
                    this.requesting = false;
                }).finally(() => {
                    this.requesting = false;
                })
            },
            changeItemToEditState(state) {
                this.itemToEditNewState = state;
            },
            getDoctors() {
                this.isLoading = true;
                this.$store
                    .dispatch("getAllDoctors")
                    .then(res => {
                        this.doctors = res.data.doctors;
                        this.isLoading = false;
                    })
                    .catch(err => {
                        console.log(err);
                    }).finally(() => {

                });
            },
            cancelEditing() {
                this.itemToEditNewState = null;
                this.$emit('close');
                // this.$refs['session-' + item.id].internalValue = item.state;
            },
            changeState() {
                if (this.$refs.editStateForm.validate()) {

                    let datetime = null;
                    if (this.sessionToEditDate != null && this.sessionToEditTime != null) {
                        datetime = `${this.sessionToEditDate} ${this.sessionToEditTime}`;
                    }

                    let options = {
                        session: this.itemToEdit.id,
                        state: this.itemToEditNewState,
                        doctorId: this.itemToEditDoctor,
                        datetime: datetime,
                    };
                    console.log(options);
                    EventBus.$emit('setLoading', true);
                    this.$store.dispatch('adminSessionChangeState', options).then(() => {
                        this.$emit('done');
                    }).finally(() => {
                        EventBus.$emit('setLoading', false);
                    })

                }
            },
            editSessionState(session) {
                this.itemToEdit = session;
                this.editState = true;
            },
        },
        computed: {
            requiredRules() {
                return [v => !!v || this.$vuetify.lang.t('$vuetify.fillIsRequired')];
            },
            requiresEnteringTime() {
                if (this.itemToEdit.state == 'session_requested' || this.itemToEdit.state == 'doctor_assigned') {
                    if (this.itemToEditNewState == 'date_fixed' || this.itemToEditNewState == 'used') {
                        return true;
                    }
                }
                return false;
            },
            requiresEnteringDoctor() {
                if (this.itemToEdit.state == 'session_requested') {
                    if (this.itemToEditNewState == 'date_fixed' || this.itemToEditNewState == 'used' || this.itemToEditNewState == 'doctor_assigned') {
                        return true;
                    }
                }
                return false;
            },
        },
        filters: {
            toJalali(date) {
                if (date) {
                    return moment(date, "YYYY-M-DD").format('jYYYY/jMM/jDD');
                }
                return date;
            },
        }

    }
</script>

<style scoped>

</style>