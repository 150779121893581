<template>
    <v-container>
        <requests-component></requests-component>
    </v-container>
</template>

<script>
    import RequestsComponent from "../../components/RequestsComponent";
    export default {
        components: {
            RequestsComponent
        }
    };
</script>


