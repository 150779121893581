<template>
    <ReportComponent>
    </ReportComponent>
</template>

<script>
    import ReportComponent from '@/components/Admin/reports/ReportComponent'

    export default {
        components: {
            ReportComponent
        }

    }
</script>

<style scoped>

</style>