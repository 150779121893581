<template>
    <v-card>
        <v-card-title>
            تغییر زمان محلی
        </v-card-title>
        <v-card-text>
            <v-form ref="changeTimezoneForm">
                <v-autocomplete
                        menu-props="auto"
                        label="زمان محلی"
                        v-model="timezone"
                        :items="timezones"
                        item-value="value"
                        prepend-icon="fas fa-clock"
                        single-line
                        outlined
                        hint="با تغییر زمان محلی، زمان های ثبت شده آینده شما حذف خواهند شد"
                        persistent-hint
                >
                </v-autocomplete>
            </v-form>
        </v-card-text>
        <!--<v-card-actions>-->
        <!--<v-spacer></v-spacer>-->
        <!--<v-btn class="primary elevation-0" @click="changeTz" small>تغییر زمان محلی</v-btn>-->
        <!--</v-card-actions>-->
    </v-card>
</template>

<script>
    import timezones from "../data/timezones";
    import moment from 'moment-timezone';
    import {EventBus} from "../event-bus";
    import {mapGetters} from 'vuex';

    export default {
        props: {
            timezone: {
                default: null
            }
        },
        created() {
            this.sortAndGenerateTimezoneOffset();
        },
        computed:{
            ...mapGetters(['getUserInfo'])
        },
        methods: {
            sortAndGenerateTimezoneOffset() {
                let x = JSON.parse(JSON.stringify(timezones));
                let tempTime = x.map((item) => {
                    let tznum = moment.tz(item.text).format('Z');

                    item.offset = moment.tz(item.text).utcOffset();
                    item.text = item.text + ' ' + tznum;
                    return item;
                });
                tempTime = tempTime.sort((a, b) => {
                    return a.offset - b.offset;
                });
                this.timezones = tempTime;
            },

            changeTz() {
                EventBus.$emit('setLoading', true);
                let data = {
                    timezone: this.timezone
                };

                this.$store.dispatch('saveUserInfo', data).then(() => {
                    this.$store.dispatch('getUserInfo').then(() => {
                        EventBus.$emit('notify', 'green', undefined, 'با موفقیت به ثبت رسید');
                        EventBus.$emit('setLoading', false);
                        this.$emit('tzChange');
                    })


                });
            }
        },
        data() {
            return {
                timezones: []
            }
        },
        watch: {
            timezone(newVal, oldVal) {
                if (newVal != null && newVal != oldVal) {
                    if(newVal!=this.getUserInfo.tz) {
                        this.changeTz();
                    }
                }
            }
        }
    }
</script>

<style scoped>

</style>