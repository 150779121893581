<template>
    <div>
        <v-container fluid  style="background-color: #333">
            <v-row>
                <v-container >
                    <v-row dense>
                        <v-col cols="12" >
                            <v-card
                                    flat
                                    tile
                                    color="#333"
                            >
                                <v-row>
                                    <v-col cols="12" lg="2" md="4" sm="6" v-if="isSimiaroom">
                                        <v-card dark dense color="#333" class="elevation-0">
                                            <v-card-title class="subtitle-1 font-weight-bold">
                                                <span>
                                                    دغدغه های پر تکرار
                                                </span>
                                            </v-card-title>
                                            <v-card-text>
                                                <v-list color="#333">
                                                    <div v-for="(item,index) in issues" :key="index"
                                                         class="footer-section-row caption">
                                                        <a class="footer-links" :href="item.href" target="_blank">{{item.title}}</a>
                                                    </div>
                                                </v-list>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>

                                    <v-col cols="12" lg="2" md="4" sm="6" v-if="isSimiaroom">
                                        <v-card dark dense color="#333" class="elevation-0">
                                            <v-card-title class="subtitle-1 font-weight-bold">
                                                <span>
                                                    سیمیاروم
                                                </span>
                                            </v-card-title>
                                            <v-card-text>
                                                <v-list color="#333">
                                                    <div v-for="(item,index) in simiaroom" :key="index"
                                                         class="footer-section-row caption">
                                                        <a class="footer-links" :href="item.href" target="_blank">{{item.title}}</a>
                                                    </div>
                                                </v-list>

                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                    <v-col cols="12" lg="4" md="4" sm="6" v-if="!isSimiaroom">
                                        <v-card-title class="white--text font-weight-light">
                                            {{aboutUsTitle}}
                                        </v-card-title>
                                        <v-card-text class="white--text pt-0 mt-0 font-weight-light">
                                            <div class="mt-3">
                                                {{aboutUsText}}
                                            </div>

                                            <!--<div class="text-center ma-4" v-if="isSimiaroom">-->
                                            <!--<a href="https://simiaroom.com/why-simiaroom-online-therapy/">-->
                                            <!--<v-btn class="custom-gradient pa-6" text>-->
                                            <!--<span class="light-green-button">-->
                                            <!--چرا مشاوره آنلاین؟-->
                                            <!--</span>-->
                                            <!--</v-btn>-->
                                            <!--</a>-->
                                            <!--</div>-->
                                        </v-card-text>

                                    </v-col>


                                    <v-col cols="12" lg="3" md="4" sm="6" v-if="isSimiaroom">
                                        <v-card dark color="#333" class="elevation-0">
                                            <v-card-title class="subtitle-1 white--text font-weight-bold">
                                                <span>
                                                    شبکه های اجتماعی سیمیا
                                                </span>
                                            </v-card-title>
                                            <v-card-text class="text-center">
                                                <v-btn
                                                        v-for="(socialAccount,index) in socialAccounts"
                                                        :key="index"
                                                        class="mx-1 white--text text-center justify-center"
                                                        :class="socialAccount.color"
                                                        :href="socialAccount.link"
                                                        target="_blank"
                                                        fab small
                                                >
                                                    <v-icon>{{ socialAccount.icon }}</v-icon>
                                                </v-btn>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                    <v-col cols="12" lg="4" md="4" sm="6" v-if="isSimiaroom">
                                        <v-card-title class="white--text font-weight-light">تماس با سیمیا
                                        </v-card-title>
                                        <v-card-text class="white--text pt-0 mt-0 font-weight-light">
                                            <div class="mt-3 footer-links">
                                                <!--<v-icon class="mx-1">phone</v-icon>-->
                                                <a href="tel:۰۰۹۸۲۱۸۸۵۶۲۶۱۵" class="footer-link">
                                                    <span>شماره تماس: ۰۰۹۸۲۱۸۸۵۶۲۶۱۵</span>
                                                </a>

                                            </div>

                                            <div class="footer-links">
                                                <!--<v-icon class="mx-1">email</v-icon>-->
                                                <a href="mailto:COUNSELING@SIMIASERVICE.COM" class="footer-link">
                                                    <span>ایمیل: COUNSELING@SIMIASERVICE.COM</span>
                                                </a>
                                            </div>
                                            <div class=" footer-links">
                                                <!--<v-icon class="mx-1">fas fa-telegram</v-icon>-->
                                                <a href="https://telegram.me/maryamkhazraee"
                                                   class="footer-link">
                                                    <span>پیام رسان تلگرام:@MARYAMKHAZRAEE</span>
                                                </a>
                                            </div>
                                            <div class="footer-links">
                                                <!--<v-icon class="mx-1">fas fa-whatsapp</v-icon>-->
                                                <a href="http://wa.me/989302672054" class="footer-link">
                                                    <span>پیام رسان واتساپ: 00989302672054</span>
                                                </a>


                                            </div>
                                        </v-card-text>
                                    </v-col>
                                </v-row>
                                <!--<v-col cols="12" lg="3" md="3" sm="6">-->
                                <!--<v-card-title class="white&#45;&#45;text font-weight-light">FAQ</v-card-title>-->
                                <!--<v-card-text class="white&#45;&#45;text pt-0 mt-0 font-weight-light">-->
                                <!--<div class="mt-3">-->
                                <!--<div class="mt-3">How Can I Join?</div>-->
                                <!--<div class="mt-3">Why Should I Join?</div>-->
                                <!--<div class="mt-3">More...</div>-->
                                <!--</div>-->
                                <!--</v-card-text>-->
                                <!--</v-col>-->

                                <v-divider></v-divider>
                                <v-row class="px-5" v-if="isSimiaroom">
                                    <v-col cols="auto"><a class="white--text"
                                                          style="text-decoration: none;font-size: .7em;"
                                                          href="https://simiaroom.com/why-online-therapy/">چرا
                                        مشاوره‌
                                        آنلاین سیمیا؟</a></v-col>
                                    <v-col cols="auto"><a class="white--text"
                                                          style="text-decoration: none;font-size: .7em;"
                                                          href="https://simiaroom.com/%d8%b4%d8%b1%d8%a7%db%8c%d8%b7-%d8%b6%d9%88%d8%a7%d8%a8%d8%b7-%d9%85%d8%b4%d8%a7%d9%88%d8%b1%d9%87/">شرایط
                                        و ضوابط مشاوره سیمیآروم</a></v-col>
                                    <v-col cols="auto"><a class="white--text"
                                                          style="text-decoration: none;font-size: .7em;"
                                                          href="https://simiaroom.com/counselor/">مشاوران</a>
                                    </v-col>
                                    <v-col cols="auto"><a class="white--text"
                                                          style="text-decoration: none;font-size: .7em;"
                                                          href="https://simiaroom.com/%d8%af%d8%b1%d8%a8%d8%a7%d8%b1%d9%87-%d9%85%d8%a7/">درباره
                                        ما</a></v-col>
                                    <v-col cols="auto"><a class="white--text"
                                                          style="text-decoration: none;font-size: .7em;"
                                                          href="https://simiaroom.com/blog/">وبلاگ</a></v-col>
                                    <v-col cols="auto"><a class="white--text"
                                                          style="text-decoration: none;font-size: .7em;"
                                                          href="https://simiaroom.com/online-therapy-faq/">سوالات
                                        متداول
                                        مشاوره آنلاین روانشناسی</a></v-col>
                                    <v-col cols="auto"><a class="white--text"
                                                          style="text-decoration: none;font-size: .7em;"
                                                          href="https://simiaroom.com/%d8%aa%d9%85%d8%a7%d8%b3-2/">تماس
                                        با ما</a></v-col>
                                </v-row>

                                <!--<v-card-text class="text-center">-->
                                <!--<v-btn-->
                                <!--v-for="icon in icons"-->
                                <!--:key="icon"-->
                                <!--class="mx-4 white&#45;&#45;text text-center justify-center"-->
                                <!--icon-->
                                <!--&gt;-->
                                <!--<v-icon>{{ icon }}</v-icon>-->
                                <!--</v-btn>-->
                                <!--</v-card-text>-->


                                <v-card-text class="white--text text-center">

                                </v-card-text>
                            </v-card>

                        </v-col>
                    </v-row>
                </v-container>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-card class="elevation-0" dark color="#222">
                        <v-card-text class="text-center">
                            <strong class="mx-1">کليه حقوق اين وب سایت متعلق به سیمیاروم می باشد.</strong>
                            <div>
                                <span class="caption">{{ new Date().getFullYear() }}</span>
                                <span class="mx-1">—</span>
                                <v-icon small class="" color="#aaa">far fa-copyright</v-icon>
                            </div>


                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>

        </v-container>
        <!--<v-card-->
        <!--flat-->
        <!--tile-->
        <!--dark-->
        <!--class=""-->
        <!--&gt;-->
        <!--<div class="pt-5 text-center">-->
        <!--در صورت بروز مشکل در فرایند ثبت سفارش، با پشتیبانی سیمیاروم در واتس اپ یا تلگرام به شماره-->
        <!--<v-btn rounded class="primary" small>-->
        <!--<span-->
        <!--style="display: inline-block;direction: ltr!important; text-align: left">-->
        <!--<a class="white&#45;&#45;text no-decoration"-->
        <!--href="whatsapp://send?phone=+989302672054">+989302672054</a>-->
        <!--</span>-->
        <!--</v-btn>-->
        <!--پیام ارسال فرمایید.-->
        <!--</div>-->

        <!--<v-card-text class="text-center">-->
        <!--<v-btn-->
        <!--v-for="(socialAccount,index) in socialAccounts"-->
        <!--:key="index"-->
        <!--class="mx-4 white&#45;&#45;text text-center justify-center"-->
        <!--socialAccount-->
        <!--:href="socialAccount.link"-->
        <!--target="_blank"-->
        <!--fab small-->
        <!--&gt;-->
        <!--<v-icon>{{ socialAccount.icon }}</v-icon>-->
        <!--</v-btn>-->
        <!--</v-card-text>-->


        <!--<v-divider></v-divider>-->

        <!--<v-card-text class="white&#45;&#45;text text-center">-->
        <!--{{ new Date().getFullYear() }}-->
        <!--<span class="mx-1">—</span>-->
        <!--<strong class="mx-1">مشاوره روانشناسی آنلاین</strong>-->
        <!--<v-icon small>far fa-copyright</v-icon>-->
        <!--</v-card-text>-->
        <!--</v-card>-->
    </div>
</template>
<script>
    export default {
        props: {
            isSimiaroom: {
                default: true
            }
        },
        computed: {
            aboutUsTitle() {
                if (this.isSimiaroom) {
                    return 'درباره سیمیاروم';
                } else {
                    return 'درباره مشاور لایو';
                }


            },
            aboutUsText() {
                if (this.isSimiaroom) {
                    return 'اگر از مشاوره روانشناسی آنلاین اطلاعات کمی دارید توصیه می کنیم راهنمای مشاوره های آنلاین روانشناسی را در لینک زیر مشاهده نمایید:';
                } else {
                    return 'ارائه دهنده خدمات یکپارچه مشاوره ویدئویی برای روانشناسان و روانپزشکان';
                }
            }
        },
        data: () => ({
            simiaroom: [
                {title: 'بلاگ', href: 'https://simiaroom.com/blog/'},
                {title: 'مشاوران سیمیا', href: 'https://simiaroom.com/counselor/'},
                {title: 'تماس با ما', href: 'https://simiaroom.com/%d8%aa%d9%85%d8%a7%d8%b3-2/'},
                {title: 'درباره ما', href: 'https://simiaroom.com/%d8%af%d8%b1%d8%a8%d8%a7%d8%b1%d9%87-%d9%85%d8%a7/'},
            ],
            issues: [
                {title: 'مشاوره خانواده', href: 'https://simiaroom.com/blog/category/family-counselling/'},
                {
                    title: 'زوج درمانی',
                    href: 'https://simiaroom.com/counseling/%d9%85%d8%b4%d8%a7%d9%88%d8%b1%d9%87-%d8%a7%d8%b2%d8%af%d9%88%d8%a7%d8%ac/'
                },
                {title: 'مشاوره زناشویی', href: 'https://simiaroom.com/blog/category/marital-counseling/'},
                {title: 'مشاوره فردی', href: 'https://simiaroom.com/blog/category/individual-counseling/'},
                {
                    title: 'مشاوره کودک و نوجوان',
                    href: 'https://simiaroom.com/blog/category/child-and-adolescent-counselling/'
                },
                {title: 'حوزه های مشاوره', href: 'https://simiaroom.com/counseling/'},
            ],
            socialAccounts: [
                {
                    icon: 'fab fa-facebook',
                    link: 'https://www.facebook.com/simiatalk',
                    color: 'blue darken-4'

                },
                {
                    icon: 'fab fa-twitter',
                    link: 'https://twitter.com/SimiaRoom',
                    color: 'blue'

                },

                {
                    icon: 'fab fa-linkedin',
                    link: 'https://www.linkedin.com/company/simiaroom/',
                    color: 'blue darken-2'

                },
                {
                    icon: 'fab fa-instagram',
                    link: 'https://www.instagram.com/simiaroomcom/',
                    color: 'red lighten-1'
                },
                {
                    icon: 'fab fa-youtube',
                    link: 'https://www.youtube.com/channel/UCgM4KoG4MZg-ATBchGzQnAw/videos',
                    color: 'red'
                },
            ],
        }),
    }
</script>
<style>
    .no-decoration {
        text-decoration: none !important;
    }

    .footer-links {
        color: #ccc !important;
        text-decoration: none;
        font-weight: bold;
        padding: 15px 10px;
        margin: 0;
        display: inline-block;
        width: 100%;
        border-radius: 15px;
        -webkit-transition: all .3s ease-in-out;
        -moz-transition: all .3s ease-in-out;
        -ms-transition: all .3s ease-in-out;
        -o-transition: all .3s ease-in-out;
        transition: all .3s ease-in-out;
    }

    .footer-links:hover {
        background-color: white;
        color: #222 !important;
        box-shadow: 0 10px 10px -7px rgba(0,0,0,.3);
        /*padding-right: 15px;*/
    }

    .footer-section-row {

    }

    .footer-link {
        text-decoration: none;
        color: #999 !important;
        /*padding: 15px 0;*/
    }

    .footer-link:hover {
        color: #222 !important;
    }
</style>