<template>
    <v-card>
        <v-dialog v-model="requestConfirmDialog" max-width="400">
            <v-card>
                <v-card-title>تایید درخواست جلسه</v-card-title>
                <v-divider></v-divider>

                <v-card-text class="pa-5">
                    آیا نسبت به درخواست {{sessionCount}} جلسه اطمینان دارید؟
                </v-card-text>

                <v-card-actions>
                    <v-btn class="red white--text" text @click="requestConfirmDialog = false">بستن</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn class="primary" text @click="sessionRequestConsent">تایید</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-card-title>
            {{pageTitle}}
        </v-card-title>

        <v-card-text>
            <v-alert type="info">
                <template v-if="!profile.cycles.length">
                    لطفا موضوع مشاوره، شرح مشکل و تعداد جلسات خود را وارد نمایید
                </template>
                <template v-else>
                    لطفا دوره درمان خود را انتخاب نمایید
                </template>
            </v-alert>
            <v-form
                    ref="addCycleForm"
            >
                <v-row>
                    <v-col v-for="cycle in profile.cycles" :key="cycle.id" cols="4">

                        <v-card>

                            <v-card-title>
                                موضوع دوره :
                                {{cycle.heading}}
                            </v-card-title>

                            <v-card-subtitle>
                                تعداد کل جلسات {{cycle.sessionCount}}
                            </v-card-subtitle>

                            <v-card-actions>

                                <v-btn
                                        color="purple"
                                        text
                                        @click="requestSession(cycle)"
                                >
                                    درخواست جلسه
                                </v-btn>

                                <v-spacer></v-spacer>

                                <!--<v-btn-->
                                <!--text-->
                                <!--color="primary"-->
                                <!--@click="showDetails(cycle)"-->
                                <!--&gt;-->
                                <!--جزئیات-->
                                <!--<v-icon>{{ detailsToShow.includes(cycle.id) ? 'mdi-chevron-up' :-->
                                <!--'mdi-chevron-down' }}-->
                                <!--</v-icon>-->
                                <!--</v-btn>-->
                            </v-card-actions>

                            <!--<v-expand-transition>-->
                            <!--<div v-show="detailsToShow.includes(cycle.id)">-->
                            <!--<v-divider></v-divider>-->

                            <!--<v-card-text>-->
                            <!--{{cycle.problem_description}}-->
                            <!--</v-card-text>-->
                            <!--</div>-->
                            <!--</v-expand-transition>-->
                        </v-card>
                    </v-col>
                </v-row>
                <v-row align="center" justify="center">
                    <!--<v-col style="direction: ltr" class="text-center">-->
                    <!--<v-btn-toggle>-->
                    <!--<v-btn :height="btnWH" :width="btnWH" color="red"-->
                    <!--@click="decreaseSessionCount">-->

                    <!--<v-icon class="white&#45;&#45;text" large>-->
                    <!--mdi-minus-->
                    <!--</v-icon>-->
                    <!--</v-btn>-->
                    <!--<v-btn :height="btnWH" :width="btnWH">-->
                    <!--<span class="display-1">{{sessionCount}}</span>-->
                    <!--</v-btn>-->

                    <!--<v-btn :height="btnWH" :width="btnWH" color="green"-->
                    <!--@click="addSessionCount">-->
                    <!--<v-icon class="white&#45;&#45;text" large>-->
                    <!--mdi-plus-->
                    <!--</v-icon>-->
                    <!--</v-btn>-->
                    <!--</v-btn-toggle>-->
                    <!--</v-col>-->
                    <!--<v-col cols="4" lg="2" sm="3" md="2" class="text-center">-->
                    <!--<v-btn fab color="green" @click="addSessionCount">-->
                    <!--<v-icon class="white&#45;&#45;text">-->
                    <!--mdi-plus-->
                    <!--</v-icon>-->
                    <!--</v-btn>-->
                    <!--</v-col>-->
                    <!--<v-col cols="4" lg="2" sm="3" md="2" class="text-center">-->
                    <!--&lt;!&ndash;<v-text-field v-model="sessionCount" solo type="number"></v-text-field>&ndash;&gt;-->

                    <!--</v-col>-->
                    <!--<v-col cols="4" lg="2" sm="3" md="2" class="text-center">-->
                    <!--<v-btn fab color="red" @click="decreaseSessionCount">-->

                    <!--<v-icon class="white&#45;&#45;text">-->
                    <!--mdi-minus-->
                    <!--</v-icon>-->
                    <!--</v-btn>-->
                    <!--</v-col>-->
                </v-row>
                <v-row justify="center" align="center">
                    <v-col cols="12" sm="8" md="4"
                           v-if="!profile.cycles.length"
                    >


                        <v-select
                                :items="categories"
                                v-model="title"
                                label="موضوع مشاوره"
                                outlined
                                :rules="[rules.requiredRule]"
                        ></v-select>
                    </v-col>
                    <v-col cols="12" md="6" sm="8" class="text-center"
                           v-if="!profile.cycles.length"
                    >


                        <v-textarea

                                v-model="description"
                                outlined
                                label="شرح مساله"
                                :rules="[rules.requiredRule]"
                        ></v-textarea>
                    </v-col>
                </v-row>
                <!--<v-col cols="12" md="4" sm="8" class="text-center">-->


                <!--&lt;!&ndash;<v-text-field&ndash;&gt;-->
                <!--&lt;!&ndash;outlined&ndash;&gt;-->
                <!--&lt;!&ndash;label="تعداد جلسات"&ndash;&gt;-->
                <!--&lt;!&ndash;placeholder="تعداد جلسات را وارد کنید"&ndash;&gt;-->
                <!--&lt;!&ndash;v-model="sessionCount"&ndash;&gt;-->
                <!--&lt;!&ndash;:rules="sessionCountRules"&ndash;&gt;-->
                <!--&lt;!&ndash;&gt;</v-text-field>&ndash;&gt;-->
                <!--</v-col>-->
            </v-form>
        </v-card-text>
        <v-card-actions v-if="dialog">
            <v-btn class="primary" @click="addNewCycle">ثبت</v-btn>
            <v-btn class="red white--text" @click="close">بستن</v-btn>
        </v-card-actions>

    </v-card>
</template>

<script>
    /*eslint-disable*/
    import {EventBus} from "../../event-bus";
    import {mapGetters} from 'vuex';


    export default {
        props: {
            dialog: {
                default: false
            },
            getProfile: {
                default: true
            }
        },
        data() {
            return {
                requestConfirmDialog: false,
                sessionCount: 1,
                title: null,
                description: null,
                categories: [
                    'فردی',
                    'زوج',
                    'خانواده',
                    'کودک و نوجوان',
                    'سکس تراپی',
                    'روانپزشکی'
                ],
                rules: {
                    requiredRule: v => !!v || this.$vuetify.lang.t('$vuetify.fillIsRequired'),
                    lengthRule: v => v.length >= 20 || 'حداقل 20 کاراکتر',

                }
            }
        },
        computed: {
            btnWH() {
                return this.$vuetify.breakpoint.smAndDown ? 50 : 80;
            },
            pageTitle() {
                return this.profile.cycles.length ? "انتخاب دوره" : 'افزودن دوره جدید';
            },
            sessionCountRules() {
                return [
                    value => !!value || 'تعداد جلسات را وارد نمایید.',
                    value => (value > 0) || 'تعداد جلسات حداقل باید 1 باشد',
                    value => (value <= this.profile.wallet.total) || 'تعداد جلسات درخواستی باید از جلسات خریداری شده شما کمتر باشد',
                ];
            },
            ...mapGetters({
                profile: 'getUserInfo'
            })
        },
        created() {
            EventBus.$on('postConsultationInfoEvent', this.addNewCycle);

            if (this.$route.name != 'expressSession') {

                //SESSION COUNT
                // if (this.profile.wallet.global_packages > this.profile.wallet.dr_packages) {
                //     this.sessionCount = this.profile.wallet.global_packages;
                // } else {
                //     this.sessionCount = this.profile.wallet.dr_packages;
                // }

            } else {
                this.sessionCount = 1;
            }

        },
        beforeDestroy() {
            EventBus.$off('postConsultationInfoEvent', this.postConsultation);
        },
        methods: {
            decreaseSessionCount() {
                if (this.sessionCount > 1) {
                    this.sessionCount--;
                } else {
                    EventBus.$emit('notify', 'red', undefined, 'حداقل تعداد جلسات 1 می باشد.');
                }
            },
            addSessionCount() {
                let max = 0;
                if (this.profile.wallet.global_packages > this.profile.wallet.dr_packages) {
                    // this.sessionCount = this.profile.wallet.global_packages;
                    max = this.profile.wallet.global_packages;
                } else {
                    // this.sessionCount = this.profile.wallet.dr_packages;
                    max = this.profile.wallet.dr_packages;
                }

                if (this.sessionCount < max) {
                    this.sessionCount++;
                } else {
                    EventBus.$emit('notify', 'red', undefined, 'تعداد جلسات شما کافی نیست.');
                }

            },
            requestSession(cycle) {
                if (this.sessionCount > 1) {
                    this.requestConfirmDialog = true;
                } else if (this.sessionCount == 1) {
                    this.sessionRequestConsent(cycle);
                }

            },
            sessionRequestConsent(cycle) {

                this.requestConfirmDialog = false;
                this.$emit('cycleAdded', {
                    sessionCount: this.sessionCount,
                    cycle: cycle
                });
                return;
            },
            addNewCycle() {


                if (this.$refs.addCycleForm.validate()) {

                    if (this.profile.cycles.length) {
                        this.requestSession(this.profile.cycles[0]);
                        return;
                    }

                    let payload = {
                        title: this.title,
                        description: this.description,
                    };

                    // if (this.profile.cycles.length == 0) {
                    //     payload.requestFirstSession = true;
                    // }

                    this.$store.dispatch('addNewCycle', payload).then(() => {
                        EventBus.$emit('notify', 'green', undefined, 'با موفقیت به ثبت رسید');

                        if (this.getProfile) {
                            this.$store.dispatch('getUserInfo').then(() => {
                                this.$emit('cycleAdded', {
                                    sessionCount: this.sessionCount
                                });
                                this.close();
                            });
                        } else {
                            this.$emit('cycleAdded', {
                                sessionCount: this.sessionCount
                            });
                            this.close();
                        }


                    });
                } else {
                    EventBus.$emit('postConsultationInfoError');
                }
            },
            close() {
                this.$emit('close');
            }
        }
    }
</script>

<style scoped>

</style>