<template>
    <div>
        <v-container>
            <v-card class="my-8">
                <v-card-title>
                    {{ title }}
                    <v-spacer ref="counselorClients"></v-spacer>
                    <v-text-field
                            solo
                            v-model="search"
                            append-icon="mdi-magnify"
                            label="جستجو"
                            single-line
                            hide-details
                    ></v-text-field>
                </v-card-title>
                <v-data-table
                        hide-default-footer
                        :headers="headers"
                        :items="clients"
                        :search="search"
                >
                    <template v-slot:item.fullname="{ item }">
                        <div @click="getClientProfile(item.id)" style="cursor: pointer;">
                            {{ item.name }} {{item.family}}
                        </div>
                    </template>
                    <template v-slot:item.cycle="{ item }">
                        {{item.cycle.heading}}
                    </template>
                    <template v-slot:item.nextSession="{ item }">
                        <template v-if="item.next_session!=null">
                            {{item.next_session.diff_time}}
                        </template>

                        <!--<template v-if="nextSessions[item.id] != null">-->
                            <!--{{nextSessions[item.id]}}-->
                        <!--</template>-->
                        <!--<template v-if=" != null "></template>-->
                    </template>

                    <template v-slot:item.action="{ item }">
                        <v-btn color="primary my-2" @click="getClientProfile(item.id)" small>
                            مشاهده پرونده
                        </v-btn>
                    </template>
                </v-data-table>
            </v-card>
            <div class="text-center" v-if="!fiveSession">
                <v-pagination
                        v-model="page"
                        :length="total"
                ></v-pagination>
            </div>
        </v-container>
    </div>
</template>

<script>
    /*eslint-disable*/
    // import {mapGetters} from 'vuex'
    import {EventBus} from "../../event-bus";

    export default {
        props: {
            title: {
                default: 'مراجعان',
                type: String
            },
            fiveSession: Boolean
        },
        data() {
            return {
                // loading: true,
                nextSessions: [],
                search: '',
                headers: [
                    {
                        text: 'مراجعه کننده',
                        align: 'start',
                        sortable: false,
                        value: 'fullname',
                    },
                    {text: 'جلسه بعدی', value: 'nextSession', sortable: false},
                    {text: 'دوره درمان', value: 'cycle', sortable: false},
                    {text: 'اطلاعات کاربر', value: 'action', sortable: false},
                ],
                desserts: [],
                page: 1,
                total: null,
                clients: []
            }
        },
        computed: {
            // ...mapGetters({
            //     clients: 'getCounselorClients',
            // }),
        },
        created() {
            this.getCounselorClients();
        },
        methods: {
            getCounselorClients() {
                EventBus.$emit('setLoading', true);
                this.$store.dispatch('getCounselorClients', {page: this.page}).then((resp) => {
                    // this.nextSessions = resp.data.next;
                    this.total = resp.data.pagination.total_pages;
                    // this.clients = resp.data.group.clients;
                    this.clients = resp.data.users;

                }).finally(() => {
                    EventBus.$emit('setLoading', false);
                })
            },
            // nextPage() {
            //     this.$store.dispatch('pageChangeHandle', {
            //         value: 'next', page: this.page,
            //         url: '/counselor/users/list',
            //         commitName: 'setCounselorClients'
            //     });
            // },
            // previousPage() {
            //     this.$store.dispatch('pageChangeHandle', {
            //         value: 'previous', page: this.page,
            //         url: '/counselor/users/list',
            //         commitName: 'setCounselorClients'
            //     });
            // },
            // specificPage(page) {
            //     this.$store.commit('SET_SPECIFIC_PAGE', page);
            //     this.$store.dispatch('pageChangeHandle', {
            //         value: '', page: page,
            //         url: '/counselor/users/list',
            //         commitName: 'setCounselorClients'
            //     });
            // },
            getClientProfile(id) {
                this.$router.push('clients/' + `${id}` + '/profile');
                // this.$store.dispatch('getClientInfo', id).then((data) => {
                //
                // })
            }
        },
        watch: {
            page() {
                this.getCounselorClients();
                this.$vuetify.goTo(this.$refs.counselorClients.offsetTop);
            }
        }
    }
</script>

<style scoped>

</style>