<template>

    <v-container>
        <v-form ref="profileForm">
            <v-row>
                <v-col cols="12">
                    <v-card-text class="pa-0 font-weight-bold">
                        {{$vuetify.lang.t('$vuetify.profileInformationTitle')}}
                    </v-card-text>
                </v-col>
            </v-row>
            <v-row class="px-md-10">
                <v-col cols="12" sm="6" md="6">
                    <v-text-field
                            v-model="userInfo.firstname"
                            outlined
                            :label="$vuetify.lang.t('$vuetify.profileFirstname')"
                            :rules="requiredRules"

                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                    <v-text-field
                            v-model="userInfo.lastname"
                            outlined
                            :label="$vuetify.lang.t('$vuetify.profileLastname')"
                            :rules="requiredRules"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                    <v-text-field
                            v-model="userInfo.cel"
                            outlined
                            :label="$vuetify.lang.t('$vuetify.profileMobile')"

                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                    <v-text-field
                            v-model="userInfo.email"
                            outlined
                            :label="$vuetify.lang.t('$vuetify.profileEmail')"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                    <v-autocomplete
                            prepend-inner-icon="map"
                            outlined
                            v-model="userInfo.tz"
                            :items="timezones"
                            item-value="value"
                            menu-props="auto"
                            :label="$vuetify.lang.t('$vuetify.profileTZ')"
                            hide-details
                            single-line
                            :rules="requiredRules"
                    ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                    <v-autocomplete
                            outlined
                            v-model="userInfo.support_title"
                            :items="supportTitles"
                            menu-props="auto"
                            :label="$vuetify.lang.t('$vuetify.profileSupportTitle')"
                            hide-details
                            prepend-inner-icon="mdi-account-star-outline"
                            :rules="requiredRules"
                    ></v-autocomplete>
                </v-col>
            </v-row>

            <!--            <v-snackbar-->
            <!--                    top-->
            <!--                    color="success"-->
            <!--                    v-model="snackbar"-->
            <!--                    :timeout="timeout"-->
            <!--            >-->
            <!--                {{ text }}-->
            <!--                <v-btn-->
            <!--                        fab-->
            <!--                        color="success"-->
            <!--                >-->
            <!--                    <v-icon>mdi-check</v-icon>-->
            <!--                </v-btn>-->
            <!--            </v-snackbar>-->
        </v-form>
    </v-container>
</template>

<script>
    /*eslint-disable*/
    import {EventBus} from '../../../event-bus.js';
    import timezones from "../../../data/timezones";
    import moment from 'moment-timezone';

    export default {
        beforeDestroy() {
            EventBus.$off('saveProfileEvent', this.saveProfile);
            EventBus.$off('resetProfileEvent', this.resetProfile);
        },
        created() {

            let x = JSON.parse(JSON.stringify(timezones));
            this.timezones = x.map((item) => {
                item.text = item.text + ' ' + moment.tz(item.text).format('Z');
                return item;
            });

            this.getUserInfo();
            // console.log('IT IS CREATED');
            EventBus.$on('saveProfileEvent', this.saveProfile);
            EventBus.$on('resetProfileEvent', this.resetProfile);
            // console.log('-=-=-=-=--=--=-=', Object.entries(this.userInfo))
        },
        data() {
            return {
                supportTitles: ['پشتیبان', 'پشتیبان مالی', 'پشتیبان فنی'],
                timezones: timezones,
                userInfo: {},
                locale: this.$store.getters.getLocal,
                modal: false,
                // snackbar: false,
                // timeout: 2000,
            };
        },
        methods: {
            getUserInfo() {
                EventBus.$emit('setLoading',true);
                this.$store.dispatch('getUserInfo').then(() => {
                    this.userInfo = this.$store.getters.getUserInfo;
                }).finally(() => {
                    EventBus.$emit('setLoading',false);
                });
            },
            saveProfile() {
                if (this.$refs.profileForm.validate()) {
                    // console.log('Saving');
                    // const fd = new FormData();

                    let profile = {
                        'name': this.userInfo.firstname,
                        'family': this.userInfo.lastname,
                        'timezone': this.userInfo.tz,
                        'support_title': this.userInfo.support_title,
                        'password': this.userInfo.password,
                        'password_confirmation': this.userInfo.password_confirmation,
                    };

                    EventBus.$emit('setLoading',true);
                    this.$store.dispatch('adminSaveProfile', profile).then(() => {
                        this.snackbar = true;
                        EventBus.$emit('notify', 'green', undefined, 'با موفقیت به ثبت رسید');
                    }).finally(() => {
                        EventBus.$emit('setLoading',false);
                    });

                }
            },
            resetProfile() {
                // console.log('Reseting');
                this.userInfo = {};
            },
            addToResume() {
                this.userInfo.resume.push(this.resume);
                this.resume = '';
            },
            removeResume(item) {
                this.userInfo.resume.splice(this.userInfo.resume.indexOf(item), 1);
            },
            editResume(item) {
                this.resume = item;
            },
            onFileSelected(event) {
                this.userInfo.avatar = URL.createObjectURL(event.target.files[0]);
            }
        },
        computed: {
            requiredRules() {
                return [v => !!v || this.$vuetify.lang.t('$vuetify.fillIsRequired')];
            },
            // text() {
            //     return this.$vuetify.lang.t('$vuetify.saveSuccessfullyMsg');
            // }
        }
    }
</script>

<style>

</style>