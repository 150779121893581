<template>
    <v-card class="elevation-0">
        <v-card-text>
            <v-tabs
                    v-model="model"
                    centered
            >
                <v-tab
                        :href="`#tab-1`"

                >
                    جلسات
                </v-tab>
                <v-tab
                        :href="`#tab-2`"
                >
                    جلسات در انتظار تایید
                </v-tab>

            </v-tabs>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text>
            <v-tabs-items v-model="model">
                <v-tab-item
                        :value="`tab-1`"
                >
                    <v-card class="elevation-1">
                        <v-card-text v-if="sessions.length">
                            <v-row justify="center">
                                <v-col cols="12" sm="8" md="6" lg="4" v-for="(session,index) in sessions" :key="index">
                                    <SessionCard :session="session"></SessionCard>
                                </v-col>
                            </v-row>

                            <div class="text-center">
                                <v-pagination
                                        v-model="page"
                                        :length="total"
                                ></v-pagination>
                            </div>
                        </v-card-text>
                        <v-card-text class="text-center" v-else>
                            جلسه ای وجود ندارد
                        </v-card-text>
                    </v-card>


                </v-tab-item>
                <v-tab-item
                        :value="`tab-2`"
                >
                    <v-card>
                        <v-card-text class="elevation-1" v-if="awaitingSessions.length">

                            <v-row justify="center">
                                <v-col cols="12" sm="8" md="6" lg="4" v-for="(session,index) in awaitingSessions"
                                       :key="index">
                                    <SessionCard :session="session" @updateSessions="fetAllSessions"></SessionCard>
                                </v-col>
                            </v-row>

                            <div class="text-center">
                                <v-pagination
                                        v-model="awaitingPage"
                                        :length="awaitingTotal"
                                ></v-pagination>
                            </div>
                        </v-card-text>
                        <v-card-text class="text-center" v-else>
                            جلسه ای وجود ندارد
                        </v-card-text>
                    </v-card>

                </v-tab-item>
            </v-tabs-items>
            <!--<ClientSessions :update="update" @updated="update=false"></ClientSessions>-->

        </v-card-text>

        <!--<ClientSessionRequests @updateSessions="updateSessions"></ClientSessionRequests>-->

    </v-card>
</template>


<script>
    // import ClientSessions from '@/components/clientComponents/ClientSessionsComponent';
    // import ClientSessionRequests from '@/components/clientComponents/ClientSessionsRequests';
    import SessionCard from '@/components/sessions/SessionCard';
    import {EventBus} from "../../event-bus";


    export default {
        created() {
            this.getClientSessions();
            this.getAwaitingSessions();
        },
        data() {
            return {
                awaitingSessions: [],
                awaitingTotal: 1,
                awaitingPage: 1,
                page: 1,
                total: 1,
                model: 'tab-1',
                update: false,
                sessions: []
            }
        },
        methods: {
            fetAllSessions() {
                this.getAwaitingSessions();
                this.getClientSessions();
            },
            getAwaitingSessions(loading = false) {
                if (loading) EventBus.$emit('setLoading', true);
                this.$store.dispatch('requestedSessions', {page: this.awaitingPage}).then((data) => {

                    this.awaitingTotal = data.data.pagination.total_pages;
                    this.awaitingSessions = data.data.sessions;
                    // this.total = data.paginate.totalPages
                    this.$emit('updated');
                }).finally(() => {
                    if (loading) EventBus.$emit('setLoading', false);
                });
            },
            updateSessions() {
                this.update = true;
            },
            getClientSessions() {
                // let options = {
                //     state: 'date_fixed|used',
                //     orderBy: "session_date:desc"
                // };
                //
                // if (this.activeCycle != null) {
                //     options.cycle_id = this.activeCycle.id;
                // }
                EventBus.$emit('setLoading', true);
                this.$store.dispatch('getClientAllSessions', {page: this.page}).then((data) => {
                    this.total = data.data.pagination.total_pages;
                    this.sessions = [];
                    this.sessions = data.data.sessions;
                    // this.total = data.paginate.totalPages
                    this.$emit('updated');
                }).finally(() => {
                    EventBus.$emit('setLoading', false);
                });
            },
        },
        components: {
            SessionCard
            // ClientSessions,
            // ClientSessionRequests
        },
        watch: {
            update() {
                this.getClientSessions();
            },
            page() {
                this.getClientSessions();
                this.$vuetify.goTo(this.$refs.sessions.offsetTop);

            },
            awaitingPage() {
                this.getAwaitingSessions();

            }
        }

    }
</script>

<style scoped>

</style>