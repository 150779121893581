<template>
    <div>
        <v-dialog v-model="showAddDialog" max-width="700" scrollable>
            <v-card>
                <v-card-title>افزودن متد پرداختی</v-card-title>
                <v-card-text>
                    <v-form ref="addPaymentForm">

                        <v-row class="justify-center center align-center text-center justify-center"
                               style="position: relative">
                            <v-col cols="12" lg="12" class="text-center">
                                <img

                                        style="object-fit: cover;width:100%;"
                                        :src="newImage"
                                        alt="avatar"
                                >
                            </v-col>
                            <v-col cols="12" sm="6" class="addImg">
                                <input ref="imgInput" class="d-none" type="file" @change="onFileSelected"
                                       accept="image/gif, image/jpeg, image/png">
                                <v-btn small class="mx-2" fab dark color="primary" @click="$refs.imgInput.click()">
                                    <v-icon dark>mdi-plus</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>

                        <v-row align="center" justify="center">
                            <v-col cols="6" sm="3">
                                <v-select label="نوع پرداخت" :items="['online','offline']" :rules="requiredRules"
                                          v-model="newPayment.type"
                                          outlined></v-select>
                            </v-col>
                            <v-col cols="6" sm="6">
                                <v-text-field v-model="newPayment.title" label="عنوان" :rules="requiredRules"
                                              placeholder="مثال: حساب بانکی دلار استرالیا" outlined></v-text-field>
                            </v-col>
                            <v-col cols="6" sm="3">
                                <v-select
                                        v-model="newPayment.currency"
                                        :items="currencies"
                                        label="ارز"
                                        item-text="title"
                                        item-value="value"
                                        outlined
                                        :rules="requiredRules"
                                ></v-select>
                            </v-col>
                            <v-col cols="6" sm="5">
                                <v-text-field v-model="newPayment.code" :rules="requiredRules" label="کد"
                                              placeholder="مثال: USD" outlined></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-textarea v-model="newPayment.description" label="توضیحات" outlined></v-textarea>
                            </v-col>
                        </v-row>

                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="error elevation-0" text @click="showAddDialog = false">
                        بستن
                    </v-btn>
                    <v-btn class="primary elevation-0" text @click="addNewMethod">
                        افزودن
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-container>
            <v-card class="my-8">

                <v-card-title>
                    متد های پرداخت
                    <v-spacer></v-spacer>
                    <v-btn @click="showAddDialog = true" small class="primary" text>
                        افزودن متد جدید
                        <v-icon class="mx-1" small>fas fa-plus</v-icon>
                    </v-btn>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <v-data-table
                            hide-default-footer
                            :headers="headers"
                            :items="paymentMethods"
                            :items-per-page="-1"
                    >
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-container>
    </div>
</template>

<script>

    import {EventBus} from "../../event-bus";
    import {mapGetters} from 'vuex';

    export default {
        data() {
            return {
                newImage: null,
                // currencies: [
                //     {title: 'دلار', value: 'dollar'},
                //     {title: 'تومان', value: 'rial'}
                // ],
                newPayment: {
                    type: '',
                    title: '',
                    code: '',
                    description: '',
                    currency: '',

                },
                showAddDialog: false,
                paymentMethods: [],
                headers: [
                    {text: 'متد پرداختی', value: 'title'},
                    {text: 'نوع', value: 'type'},
                ],

            }
        },
        computed: {
            ...mapGetters({
                currencies:'getCurrencies'
            }),
            // imgUrl() {
            //     if (!this.userInfo.hasOwnProperty('avatar') && this.newImage == '') {
            //         return require('./../../../assets/avatar-default.png');
            //     } else if (this.newImage) {
            //         return this.newImage;
            //     } else {
            //         return this.userInfo.avatar;
            //     }
            // },
            requiredRules() {
                return [v => !!v || this.$vuetify.lang.t('$vuetify.fillIsRequired')];
            },
        },
        created() {
            this.getAllPaymentMethods();
        },
        methods: {
            onFileSelected(e) {
                let files = e.target.files || e.dataTransfer.files;
                if (!files.length) return;
                this.createImage(files[0]).then(resp => {
                    this.newImage = resp;
                    this.userInfo.avatar = resp;
                });
            },
            createImage(file) {
                return new Promise((resolve) => {
                    let reader = new FileReader();
                    reader.onload = e => {
                        resolve(e.target.result);
                    };
                    reader.readAsDataURL(file);
                });
            },
            addNewMethod() {

                if (this.$refs.addPaymentForm.validate()) {
                    let data = this.newPayment;
                    if (this.newImage != null) data.image = this.newImage;
                    this.$store.dispatch('adminAddNewPaymentMethod', data).then(() => {
                        this.getAllPaymentMethods();
                        this.showAddDialog = false;
                        EventBus.$emit('notify', 'green', undefined, 'با موفقیت به ثبت رسید');
                    });
                }
            },


            getAllPaymentMethods() {
                this.paymentMethodsLoading = true;
                EventBus.$emit('setLoading', true);
                this.$store.dispatch('getAllPaymentMethods').then((resp) => {
                    this.paymentMethods = resp.data;

                }).finally(() => {
                    this.paymentMethodsLoading = false;
                    EventBus.$emit('setLoading', false);
                })
            }
        }
    }
</script>

<style scoped>

</style>
