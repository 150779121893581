<template>

    <v-btn
            class="blue darken-3"
            fab
            x-small
            text
            @click="getLinks"
            v-if="item.state!='used'"
    >
        <v-icon class="white--text">mdi-link</v-icon>
        <template v-if="!loading">
            <v-dialog width="600" v-model="showLinksDialog">
                <v-card>
                    <v-card-title>لینک ورود به جلسه</v-card-title>
                    <v-card-text>
                        <v-text-field outlined v-model="links.patient" label="مراجعه کننده">
                            <template v-slot:append-outer>
                                <ShareNetwork
                                        v-for="(network,index) in networks"
                                        :key="index"
                                        :network="network.title"
                                        title="لینک ورود به جلسه"
                                        :url="links.patient"
                                >
                                    <v-btn fab class="px-0 ma-1 " text :class="network.color" x-small>
                                        <v-icon class="ma-0 pa-0 elevation-0">{{network.icon}}</v-icon>
                                    </v-btn>
                                </ShareNetwork>
                            </template>
                        </v-text-field>

                        <v-text-field outlined v-model="links.doctor" label="مشاور">
                            <template v-slot:append-outer>
                                <ShareNetwork
                                        v-for="(network,index) in networks"
                                        :key="index"
                                        :network="network.title"
                                        title="لینک ورود به جلسه:"
                                        :url="links.doctor"
                                >
                                    <v-btn fab class="px-0 ma-0 mx-1" text :class="network.color" x-small>
                                        <v-icon class="ma-0 pa-0 elevation-0">{{network.icon}}</v-icon>
                                    </v-btn>
                                </ShareNetwork>
                            </template>
                        </v-text-field>
                    </v-card-text>

                </v-card>
            </v-dialog>
        </template>
    </v-btn>


</template>

<script>
    export default {
        props: ['item'],
        data() {
            return {
                networks: [{
                    title: 'telegram',
                    text: '',
                    icon: 'mdi-telegram',
                    url: '',
                    description: 'لبت خندون، دلت آروم\n' +
                        'سیمیاروم؛ یه تجربه خوب از مشاوره آنلاین روانشناسیه، با حرفه‌ای‌ترین روان‌شناس‌ها و روان‌کاوهای فارسی زبان.\n' +
                        'حالِ من خیلی بهتره، تو هم حال دلتو خوب کن',
                    quote: 'quote',
                    hashtags: 'hashtags',
                    color: 'blue white--text darken-1'
                },
                    {
                        title: 'whatsapp',
                        text: '',
                        icon: 'mdi-whatsapp',
                        url: '',
                        description: 'لبت خندون، دلت آروم\n' +
                            'سیمیاروم؛ یه تجربه خوب از مشاوره آنلاین روانشناسیه، با حرفه‌ای‌ترین روان‌شناس‌ها و روان‌کاوهای فارسی زبان.\n' +
                            'حالِ من خیلی بهتره، تو هم حال دلتو خوب کن',
                        quote: 'quote',
                        hashtags: 'hashtags',
                        color: 'green white--text'
                    },],
                loading: false,
                received: false,
                links: {
                    patient: null,
                    doctor: null
                },
                showLinksDialog: false
            }
        },
        methods: {
            getLinks() {
                if (!this.received) {
                    this.loading = true;
                    this.$store.dispatch('getAdminSessionLinks', {id: this.item.id}).then((resp) => {
                        this.links.patient = resp.data.user_session_link;
                        this.links.doctor = resp.data.doctor_session_link;
                        this.received = true;
                        this.showLinksDialog = true;
                    }).finally(() => {
                        this.loading = false;
                    })
                } else {
                    this.showLinksDialog = true;
                }
            },
            telegramLink() {
                alert('teleg');
            },
            whatsapplink() {
                alert('whatsapp')
            },
        }
    }
</script>

<style scoped>

</style>