import constants from './constants/constants'

let adminTicketFilters = {
    stateFilters: {
        title: 'وضعیت',
        key: 'state',
        type: 'singleselect',
        options: [
            {title: 'رسیدگی نشده', value: 'pending'},
            {title: 'دیده شده', value: 'seen'},
            {title: 'رسیدگی شده', value: 'resolved'},
        ]
    },
    typeFilters: {
        title: 'نوع',
        key: 'type',
        type: 'singleselect',
        options: [
            {title: 'درخواست', value: ''},
        ]
    },
}

let couponFilters = {
    expiredFilter: {
        title: 'انقضا',
        key: 'expired',
        type: 'singleselect',
        default: true,
        options: [
            {title: 'منقضی نشده', value: "notExpired", default: true},
            {title: 'منقضی شده', value: "expired"}
        ]
    },
    stateFilter: {
        title: 'وضعیت',
        key: 'state',
        type: 'singleselect',
        default: true,
        options: [
            {title: 'فعال', value: 'available', default: true},
            {title: 'غیر فعال', value: 'used'}
        ]
    },
    amountFilter: {
        title: 'مبلغ',
        key: 'amount',
        type: 'text',
        options: ['']
    }
};


let packageFilters = {
    quantityFilter: {
        title: 'تعداد جلسه',
        key: 'session_count',
        type: 'text',
        options: ['']
    },
    titleFilter: {
        title: 'عنوان',
        key: 'title',
        type: 'text',
        options: ['']
    },
    currencyFilter: {
        title: 'ارز',
        key: 'payment_unit',
        type: 'singleselect',
        options: [
            {title: 'تومان', value: 'rial'},
            {title: 'دلار', value: 'dollar'},
        ]
    },
    stateFilter: {
        title: 'وضعیت',
        key: 'state',
        type: 'singleselect',
        default: true,
        options: [
            {title: 'فعال', value: 'active', default: true},
            {title: 'غیر فعال', value: 'inactive'}
        ]
    },
    amountFilter: {
        title: 'مبلغ',
        key: 'amount',
        type: 'text',
        options: ['']
    }
};


let financialFilters = {

    stateFilters: {
        title: 'وضعیت',
        key: 'state',
        type: 'singleselect',
        default: true,
        options: [
            {title: 'نامشخص', value: 'unknown'},
            {title: 'پرداخت شده', value: 'paid', default: true},
            {title: 'شکست خورده', value: 'failed'},
            {title: 'ریفاند', value: 'refund'},
        ]
    },
    cartStateFilters: {
        title: 'وضعیت',
        key: 'state',
        type: 'multiselect',
        default: true,
        options: [
            {title: 'پرداخت شده', value: 'paid', default: true},
            {title: 'پیش پرداخت', value: 'partially_paid', default: true},
            // {title: 'پیش فاکتور', value: 'submit'},
        ]
    },
    currencyFilter: {
        title: 'ارز',
        key: 'currency',
        type: 'singleselect',
        options: [
            {title: 'تومان', value: 'rial'},
            {title: 'دلار', value: 'dollar'},
            {title: 'یورو', value: 'euro'},
            {title: 'دلار استرالیا', value: 'aud'},
            {title: 'دلار کانادا', value: 'cad'},
            {title: 'پوند', value: 'gbp'},
        ]
    },

    referenceFilters: {
        title: 'شماره پیگیری',
        key: 'ref_id',
        type: 'text',
        options: ['']
    },
    cartPaymentSortFilters: {
        title: 'مرتب سازی بر اساس',
        key: 'orderBy',
        type: 'singleselect',
        default: true,
        options: [
            {title: 'تاریخ پرداخت صعودی', value: 'asc'},
            {title: 'تاریخ پرداخت نزولی', value: 'desc', default: true}
        ]
    },
    paymentSortFilters: {
        title: 'مرتب سازی بر اساس',
        key: 'orderBy',
        type: 'singleselect',
        default: true,
        options: [
            {title: 'مبلغ صعودی', value: 'amount:asc'},
            {title: 'مبلغ نزولی', value: 'amount:desc',},
            {title: 'تاریخ پرداخت صعودی', value: 'payment_date:asc'},
            {title: 'تاریخ پرداخت نزولی', value: 'payment_date:desc', default: true}
        ]
    },

    cartSortFilters: {
        title: 'مرتب سازی بر اساس',
        key: 'orderBy',
        type: 'singleselect',
        default: true,
        options: [
            {title: 'مبلغ صعودی', value: 'amount:asc'},
            {title: 'مبلغ نزولی', value: 'amount:desc',},
            {title: 'تاریخ پرداخت صعودی', value: 'date:asc'},
            {title: 'تاریخ پرداخت نزولی', value: 'date:desc', default: true}
        ]
    },
};

// 'order' ,
// 'from_date' ,
// 'to_date' ,
// 'from_amount' ,
// 'to_amount' ,
// 'currency' ,


let filters = {
    general: {
        patientNameFilter: {
            title: 'نام مراجع',
            key: 'patient_name',
            type: 'text',
            expandable: false,
            options: ['']
        },
        patientMobileFilter: {
            title: 'تلفن همراه',
            key: 'mobile',
            type: 'text',
            expandable: false,
            options: ['']
        },
        patientEmailFilter: {
            title: 'ایمیل',
            key: 'email',
            type: 'text',
            expandable: false,
            options: ['']
        },
        patientFamiliarityFilter: {
            title: 'آشنایی',
            key: 'familiar_by',
            type: 'singleselect',
            default: false,
            options: [
                {value: "google", title: "جست و جو در  گوگل"},
                {value: "twitter", title: "توییتر"},
                {value: "instagram", title: "اینستاگرام"},
                {value: "podcast", title: "پادکست"},
                {value: "bannerads", title: "تبلیغات بنری"},
                {value: "friends", title: "معرفی دوستان و آشنایان"},
                {value: "site", title: "سایت"},
                {value: "other", title: "موارد دیگر"},
            ]
        },
        // patientPaymentExistence: {
        //     title: 'وضعیت خرید',
        //     key: 'patient_payment_existence',
        //     type: 'singleselect',
        //     default: false,
        //     options: [
        //         {title: 'بدون خرید', value: 'no_payments'},
        //         {title: 'با خرید', value: 'has_payments'},
        //     ]
        // },
        patientOriginType: {
            title: 'نوع مشتری',
            key: 'user_origin_type',
            type: 'singleselect',
            default: false,
            options: [
                {title: 'غیر ریالی', value: 'dollar'},
                {title: 'ریالی', value: 'rial'},
            ]
        },
        patientPaymentType: {
            title: 'نوع پرداخت',
            key: 'patient_payment_type',
            type: 'singleselect',
            default: false,
            options: [
                // {title: 'دلاری', value: 'dollar'},
                // {title: 'ریالی', value: 'rial'},
                {title: 'تومان', value: 'rial'},
                {title: 'دلار', value: 'dollar',},
                {title: 'یورو', value: 'euro',},
                {title: 'پوند', value: 'gbp',},
                {title: 'دلار کانادا', value: 'cad',},
                {title: 'دلار استرالیا', value: 'aud',},
                {title: 'دلاری و ریالی', value: 'both_currencies'},
                {title: 'با پرداخت', value: 'has_payment'},
                {title: 'بدون پرداخت', value: 'no_payment'},
            ]
        },

        counselorFilters: {
            title: 'نام مشاور',
            key: 'doctor_name',
            type: 'text',
            options: ['']
        },

        counselorIdFilters: {
            title: 'مشاور',
            key: 'doctor_ids',
            type: 'multiselect',
            options: []
        },
        sessionSortFilters: {
            title: 'مرتب سازی بر اساس',
            key: 'orderBy',
            type: 'singleselect',
            default: true,
            options: [
                {title: 'تاریخ جلسه صعودی', value: 'session_date:asc'},
                {title: 'تاریخ جلسه نزولی', value: 'session_date:desc'},
                {title: 'تاریخ درخواست صعودی', value: 'request_date:asc'},
                {title: 'تاریخ درخواست نزولی', value: 'request_date:desc', default: true}
            ]
        },
        adminClientSessionSortFilters: {
            title: 'مرتب سازی بر اساس',
            key: 'orderBy',
            type: 'singleselect',
            default: true,
            options: [
                {title: 'تاریخ جلسه صعودی', value: 'session_date:asc'},
                {title: 'تاریخ جلسه نزولی', value: 'session_date:desc', default: true},
                {title: 'تاریخ درخواست صعودی', value: 'request_date:asc'},
                {title: 'تاریخ درخواست نزولی', value: 'request_date:desc'}
            ]
        },
        stateFilters: {
            title: 'وضعیت',
            key: 'state',
            type: 'multiselect',
            default: true,
            options: [
                {title: 'درخواست', value: 'session_requested'},
                {title: 'تخصیص یافته', value: 'doctor_assigned'},
                {title: 'تاریخ ست شده', value: 'date_fixed'},
                {title: 'برگزار شده', value: 'used'}
            ]
        },

        typeFilters: {
            title: 'نوع',
            key: 'type',
            type: 'multiselect',
            default: true,
            options: constants.withDefault(constants.packages.types , 'session')
        },

        clientSessionStateFilters: {
            title: 'وضعیت',
            key: 'state',
            type: 'multiselect',
            default: true,
            options: [
                {title: 'درخواست', value: 'session_requested', default: true},
                {title: 'تخصیص یافته', value: 'doctor_assigned', default: true},
                {title: 'تاریخ ست شده', value: 'date_fixed', default: true},
                {title: 'برگزار شده', value: 'used', default: true}
            ]
        },
        clientSessionRequestStateFilters: {
            title: 'وضعیت',
            key: 'state',
            type: 'multiselect',
            default: true,
            options: [
                {title: 'درخواست', value: 'session_requested', default: true},
                {title: 'تخصیص یافته', value: 'doctor_assigned'},
                {title: 'تاریخ ست شده', value: 'date_fixed'},
                {title: 'برگزار شده', value: 'used'}
            ]
        },
        couponCodeFilter: {
            title: 'کد تخفیف',
            key: 'code',
            type: 'text',
            expandable: false,
            options: ['']
        }
    },
    sessionDateFilters: [
        {
            title: 'از تاریخ',
            key: 'from_date',
            type: 'datepicker',
            options: ['']
        },
        {
            title: 'تا تاریخ',
            key: 'to_date',
            type: 'datepicker',
            options: ['']
        }
    ],
    cartPaymentDateFilters: [
        {
            title: 'از تاریخ پرداخت',
            key: 'payment_from_date',
            type: 'datepicker',
            options: ['']
        },
        {
            title: 'تا تاریخ پرداخت',
            key: 'payment_to_date',
            type: 'datepicker',
            options: ['']
        }
    ],
    userJoinDateFilters: [
        {
            title: 'از تاریخ',
            key: 'from_date',
            type: 'datepicker',
            options: ['']
        },
        {
            title: 'تا تاریخ',
            key: 'to_date',
            type: 'datepicker',
            options: ['']
        }
    ],
    paymentAmountFilters: [
        {
            title: 'از مبلغ',
            key: 'from_amount',
            type: 'text',
            options: ['']
        },
        {
            title: 'تا مبلغ',
            key: 'to_amount',
            type: 'text',
            options: ['']
        }
    ],
    requestDateFilters: [
        {
            title: 'درخواست از',
            key: 'from_request_date',
            type: 'datepicker',
            options: ['']
        },
        {
            title: 'درخواست تا',
            key: 'to_request_date',
            type: 'datepicker',
            options: ['']
        }
    ]
}


const adminSessionFilters = [
    filters.general.stateFilters,
    filters.general.patientNameFilter,
    filters.general.typeFilters,
    // filters.general.counselorFilters,
    filters.general.counselorIdFilters,
    filters.general.sessionSortFilters,
    ...filters['sessionDateFilters'],
    ...filters['requestDateFilters']
];

const adminFinancialFilters = [
    financialFilters.stateFilters,
    financialFilters.paymentSortFilters,
    financialFilters.currencyFilter,
    filters.general.patientNameFilter,
    financialFilters.referenceFilters,
    ...filters['sessionDateFilters'],
    ...filters['paymentAmountFilters']

]

const adminCartFactorsFilters = [
    financialFilters.cartStateFilters,
    financialFilters.cartPaymentSortFilters,
    financialFilters.currencyFilter,
    filters.general.patientNameFilter,
    filters.general.patientEmailFilter,
    financialFilters.referenceFilters,
    ...filters['sessionDateFilters'],
    ...filters['paymentAmountFilters'],
    ...filters['cartPaymentDateFilters'],

]


const adminFinancialTransactionsFilters = [
    financialFilters.paymentSortFilters,
    financialFilters.currencyFilter,
    filters.general.patientNameFilter,
    financialFilters.referenceFilters,
    ...filters['sessionDateFilters'],
    ...filters['paymentAmountFilters']

]


const adminFinancialRefundsFilters = [
    financialFilters.paymentSortFilters,
    financialFilters.currencyFilter,
    filters.general.patientNameFilter,
    financialFilters.referenceFilters,
    ...filters['sessionDateFilters'],
    ...filters['paymentAmountFilters']
]


const adminFinancialRecordsFilters = [
    financialFilters.paymentSortFilters,
    financialFilters.stateFilters,
    financialFilters.currencyFilter,
    filters.general.patientNameFilter,
    financialFilters.referenceFilters,
    ...filters['sessionDateFilters'],
    ...filters['paymentAmountFilters']

]


let dateSessionFilters = [
    filters.general.stateFilters,
    filters.general.patientNameFilter,
    filters.general.counselorFilters,
    filters.general.sessionSortFilters,
    ...filters['requestDateFilters']
];

let clientDetailSessionsFilters = [
    filters.general.stateFilters,
    filters.general.counselorFilters,
    filters.general.sessionSortFilters,
    ...filters['sessionDateFilters'],
    ...filters['requestDateFilters']
];


let adminSessionRequests = [
    filters.general.sessionSortFilters,
    filters.general.counselorIdFilters,
    filters.general.patientNameFilter,
    filters.general.typeFilters,
    // filters.general.counselorFilters,

    ...filters['requestDateFilters']
];

let adminClients = [
    filters.general.patientNameFilter,
    filters.general.patientMobileFilter,
    filters.general.patientEmailFilter,
    filters.general.patientFamiliarityFilter,
    filters.general.patientOriginType,
    filters.general.patientPaymentType,
    // filters.general.patientPaymentExistence,
    ...filters['userJoinDateFilters'],

];

let adminCounselors = [
    filters.general.patientNameFilter,
];


let adminTickets = [
    adminTicketFilters.stateFilters,
    adminTicketFilters.typeFilters,
    filters.general.patientNameFilter
]
let adminCouponFilters = [
    filters.general.patientNameFilter,
    filters.general.couponCodeFilter,
    filters.general.counselorFilters,
    couponFilters.stateFilter,
    couponFilters.amountFilter,
    couponFilters.expiredFilter,
];

let adminGiftCodeFilters=[];

let adminPackageFilters = [
    filters.general.counselorFilters,
    filters.general.typeFilters,
    // packageFilters.amountFilter,
    packageFilters.stateFilter,
    // packageFilters.currencyFilter,
    packageFilters.quantityFilter,
    packageFilters.titleFilter,
];

let adminClientUsedSessionFilters = [
    filters.general.clientSessionStateFilters,
    filters.general.typeFilters,
    filters.general.counselorFilters,
    filters.general.adminClientSessionSortFilters,
    ...filters['sessionDateFilters'],
    ...filters['requestDateFilters']
]


let adminClientRequestedSessionFilters = [
    filters.general.clientSessionRequestStateFilters,
    filters.general.typeFilters,
    filters.general.counselorFilters,
    filters.general.sessionSortFilters,
    ...filters['sessionDateFilters'],
    ...filters['requestDateFilters']
]


export {
    adminSessionFilters,
    dateSessionFilters,
    clientDetailSessionsFilters,
    adminFinancialFilters,
    adminSessionRequests,
    adminClients,
    adminTickets,
    adminCounselors,
    adminCouponFilters,
    adminPackageFilters,
    adminFinancialTransactionsFilters,
    adminFinancialRecordsFilters,
    adminFinancialRefundsFilters,
    adminClientUsedSessionFilters,
    adminClientRequestedSessionFilters,
    adminCartFactorsFilters,
    adminGiftCodeFilters

};