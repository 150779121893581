<template>
    <v-container>
        <clientDashboard></clientDashboard>
        <v-dialog
                v-model="addCycle"
                max-width="700"
        >
            <AddNewCycle @cycleAdded="getCycles" :dialog="true" @close="addCycle=false"></AddNewCycle>
        </v-dialog>
        <v-row>
            <v-col cols="6" sm="6" md="3" v-for="(heading,i) in headings" :key="i">
                <v-card
                        class="mx-auto"
                        color="#26c6da"
                        dark
                        max-width="400"
                >
                    <v-card-title>
                        <v-icon large class="mx-1">

                            {{heading.icon}}
                        </v-icon>
                        <span class="title font-weight-light">
                            <span>{{heading.text}}</span>
                        </span>
                    </v-card-title>

                    <v-card-text class="headline font-weight-bold">
                        {{heading.value}}
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row justify="center" align="center" class="mt-5">
            <v-col cols="12" sm="4" md="4">
                <v-btn block height="80px" class="error">
                    <span class="title font-weight-bold">
                        <span @click="openSessionRequestDialog">درخواست جلسه</span>
                    </span>
                </v-btn>
            </v-col>
            <v-col cols="12" sm="4" md="4">
                <v-btn block height="80px" class="info" @click="addCycle=true">
                    <span class="title font-weight-bold">
                        <span>افزودن دوره درمان</span>
                    </span>

                </v-btn>
            </v-col>
        </v-row>
        <v-row justify="center" align="center">

            <v-col cols="12" sm="8" md="5" lg="4"
                   v-for="(session,index) in $store.getters.getUserInfo.four_future_sessions"
                   :key="index">
                <SessionCard :session="session"></SessionCard>
            </v-col>
        </v-row>
        <!--<v-row >-->
        <!--<v-col class="text-center">-->
        <!--<v-btn class="info">ادامه</v-btn>-->
        <!--</v-col>-->
        <!--</v-row>-->
        <v-row justify="center" dense>
            <v-col cols="6" sm="4" class="ma-sm-1">

                <div class="app-links elevation-1 grey lighten-3 text-center">
                    <a
                            href="https://play.google.com/store/apps/details?id=com.mars.platroom"
                            target="_blank"
                            style="text-decoration: none"
                    >
                        <v-icon class=" green--text px-2">fab fa-android</v-icon>
                        <span style="text-decoration: none;white-space: normal;" class="mt-1">دانلود اپلیکیشن ارتباط تصویری اندروید</span>
                    </a>
                </div>
            </v-col>
            <v-col cols="6" sm="4" class="ma-sm-1">
                <div class="app-links elevation-1 grey lighten-3 text-center">
                    <a
                            href="https://apps.apple.com/us/app/platroom/id1516231951"
                            target="_blank"
                            style="text-decoration: none"
                    >
                        <v-icon class="px-2">fab fa-apple</v-icon>
                        <span style="text-decoration: none;white-space: normal;" class="mt-1">دانلود اپلیکیشن ارتباط تصویری IOS</span>
                    </a>
                </div>
            </v-col>
        </v-row>
        <v-row justify="center" dense>
            <v-col cols="6" sm="4" class="ma-sm-1">
                <div class="app-links elevation-1 grey lighten-3 text-center">
                    <a
                            href="http://app.simiaroom.com/apps/win/PlatRoom Setup 1.1.2.exe"
                            target="_blank"
                            style="text-decoration: none"
                    >
                        <v-icon class="px-2 blue--text">fab fa-windows</v-icon>
                        <span style="text-decoration: none" class="mt-1">دانلود اپلیکیشن ارتباط تصویری ویندوز</span>
                    </a>
                </div>
            </v-col>
            <v-col cols="6" sm="4" class="ma-sm-1">
                <div class="app-links elevation-1 grey lighten-3 text-center">
                    <a
                            href="http://app.simiaroom.com/apps/mac/PlatRoom-1.1.2.dmg"
                            target="_blank"
                            style="text-decoration: none"
                    >
                        <v-icon class="px-2">fab fa-apple</v-icon>
                        <span style="text-decoration: none" class="mt-1">دانلود اپلیکیشن ارتباط تصویری مک</span>
                    </a>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import {mapGetters} from 'vuex';
    import SessionCard from '@/components/sessions/SessionCard'
    import {EventBus} from "../../event-bus";
    import AddNewCycle from '@/components/clients/AddNewCycle';
    import clientDashboard from '@/components/dashboards/clientDashboard.vue';

    export default {
        components: {SessionCard, AddNewCycle, clientDashboard},
        computed: {
            ...mapGetters({
                profile: 'getUserInfo',
                newMessageCount:'getNewMessageCount'
            })
        },
        methods: {
            getCycles() {
                this.$store.dispatch('getUserInfo');
            },
            openSessionRequestDialog() {
                EventBus.$emit('showAddSessionDialog');
            },
        },
        data() {
            return {
                addCycle: false,

                headings: [
                    {
                        text: 'دوره های درمان',
                        icon: 'mdi-heart-box',
                        value: this.$store.getters.getUserInfo.cycles.length
                    },
                    {
                        text: 'جلسات در انتظار تایید',
                        icon: 'mdi-monitor',
                        value: this.$store.getters.getUserInfo.future_session_count
                    },
                    {text: 'پیام ها', icon: 'mdi-message', value: this.$store.getters.getNewMessageCount},
                    {text: 'بسته ها', icon: 'fas fa-heartbeat', value: this.$store.getters.getUserInfo.wallet.total},
                ]
            }
        },

    }
</script>

<style scoped>
    .app-links {
        width: 100%;
        min-height: 50px;
        border-radius: 5px;
        line-height: 50px;

    }
</style>

