<template>
    <v-container>
        <v-card class="elevation-0">
            <v-card-title>
                {{title}}
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                <v-form ref="addCoupon">
                    <v-row>
                        <v-col cols="12" class="text-center" dense>
                            <v-chip
                                    @click="coupon.is_cart_coupon = false"
                                    class="mb-2 smooth-transition"
                                    style="display: inline-block"
                                    :class="{'primary white--text':!coupon.is_cart_coupon,'grey--text':coupon.is_cart_coupon}">
                                روی محصول
                            </v-chip>
                            <div style="display: inline-block">
                                <v-switch
                                        inset
                                        dense
                                        class="ma-3 mx-1 "
                                        v-model="coupon.is_cart_coupon"
                                        hide-details
                                        color="primary"
                                >
                                </v-switch>
                            </div>
                            <v-chip
                                    @click="coupon.is_cart_coupon = true" class="mb-2 smooth-transition"
                                    style="display: inline-block"
                                    :class="{'primary white--text':coupon.is_cart_coupon,'grey--text':!coupon.is_cart_coupon}">
                                روی سبد خرید
                            </v-chip>
                        </v-col>
                        <v-col cols="12" md="4" lg="3" sm="6">
                            <v-text-field
                                    v-model="coupon.max_usage_count"
                                    outlined label="دفعات قابل استفاده"
                            >

                            </v-text-field>
                        </v-col>
                        <v-col cols="12" md="4" lg="3" sm="6">
                            <v-text-field
                                    v-model="coupon.max_usage_count_per_user"
                                    outlined label="دفعات قابل استفاده هر کاربر"

                            >

                            </v-text-field>
                        </v-col>
                        <v-col cols="12" md="4" lg="3" sm="6">
                            <v-select
                                    v-model="coupon.discount_type"
                                    :items="types"
                                    label="نوع تخفیف"
                                    item-text="title"
                                    item-value="value"
                                    outlined
                                    :rules="requiredRules"
                            ></v-select>
                        </v-col>
                        <v-col cols="12" md="4" lg="3" sm="6">
                            <v-text-field
                                    v-model="coupon.irr_discount_amount"
                                    :rules="requiredRules"
                                    outlined label="مبلغ تومان"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4" lg="3" sm="6">
                            <v-text-field
                                    :rules="requiredRules"
                                    v-model="coupon.usd_discount_amount"
                                    outlined label="مبلغ دلاری"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4" lg="3" sm="6">
                            <v-text-field
                                    :rules="requiredRules"
                                    v-model="coupon.code"
                                    outlined label="کد"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4" lg="3" sm="6">
                            <v-autocomplete

                                    v-model="coupon.doctor_id"
                                    :items="doctors"
                                    :loading="isLoading"
                                    hide-no-data
                                    hide-selected
                                    item-text="full_name"
                                    item-value="doctor_profile.id"
                                    outlined
                                    label="دکتر"
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="4" lg="3" sm="6">
                            <v-autocomplete
                                    v-model="coupon.user_id"
                                    :items="clients"
                                    :loading="clientsLoading"
                                    hide-no-data
                                    hide-selected
                                    item-text="full_name"
                                    item-value="id"
                                    outlined
                                    label="کاربر"
                            ></v-autocomplete>
                        </v-col>
                        <!--<v-col cols="12" md="4" lg="3" sm="6">-->

                        <!--<v-autocomplete-->
                        <!--v-model="coupon.package_id"-->
                        <!--:items="packages"-->
                        <!--:loading="packagesLoading"-->
                        <!--hide-no-data-->
                        <!--hide-selected-->
                        <!--item-text="title"-->
                        <!--item-value="id"-->
                        <!--outlined-->
                        <!--label="بسته"-->
                        <!--&gt;</v-autocomplete>-->
                        <!--</v-col>-->
                        <v-col cols="12" md="4" lg="3" sm="6">
                            <!--<v-dialog-->
                            <!--ref="dialog"-->
                            <!--v-model="modal"-->
                            <!--:return-value.sync="coupon.exp_date"-->
                            <!--persistent-->
                            <!--width="290px"-->
                            <!--&gt;-->
                            <!--<template v-slot:activator="{ on, attrs }">-->
                            <v-text-field
                                    outlined
                                    v-model="coupon.exp_date"
                                    label="تاریخ انقضا"
                                    @click="modal=true"
                                    prepend-icon="event"
                            ></v-text-field>
                            <!--</template>-->
                            <AdminDatePicker @close="modal=false" :dialog="modal"
                                             v-model="coupon.exp_date"></AdminDatePicker>

                            <!--<v-date-picker v-model="coupon.exp_date" scrollable>-->
                            <!--<v-spacer></v-spacer>-->
                            <!--<v-btn text color="primary" @click="modal = false">بستن</v-btn>-->
                            <!--<v-btn text color="primary" @click="$refs.dialog.save(coupon.exp_date)">تایید-->
                            <!--</v-btn>-->
                            <!--</v-date-picker>-->
                            <!--</v-dialog>-->
                        </v-col>


                    </v-row>
                    <v-row>


                        <v-col cols="12" md="4" lg="3" sm="6">
                            <v-select
                                    multiple
                                    :items="allConditions"
                                    v-model="coupon.conditions"
                                    label="شروط"
                                    outlined
                                    item-text="title"
                                    item-value="type"
                            >

                            </v-select>
                        </v-col>

                        <v-col v-if="coupon.conditions.includes('limitedpackages')" cols="auto" md="4" lg="9" sm="6">
                            <v-autocomplete
                                    clearable
                                    chips
                                    deletable-chips
                                    v-model="coupon.packages"
                                    :items="packages"
                                    :loading="packagesLoading"
                                    hide-no-data
                                    multiple
                                    item-text="title"
                                    item-value="id"
                                    outlined
                                    label="بسته"
                                    :rules="requiredArray"
                            ></v-autocomplete>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn v-if="closable" class="red white--text" @click="$emit('close')">
                    بستن
                </v-btn>
                <v-btn class="primary" @click="addCoupon">
                    {{buttonText}}
                </v-btn>
            </v-card-actions>
        </v-card>
        <v-row>
            <v-col>
                <AlertsComponent></AlertsComponent>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import AlertsComponent from "@/components/AlertsComponent";
    import AdminDatePicker from '@/components/AdminDatePicker';

    export default {
        props: {
            title: {
                default: 'افزودن کد تخفیف جدید'
            },
            buttonText: {
                default: 'افزودن'
            },
            sending: {
                default: false
            },
            closable: {
                default: false
            },
            couponToEdit: {
                default: function () {
                    return {
                        is_cart_coupon: false,
                        doctor_id: null,
                        user_id: null,
                        package_id: null,
                        max_usage_count: null,
                        max_usage_count_per_user: 1,
                        exp_date: null,
                        discount_type: null,
                        usd_discount_amount: null,
                        irr_discount_amount: null,
                        code: null,
                        conditions: [],
                        packages: []
                    }
                }
            }
        },
        components: {
            AlertsComponent,
            AdminDatePicker
        },
        data() {
            return {
                allConditions: [
                    {
                        type: 'nopayments',
                        title: 'خرید اولی',
                    },
                    {
                        type: 'limitedpackages',
                        title: 'بسته های مشخص'
                    }
                ],
                packagesLoading: false,
                packages: [],
                clientsLoading: false,
                clients: [],
                isLoading: false,
                doctors: [],
                modal: false,
                types: [
                    {title: 'درصدی', value: 'percentage'},
                    {title: 'ثابت', value: 'fixed'}
                ],
                coupon: {
                    is_cart_coupon: false,
                    doctor_id: null,
                    user_id: null,
                    package_id: null,
                    max_usage_count: null,
                    max_usage_count_per_user: 1,
                    exp_date: null,
                    discount_type: null,
                    usd_discount_amount: null,
                    irr_discount_amount: null,
                    code: null,
                    conditions: [],
                    packages: []

                }
            }
        },
        created() {

            this.coupon = Object.assign({}, this.couponToEdit);

            console.log(this.coupon);
            this.getDoctors();
            this.getClients();
            this.getPackages();


        },
        mounted() {

        },
        computed: {
            requiredRules() {
                return [v => !!v || this.$vuetify.lang.t('$vuetify.fillIsRequired')];
            },
            requiredArray() {
                return [v => !!v.length || this.$vuetify.lang.t('$vuetify.fillIsRequired')];
            },
        },
        methods: {
            getPackages() {
                this.packagesLoading = true;
                this.$store.dispatch('getAllPackages').then((resp) => {
                    this.packages = resp.data;
                }).finally(() => {
                    this.packagesLoading = false;
                })
            },
            getClients() {
                this.clientsLoading = true;
                this.$store.dispatch('getAllPatients').then((resp) => {
                    this.clients = resp.data.clients;
                }).finally(() => {
                    this.clientsLoading = false;
                })
            },
            getDoctors() {
                this.isLoading = true;
                this.$store
                    .dispatch("getAllDoctors")
                    .then(res => {
                        this.doctors = res.data.doctors;
                        this.isLoading = false;
                    })
                    .catch(err => {
                        console.log(err);
                    }).finally(() => {

                });
            },
            addCoupon() {
                if (this.$refs.addCoupon.validate()) {
                    this.$emit('proceed', this.coupon);
                }

            }
        },
        watch: {}
    }
</script>

<style>

    .smooth-transition {
        -webkit-transition: all .3s ease-in-out;
        -moz-transition: all .3s ease-in-out;
        -ms-transition: all .3s ease-in-out;
        -o-transition: all .3s ease-in-out;
        transition: all .3s ease-in-out;
    }
</style>