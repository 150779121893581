<template>
    <v-row justify="center" class="" dense>
        <v-col v-for="(day,index) in doctorTimes" :key="index" cols="auto">
            <v-card>
                <v-card-text class="pa-1 ma-0 text-center font-weight-bold">
                    {{index}}
                    <v-card-subtitle class="pa-1">{{index|toJalali}}</v-card-subtitle>
                </v-card-text>


                <v-divider></v-divider>

                <v-card-text>
                    <template v-for="(hours,index) in day">
                        <v-row :key="index" align="center" justify="center">
                            <v-chip v-for="hour in hours" :key="hour.id" class="ma-1 "
                                    :class="{'primary':selectedSessionTimes.includes(hour.id)}"
                                    @click="selectTime(hour)">
                                {{getTimeOptionDate(hour.start)}}
                            </v-chip>
                        </v-row>

                    </template>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>

    import moment from 'moment-jalaali';
    import {EventBus} from "../../event-bus";

    export default {
        props: {
            doctorTimes: {
                default: []
            },
            maxSelectSessionTime: {
                default: 1
            }
        },
        data() {
            return {
                selectedTime: null,
                selectedSessionTimes: []
            }
        }
        ,
        methods: {
            selectTime(hour) {

                if (this.selectedSessionTimes.includes(hour.id)) {
                    this.selectedSessionTimes.splice(this.selectedSessionTimes.indexOf(hour.id), 1);
                } else {
                    if (this.selectedSessionTimes.length < this.maxSelectSessionTime) {
                        this.selectedSessionTimes.push(hour.id)
                    } else {
                        EventBus.$emit('notify', 'red', undefined, `شما نمیتوانید بیشتر از${this.maxSelectSessionTime} ساعت انتخاب کنید`);
                    }
                }
                this.$emit('timeSelected', this.selectedSessionTimes);
                //
                // this.selectedSessionTime = hour;
            }
            ,
            // selectTime(hour) {
            //
            //     if (this.selectedTime == hour.id) {
            //         this.selectedTime = null;
            //     } else {
            //         this.selectedTime = hour.id;
            //     }
            //     this.$emit('timeSelected', this.selectedTime);
            // },
            getTimeOptionDate(time) {
                return moment(time, 'YYYY-MM-DD HH:mm:ss').format('HH:mm');
            }
            ,
        },
        filters: {
            toJalali(date) {
                return moment(date, "YYYY-M-DD").format('jYYYY/jMM/jDD');
            },
        }
    }
</script>

<style scoped>

</style>