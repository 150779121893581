<template>
    <div
            :class="{ 'd-flex flex-row-reverse': !msg.is_mine,
             'd-flex flex-row': msg.is_mine }"
            style="max-width:100%"
    >
        <v-card
                class="pa-1 ma-1 elevation-0"
                dense
        >
            <v-card-text class="pa-0 ma-0">
                <div>
                    <v-chip
                            :color="msg.is_mine ? 'primary' : ''"
                            style="height:auto;white-space: normal;"
                            class="pa-4 mb-0"
                    >
                        {{ msg.contents }}
                    </v-chip>
                </div>
                <sub
                        class="mt-0"
                        style="font-size: 0.5rem;"
                >{{ msg.date }}</sub>
            </v-card-text>
        </v-card>
    </div>
</template>
<script>

    // import BaseMessage from './BaseMessage';

    export default {
        props: ['msg'],
        name: 'text-message',
        // extends: BaseMessage,
        data() {
            return {}
        }
        ,
        methods: {},

        created() {
        }
    }
</script>

<style scoped>

</style>