<template>
    <v-container fluid>
        <v-card class="my-8">
            <v-card-text>
                <TableOptions :hasExport="true" :searchFilters="searchFilters"
                              @applyFilters="applyFilters"></TableOptions>
            </v-card-text>
            <v-data-table
                    hide-default-footer
                    :headers="headers"
                    :items="orders"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :search="search"
            >

                <template v-slot:item.user="{ item }">
                    <div style="position: relative;">
                        <div v-if="item.needsToRequestSession">
                            <a target="_blank" :href="'clients/'+item.user_id" class="new-payment-badge"
                               style="text-decoration: none">new</a>
                        </div>
                        <v-chip :href="'clients/'+item.user_id">
                            <a @click.prevent="getClientProfile(item.user_id)" :href="'clients/'+item.user_id"
                               style="text-decoration: none">{{ item.user }}</a>
                        </v-chip>
                    </div>

                </template>

                <template v-slot:item.packages="{ item }">

                    <td class="px-0" style="border: none;" v-if="item.packages.length">
                        <div class="text--secondary"
                             v-for="(el) in item.packages" :key="el.title">
                            {{ el.title }}
                            <v-icon x-small>mdi-close</v-icon>
                            {{el.count}}
                        </div>
                    </td>
                    <td class="px-0" style="border: none;" v-else-if="item.cart!=null">
                        <div class="text--secondary"
                             v-for="(el) in item.cart.packages" :key="el.title">
                            {{ el.title }}
                            <v-icon x-small>mdi-close</v-icon>
                          {{ el.pivot.quantity}}
                        </div>
                    </td>
                </template>
                <template v-slot:item.wallet="{item}">
                    {{item.walletRecord != null ? item.walletRecord.amount : 0}}
                </template>
                <template v-slot:item.final_amount="{item}">
                    <!--<template v-if="item.currency=='دلار'">-->
                        <!--{{item.final_amount}}-->
                    <!--</template>-->
                    <!--<template v-else>-->
                        {{Number(item.final_amount).toLocaleString()}}
                    <!--</template>-->

                </template>

            </v-data-table>
        </v-card>
        <div class="text-center" v-if="!fiveSession">
            <v-pagination
                    v-model="page"
                    :length="total"
            ></v-pagination>
        </div>
    </v-container>
</template>

<script>
    /*eslint-disable*/
    import {mapGetters} from 'vuex'
    import {adminFinancialFilters} from "../../searchFilters";
    import TableOptions from '@/components/TableOptions.vue';
    import {EventBus} from "../../event-bus";


    export default {
        components: {
            TableOptions
        },
        props: {
            title: {
                default: 'سفارشات',
                type: String
            },
            fiveSession: Boolean
        },
        data() {
            return {
                sortBy: [],
                sortDesc: [],
                options: {},
                searchFilters: adminFinancialFilters,
                // loading: true,
                search: '',
                headers: [
                    // {
                    //     text: 'شماره سفارش',
                    //     align: 'start',
                    //     sortable: false,
                    //     value: 'transaction_code',
                    // },
                    {text: 'مراجعه کننده', value: 'user'},
                    {text: 'ایمیل', value: 'email'},
                    {text: 'موبایل', value: 'mobile'},
                    {text: 'تاریخ خرید', value: 'date'},
                    {text: 'ساعت خرید', value: 'time'},
                    {text: 'متد', value: 'method.title'},
                    {text: 'محصول', value: 'packages'},
                    {text: 'ارز', value: 'currency'},
                    {text: 'مبلغ پرداختی', value: 'final_amount'},
                ],
                desserts: [],
                status: [
                    {
                        id: 1,
                        title: 'موفق',
                        code: 'paid'
                    },
                    {
                        id: 2,
                        title: 'ناموفق',
                        code: 'failed'
                    },
                    {
                        id: 3,
                        title: 'معلق',
                        code: 'unknown'
                    }
                ],
                state: 'paid',
                orders: [],
                page: 1,
                total: 1,
            }
        },
        computed: {
            sortByComp() {
                if (this.sortBy.includes('date')) {
                    return 'payment_date';
                } else {
                    return 'payment_date';
                }
            },

            ...mapGetters({
                // orders: 'getAdminOrders',
                // page: 'page',
                // total: 'total',
            }),
            colorStatus() {
                if (this.state == 'paid') {
                    return 'success'
                } else if (this.state == 'unknown') {
                    return 'warning'
                } else {
                    return 'error'
                }
            }
        },
        created() {
            if (this.$route.name == 'clientDetail') {
                this.headers = [
                    {
                        text: 'شماره سفارش',
                        align: 'start',
                        sortable: false,
                        value: 'transaction_code',
                    },
                    {text: 'مراجعه کننده', value: 'user'},
                    {text: 'ایمیل', value: 'email'},
                    {text: 'موبایل', value: 'mobile'},
                    {text: 'تاریخ خرید', value: 'date'},
                    {text: 'ساعت خرید', value: 'time'},
                    {text: 'متد', value: 'method.title'},
                    {text: 'محصول', value: 'packages'},
                    {text: 'ارز', value: 'currency'},
                    {text: 'مبلغ پرداختی', value: 'final_amount'},
                    {text: 'کد تخفیف', value: 'coupon_code'},
                    {text: 'مبلغ کیف پول', value: 'wallet'},
                ]
            } else {
                this.headers = [
                    {text: 'مراجعه کننده', value: 'user'},
                    {text: 'ایمیل', value: 'email'},
                    {text: 'موبایل', value: 'mobile'},
                    {text: 'تاریخ خرید', value: 'date'},
                    {text: 'ساعت خرید', value: 'time'},
                    {text: 'متد', value: 'method.title'},
                    {text: 'محصول', value: 'packages'},
                    {text: 'ارز', value: 'currency'},
                    {text: 'مبلغ پرداختی', value: 'final_amount'},
                    {text: 'کد تخفیف', value: 'coupon_code'},
                    {text: 'مبلغ کیف پول', value: 'wallet'},
                ]
            }
            // this.getAdminOrders();
        },
        methods: {
            customSort(inputs) {
                // console.log(this.sortBy);
                // console.log(this.sortDesc);
                return inputs;
            },
            applyFilters(options) {

                this.page = 1;
                this.options = options;
                // if (this.options.hasOwnProperty('orderBy')) {
                //     this.sortBy = [];
                //     this.sortDesc = [];
                //     let [key, value] = this.options.orderBy.split(':');
                //     switch (key) {
                //         case 'payment_date' :
                //             this.sortBy.push('date');
                //             break;
                //     }
                //     switch (value) {
                //         case 'asc' :
                //             this.sortDesc.push(false);
                //             break;
                //         case 'desc' :
                //             this.sortDesc.push(true);
                //             break;
                //     }
                this.getAdminOrders();
            },
            getAdminOrders() {
                // this.$store.commit('setLoading', true);
                // this.$store.dispatch('getAdminOrders', 'success').then(() => {
                // this.$store.commit('setLoading', false);
                // this.loading = false;
                // })

                // let options = {
                //     state: this.state,
                //     order: 'desc'
                // };

                if (this.$router.currentRoute.name == 'orders') {
                    EventBus.$emit('setLoading', true);
                    this.$store.dispatch('getAdminOrders', {
                        page: this.page,
                        options: this.options
                    }).then((data) => {

                        if (!this.options.hasOwnProperty('wantsExcel')) {
                            this.orders = data.data.payments;
                            this.total = data.data.pagination.total_pages;
                        } else {
                            delete this.options.wantsExcel;
                        }
                        // this.loading = false;
                    }).finally(() => {
                        EventBus.$emit('setLoading', false);
                    })
                } else if (this.$router.currentRoute.name == 'clientDetail') {
                    EventBus.$emit('setLoading', true);
                    this.$store.dispatch('getAdminClientOrders', {
                        options: {
                            user_id: this.$route.params.clientId,
                            ...this.options
                        },
                        page: this.page
                    }).then((data) => {
                        this.orders = data.data.payments;
                        this.total = data.data.pagination.total_pages;
                    }).finally(() => {
                        EventBus.$emit('setLoading', false);
                    })
                }

            },

            selectStatus(el) {
                this.state = el.code;
                // this.loading = true;
                this.getAdminOrders();
            },
            setAdminOrders(data) {
                this.orders = [];
                data.forEach(order => {
                    this.orders.push(order);
                    order['customer'] = order.user.firstname + ' ' + order.user.lastname;
                    order['date'] = order.jdatetime.split(' ')[0];
                    order['time'] = order.jdatetime.split(' ')[1];
                });
            },
            getClientProfile(id) {
                this.$router.push({name: 'clientDetail', params: {clientId: id}});
            },
        },
        watch: {
            // sortBy(data) {
            //     let obj = {
            //         sortBy: data,
            //         sortDesc: this.sortDesc
            //     };
            //     EventBus.$emit('applyFilters', obj);
            // },
            sortDesc(data) {
            },
            page() {
                this.getAdminOrders();
                this.$vuetify.goTo(this.$refs.adminOrders.offsetTop);
            },
            '$route': function () {
                this.getAdminOrders();
            }
        }
    }
</script>

<style scoped>
    .new-payment-badge {
        font-size: .7em;
        cursor: pointer;
        font-weight: bolder;
        position: absolute;
        right: -5px;
        top: -5px;
        padding: 2px 3px;
        background-color: rgba(230, 0, 0, 1);
        color: white;
        border-radius: 5px;
        z-index: 10;
    }
</style>
