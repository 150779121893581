<template>
    <v-container>
        <v-card>
            <PackageComponent @addPackage="addPackage" :sending="sending"></PackageComponent>
        </v-card>
    </v-container>
</template>

<script>
    import PackageComponent from '@/components/Admin/packages/PackageComponent';
    import {EventBus} from "../../event-bus";

    export default {
        components: {
            PackageComponent
        },
        data() {
            return {
                sending: false,
                // pack: {
                //     session_count: 2,
                //     title: 'تست من',
                //     doctor_id: 2,
                //     prices: [
                //         {
                //             change_date: "2020-07-30",
                //             created_at: "2020-07-30T05:19:57.000000Z",
                //             currency: "dollar",
                //             id: 16,
                //             package_id: 134,
                //             price: "44.00",
                //             state: "active",
                //             updated_at: "2020-07-30T05:19:57.000000Z"
                //         }
                //     ]
                // }
            }
        },
        methods: {
            addPackage(pack) {
                this.sending = true;
                this.$store.dispatch('addAdminPackages', pack).then(() => {
                    EventBus.$emit('notify', 'green', undefined, 'با موفقیت به ثبت رسید');
                }).finally(() => {
                    this.sending = false;
                })
            }
        }
    }
</script>

<style scoped>

</style>