<template>
    <div>
      <v-dialog scrollable v-model="editDialog" v-if="itemToEdit!=null" max-width="1000"
                  transition="scroll-y-transition">
            <v-card>
                <v-card-text>
                    <AdminEditPackages :packProp="itemToEdit" @close="closeEditPackage"
                                       @done="doneEditing"></AdminEditPackages>
                </v-card-text>
            </v-card>

        </v-dialog>

        <v-dialog v-model="showDialog" max-width="400">
            <v-card v-if="itemToEdit != null">
                <v-card-title>
                    <template v-if="itemToEdit.state == 'active'">
                        غیرفعال سازی بسته
                    </template>
                    <template v-else>
                        فعالسازی بسته
                    </template>
                </v-card-title>
                <v-divider></v-divider>

                <v-card-text class="pa-5">
                    آیا نسبت به عملیات مورد نظر اطمینان دارید؟
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="error elevation-0" text @click="showDialog = false">
                        خیر
                    </v-btn>
                    <v-btn class="primary elevation-0" text @click="togglePackage">
                        بله
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-container>
            <v-card class="my-8">
                <v-card-title>
                    {{ title }}
                    <v-spacer></v-spacer>
                    <v-btn color="primary" class="mt-5" :to="{name: 'addNewPackage'}">افزودن بسته</v-btn>
                </v-card-title>
                <v-card-text>
                    <TableOptions :searchFilters="searchFilters" @applyFilters="applyFilters"></TableOptions>
                </v-card-text>
                <v-data-table
                        :single-expand="singleExpand"
                        :expanded.sync="expanded"
                        show-expand
                        hide-default-footer
                        :headers="headers"
                        :items="requests"
                        :search="search"
                >
                    <template v-slot:item.sequence="{item}">
                        <v-edit-dialog
                                :return-value.sync="item.sequence"
                                large
                                @save="save(item)"
                                cancel-text="لغو"
                                save-text="ویرایش"

                        >
                            <div>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                            <span
                                                    v-bind="attrs"
                                                    v-on="on"
                                            >
                                                {{ item.sequence }}
                                            </span>
                                    </template>
                                    <span>ترتیب اولویت</span>
                                </v-tooltip>
                            </div>
                            <template v-slot:input>
                                <div class="my-1 title">
                                        <span>
                                            ویرایش ترتیب
                                        </span>
                                </div>
                                <v-text-field
                                        v-model="item.sequence"
                                        label="ویرایش"
                                        single-line
                                        autofocus
                                        style="direction:ltr;text-align: left;"
                                        outlined
                                ></v-text-field>
                            </template>
                        </v-edit-dialog>
                    </template>
                    <template v-slot:expanded-item="{ headers, item }">
                        <td :colspan="headers.length" class="pa-0 ma-0">
                            <PriceComponentTable :allPrices="item.prices"></PriceComponentTable>
                        </td>
                    </template>
                    <template v-slot:item.action="{item}">
                        <template v-if="item.state =='inactive'">
                            <v-btn small fab text @click="showActionDialog(item)">
                                <v-icon class="blue--text"> fas fa-eye</v-icon>
                            </v-btn>
                        </template>
                        <template v-else>
                            <v-btn small fab text @click="showActionDialog(item)">
                                <v-icon class="red--text"> fas fa-eye-slash</v-icon>
                            </v-btn>
                        </template>
                        <v-btn small fab text @click="openEditDialog(item)">
                            <v-icon small>fas fa-edit</v-icon>
                        </v-btn>
                    </template>

                    <template v-slot:item.doctor="{item}">
                        <template v-if="item.doctor!=null">
                            {{item.doctor.full_name}}
                        </template>
                    </template>

                    <template v-slot:item.state="{item}">
                        {{ item.state == 'active' ? 'فعال':'غیرفعال' }}
                    </template>


                  <template v-slot:item.type="{item}">
                        {{  types[item.type] || item.type }}
                    </template>


                </v-data-table>
            </v-card>
            <div class="text-center" v-if="!fiveSession">
                <v-pagination
                        v-model="page"
                        :length="total"
                ></v-pagination>
            </div>
        </v-container>

    </div>
</template>

<script>
    /*eslint-disable*/
    import {mapGetters} from 'vuex';
    import {EventBus} from "../../event-bus";
    import {adminPackageFilters} from "../../searchFilters";
    import TableOptions from '@/components/TableOptions.vue';
    import PriceComponentTable from '@/components/Admin/PriceComponentTable.vue';
    // import AddPriceComponent from '@/components/Admin/AddPriceComponent.vue';
    import AdminEditPackages from '@/components/Admin/packages/AdminEditPackages';
    import constants from "@/constants/constants";

    export default {
        components: {
            TableOptions,
            PriceComponentTable,
            // AddPriceComponent,
            AdminEditPackages
        },
        name: 'requests',
        props: {
            title: {
                default: 'بسته ها',
                type: String
            },
            fiveSession: Boolean
        },
        data() {
            return {
                pricesToEdit: [],
                expanded: [],
                singleExpand: false,

                priceToAdd: {
                    currency: null,
                    price: null
                },
                editDialog: false,
                showDialog: false,
                itemToEdit: null,
                options: {},
                requesting: false,
                searchFilters: [],
                total: 1,
                page: 1,
                loading: false,
                loadingBtn: false,
                dialog: false,
                search: '',
                headers: [
                    {text: 'کد', value: 'id'},

                    {text: 'دکتر', value: 'doctor'},
                    {text: 'نوع', value: 'type'},
                    {text: 'وضعیت', value: 'state'},
                    {text: 'عنوان', value: 'title'},
                    {text: 'تعداد', value: 'qt'},
                    {text: 'ترتیب', value: 'sequence'},

                    {text: 'عملیات', value: 'action'},
                ],
                desserts: [],
                request: null,
                requests: []
            }
        },
        computed: {
            ...mapGetters({
                // requests: 'getAdminPackages',
                // page: 'page',
                // total: 'total',
            }),
          types(){
              return constants.packages.types.reduce(function(map, obj) {
                map[obj.value] = obj.title;
                return map;
              }, {});
          }
        },
        created() {
            // this.getAdminPackages();
            this.searchFilters = adminPackageFilters;
        },
        methods: {
            save(item) {
                EventBus.$emit('setLoading', true);
                let payload = {
                    sequence: item.sequence
                };

                this.$store.dispatch('AdminChangePackageOrder', {id: item.id, payload}).then(() => {
                    EventBus.$emit('notify', 'green', undefined, 'با موفقیت به ثبت رسید');
                    EventBus.$emit('setLoading', false);
                    this.getAdminPackages();
                });

            },
            // submitNewPrices() {
            //     this.$store.dispatch('saveNewPrices', {
            //         id: this.itemToEdit.id,
            //         prices: this.pricesToEdit
            //     }).then((resp) => {
            //         console.log(resp);
            //
            //
            //         this.getAdminPackages().then(() => {
            //             this.expanded = [];
            //             this.singleExpand = false;
            //             this.pricesToEdit = [];
            //             EventBus.$emit('notify', 'green', undefined, 'با موفقیت انجام شد');
            //             this.closeEditPackage();
            //         });
            //
            //     })
            // },
            doneEditing() {
                this.expanded = [];
                this.singleExpand = false;
                this.pricesToEdit = [];
                this.getAdminPackages().then(() => {
                    this.closeEditPackage();
                });

            },
            closeEditPackage() {
                this.editDialog = false;
                this.itemToEdit = null;
                this.pricesToEdit = [];
            },
            // saveNewPrices(prices) {
            //     this.pricesToEdit = prices;
            // },
            openEditDialog(item) {
                this.itemToEdit = item;
                // this.pricesToEdit = [...item.prices];
                this.editDialog = true;
            },
            togglePackage() {
                let id = this.itemToEdit.id;
                EventBus.$emit('setLoading', true);

                this.$store.dispatch('adminTogglePackage', id).then((resp) => {
                    console.log(resp);
                    this.showDialog = false;
                    this.itemToEdit = null;
                    this.getAdminPackages().then(() => {
                        EventBus.$emit('notify', 'green', undefined, 'با موفقیت انجام شد');
                    });
                }).finally(() => {
                    EventBus.$emit('setLoading', false);
                })
            },
            showActionDialog(item) {
                this.itemToEdit = item;
                this.showDialog = true;
            },
            applyFilters(options) {
                this.page = 1;
                this.options = options;
                this.getAdminPackages();
            },
            getAdminPackages() {
                return new Promise((resolve) => {
                    EventBus.$emit('setLoading', true);
                    this.$store.dispatch('getAdminPackages', {
                        page: this.page,
                        options: this.options
                    }).then((data) => {

                        this.requests = data.data.packages;
                        this.total = data.data.pagination.total_pages;
                        resolve();

                    }).finally(() => {
                        EventBus.$emit('setLoading', false);
                    })
                })


            },
            // nextPage() {
            //     this.$store.dispatch('pageChangeHandle', {value: 'next', page: this.page, url: '/admin/users/requests', commitName: 'setAdminRequests'});
            //     console.log('page', this.page)
            // },
            // previousPage() {
            //     this.$store.dispatch('pageChangeHandle', {value: 'previous', page: this.page, url: '/admin/users/requests', commitName: 'setAdminRequests'});
            //     console.log('page', this.page)
            // },
            // specificPage(page) {
            //     this.$store.commit('SET_SPECIFIC_PAGE', page);
            //     this.$store.dispatch('pageChangeHandle', {value: '', page: page, url: '/admin/users/requests', commitName: 'setAdminRequests'});
            //     console.log('page', page)
            // },
            requestDetail(item) {
                this.dialog = true;
                this.request = item;
                // console.log('request', this.request)
            },
            requestUpdate() {
                this.loading = true;
                EventBus.$emit('setLoading', true);
                this.$store.dispatch('updateTicketState', this.request.id).then(() => {
                    this.dialog = false;
                    this.requests.splice(this.requests.indexOf(this.request), 1);
                }).finally(() => {
                    this.loading = false;
                    EventBus.$emit('setLoading', false);
                })

            }
        },
        watch: {
            editDialog(newVal) {
                if (!newVal) {
                    this.closeEditPackage();
                }
            },
            page() {

                this.getAdminPackages();
                // this.$vuetify.goTo(this.$refs.adminSessions.offsetTop);
            }
        }
    }
</script>

<style scoped>

</style>
