<template>
    <v-container style="min-height: 100vh;" fluid>
        <admin-sessions v-if="$store.getters.userHasRole('admin')"></admin-sessions>
        <CounselorSessionsNewDesign v-if="$store.getters.userHasRole('counselor')"></CounselorSessionsNewDesign>
        <!--<counselor-sessions v-if="$store.getters.userHasRole('counselor')"></counselor-sessions>-->
        <!--<client-sessions v-if="$store.getters.userHasRole('customer')"></client-sessions>-->
        <clientSessionsNewDesign v-if="$store.getters.userHasRole('customer')"></clientSessionsNewDesign>
    </v-container>
</template>

<script>
    import AdminSessions from '@/components/sessions/AdminSessions.vue'
    // import CounselorSessions from '@/components/sessions/CounselorSessions.vue'
    // import ClientSessions from '@/components/sessions/ClientSessions.vue'
    import clientSessionsNewDesign from '@/components/sessions/clientSessionsNewDesign.vue'
    import CounselorSessionsNewDesign from '@/components/sessions/CounselorSessionsNewDesign.vue'

    export default {
        components: {
            AdminSessions,
            // CounselorSessions,
            // ClientSessions,
            clientSessionsNewDesign,
            CounselorSessionsNewDesign
        }
    }
</script>

<style scoped>

</style>