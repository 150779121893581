<template>
    <v-card>
        <v-card-title>
            بازپرداخت
        </v-card-title>
        <v-card-text>
            <v-row dense>
                <v-col cols="12" sm="6" md="4">
                    <v-text-field hide-details label="خریدار" outlined readonly
                                  :value="payment.user"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                    <v-text-field hide-details label="تاریخ خرید" outlined readonly
                                  :value="payment.date"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4" v-if="payment.method!=null">
                    <v-text-field hide-details label="متد" outlined readonly
                                  :value="payment.method.title"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                    <v-text-field hide-details label="ارز" outlined readonly
                                  :value="payment.currency"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                    <v-text-field hide-details label="مبلغ پرداختی" outlined readonly
                                  :value="payment.final_amount"></v-text-field>
                </v-col>
            </v-row>
            <v-row dense>
                <v-col>
                    بسته ها
                    <v-list dense>
                        <v-list-item-content>
                            <template v-for="(el) in payment.packages">
                                <v-divider :key="'divider-'+el.id"></v-divider>
                                <v-list-item :key="el.id">
                                    {{ el.title }}
                                    <v-icon x-small>mdi-close</v-icon>
                                    {{el.count}}
                                </v-list-item>
                            </template>
                        </v-list-item-content>
                    </v-list>
                </v-col>
            </v-row>
            <v-form ref="refundForm">
                <v-row dense>
                    <v-col>
                        <v-text-field v-model="refund.refundAmount" hide-details label="مبلغ بازپرداختی" outlined
                                      :rules="requiredRules"></v-text-field>
                    </v-col>
                    <v-col>

                        <v-select v-model="currency" hide-details :items="currencies"
                                  item-text="title"
                                  item-value="title"
                                  label="ارز"
                                  return-object
                                  :rules="requiredRules" outlined></v-select>
                    </v-col>
                    <v-col>
                        <v-text-field v-model="refund.sessionsToSubtract" hide-details label="تعداد جلسات برای کسر"
                                      outlined></v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-textarea v-model="refund.info" hide-details height="100" outlined
                                    label="توضیحات"></v-textarea>
                    </v-col>

                </v-row>
            </v-form>
        </v-card-text>


        <v-card-actions>

            <v-spacer></v-spacer>
            <v-btn class="error" @click="$emit('close')">بستن</v-btn>
            <v-btn class="primary" @click="requestRefund">بازپرداخت</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    /*eslint-disable*/
    import {EventBus} from "../../../event-bus";
    import {mapGetters} from 'vuex';

    export default {
        props: {
            payment: {}
        },
        data() {
            return {
                // currencies: [
                //     {
                //         title: 'تومان',
                //         value: 'rial'
                //     }, {
                //         title: 'دلار',
                //         value: 'dollar',
                //     }
                // ],
                refund: {
                    refundAmount: '',
                    currency: '',
                    sessionsToSubtract: '',
                    info: '',
                },
                currency: ''
            };
        },
        computed: {
            ...mapGetters({
                currencies: 'getCurrencies'
            }),
            requiredRules() {
                return [v => !!v || this.$vuetify.lang.t('$vuetify.fillIsRequired')];
            },
        },
        methods: {
            requestRefund() {
                if (this.$refs.refundForm.validate()) {
                    EventBus.$emit('setLoading', true);
                    this.refund.currency = this.currency.value;
                    this.$store.dispatch('adminRequestRefund',
                        {
                            id: this.payment.id,
                            payload: this.refund
                        }
                    ).then((resp) => {
                        console.log(resp);
                        EventBus.$emit('notify', 'green', undefined, 'با موفقیت به ثبت رسید');
                        this.$emit('done');
                    }).finally(() => {
                        EventBus.$emit('setLoading', false);
                    })
                }
            }
        }

    }
</script>

<style scoped>

</style>