<template>
    <div>
        <counselor-dashboard v-if="$store.getters.userHasRole('counselor')"></counselor-dashboard>
        <admin-dashboard v-else-if="$store.getters.userHasRole('admin')"></admin-dashboard>
        <!--<client-dashboard v-else-if="$store.getters.userHasRole('customer')"></client-dashboard>-->
        <clientHomePage v-else-if="$store.getters.userHasRole('customer')"></clientHomePage>
    </div>
</template>

<script>

    import counselorDashboard from '@/components/dashboards/counselorDashboard.vue';
    import adminDashboard from '@/components/dashboards/adminDashboard.vue';
    // import clientDashboard from '@/components/dashboards/clientDashboard.vue';
    import clientHomePage from '@/components/dashboards/clientHomePage.vue';

    export default {
        created() {
            console.log(new Date("2021-05-17T08:45:15.000Z"));
        },
        components: {
            counselorDashboard,
            adminDashboard,
            // clientDashboard,
            clientHomePage
        },
        methods: {
            // userHasRole(role) {
            //     return this.$store.getters.userHasRole(role);
            // }
        }
    }
</script>

<style scoped>

</style>