var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',[_c('v-card',{staticClass:"my-8"},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.title)+" "),_c('v-spacer',{ref:"counselorClients"}),_c('v-text-field',{attrs:{"solo":"","append-icon":"mdi-magnify","label":"جستجو","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"hide-default-footer":"","headers":_vm.headers,"items":_vm.clients,"search":_vm.search},scopedSlots:_vm._u([{key:"item.fullname",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.getClientProfile(item.id)}}},[_vm._v(" "+_vm._s(item.name)+" "+_vm._s(item.family)+" ")])]}},{key:"item.cycle",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.cycle.heading)+" ")]}},{key:"item.nextSession",fn:function(ref){
var item = ref.item;
return [(item.next_session!=null)?[_vm._v(" "+_vm._s(item.next_session.diff_time)+" ")]:_vm._e()]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"primary my-2","small":""},on:{"click":function($event){return _vm.getClientProfile(item.id)}}},[_vm._v(" مشاهده پرونده ")])]}}])})],1),(!_vm.fiveSession)?_c('div',{staticClass:"text-center"},[_c('v-pagination',{attrs:{"length":_vm.total},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }