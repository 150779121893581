<template>
    <div>
        <v-dialog v-model="refundDialog" max-width="800px" scrollable>
            <RefundDialog :payment="itemToChange" @close="closeRefundDialog" @done="doneRefunding"></RefundDialog>
        </v-dialog>
        <v-dialog v-model="changeDateDialog" max-width="700px" scrollable v-if="itemToChange!=null">
            <v-card>
                <v-card-title>تغییر تاریخ پرداخت</v-card-title>
                <v-card-text>


                    <v-text-field
                            outlined
                            v-model="newDate"
                            label="تاریخ پرداخت"
                            prepend-icon="event"
                            readonly
                            @click="dateModal=true"

                    ></v-text-field>
                    <AdminDatePicker @close="dateModal=false" :dialog="dateModal"
                                     v-model="newDate"></AdminDatePicker>


                </v-card-text>
                <v-card-actions>
                    <v-btn class="red white--text" text @click="changeDateDialog = false">بستن</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn class="blue white--text" @click="changePaymentDate">تایید</v-btn>
                </v-card-actions>

            </v-card>
        </v-dialog>

        <v-container fluid>
            <v-card class="my-8">

                <v-card-text>
                    <TableOptions :hasExport="true"
                                  :searchFilters="searchFilters"
                                  prefix="financial"
                                  @setOrderAndDesc="setOrder"
                                  @applyFilters="applyFilters"
                                  :hasSepidarExport="true"
                    ></TableOptions>
                </v-card-text>
                <v-data-table
                        hide-default-footer
                        :headers="headers"
                        :items="orders"
                        :sort-by.sync="sortBy"
                        :sort-desc.sync="sortDesc"
                        :search="search"
                        :custom-sort="customSort"
                >
                    <!--<template v-slot:header.date="{header}">-->
                    <!--<v-btn @click="applyHeader(header)" class="elevation-0" small text>تاریخ</v-btn>-->
                    <!--</template>-->
                    <template v-slot:item.user="{ item }">
                        <v-chip @click="getClientProfile(item.user_id)">{{ item.user }}</v-chip>
                    </template>

                    <template v-slot:item.actions="{ item }">
                        <v-btn x-small fab class="primary ma-1 " @click="refundPayment(item)">
                            <v-icon class="white--text">fas fa-hand-holding-usd</v-icon>
                        </v-btn>

                        <v-btn x-small fab class="primary  " @click="showChangeDateDialog(item)">
                            <v-icon class="white--text">fas fa-edit</v-icon>
                        </v-btn>

                    </template>

                    <template v-slot:item.packages="{ item }">

                        <td class="px-0" style="border: none;">
                            <div class="text--secondary"
                                 v-for="(el) in item.packages" :key="el.id">
                                {{ el.title }}
                                <v-icon x-small>mdi-close</v-icon>
                                {{el.count}}
                            </div>
                        </td>
                    </template>

                    <template v-slot:item.wallet="{item}">
                        {{item.walletRecord != null ? item.walletRecord.amount : 0}}
                    </template>
                    <template v-slot:item.final_amount="{item}">
                        <!--<template v-if="item.currency=='دلار'">-->
                            <!--{{item.final_amount}}-->
                        <!--</template>-->
                        <!--<template v-else>-->
                            {{Number(item.final_amount).toLocaleString()}}
                        <!--</template>-->
                    </template>
                </v-data-table>
            </v-card>
            <div class="text-center" v-if="!fiveSession">
                <v-pagination
                        v-model="page"
                        :length="total"
                ></v-pagination>
            </div>
        </v-container>
    </div>
</template>

<script>
    /*eslint-disable*/
    import {mapGetters} from 'vuex'
    import {adminFinancialRecordsFilters} from "../../searchFilters";
    import TableOptions from '@/components/TableOptions.vue';

    import {EventBus} from "../../event-bus";
    import RefundDialog from '@/components/Admin/financial/RefundDialog.vue';
    import moment from 'moment-jalaali';
    import AdminDatePicker from '@/components/AdminDatePicker';


    export default {
        components: {
            TableOptions,
            RefundDialog,
            AdminDatePicker
        },
        props: {
            title: {
                default: 'سفارشات',
                type: String
            },
            fiveSession: Boolean
        },
        data() {
            return {
                newDate: null,
                dateModal: false,
                changeDateDialog: false,
                refundDialog: false,
                action: null,
                itemToChange: null,
                showDialog: false,
                lockHeaders: false,
                sortBy: [],
                sortDesc: [],
                options: {},
                searchFilters: adminFinancialRecordsFilters,
                // loading: true,
                search: '',
                headers: [
                    {
                        text: 'شماره سفارش', align: 'start', sortable: false, value: 'transaction_code',
                    },
                    {text: 'مراجعه کننده', value: 'user'},
                    {text: 'ایمیل', value: 'email'},
                    {text: 'موبایل', value: 'mobile'},
                    {text: 'تاریخ خرید', value: 'date'},
                    {text: 'ساعت خرید', value: 'time'},
                    {text: 'متد', value: 'method.title'},
                    {text: 'محصول', value: 'packages'},
                    {text: 'ارز', value: 'currency'},
                    {text: 'مبلغ پرداختی', value: 'final_amount'},
                    {text: 'مبلغ کیف پول', value: 'wallet'},
                    {text: 'کد تخفیف', value: 'coupon_code'},
                    {text: 'عملیات', value: 'actions'},
                ],
                desserts: [],
                status: [
                    {
                        id: 1,
                        title: 'موفق',
                        code: 'paid'
                    },
                    {
                        id: 2,
                        title: 'ناموفق',
                        code: 'failed'
                    },
                    {
                        id: 3,
                        title: 'معلق',
                        code: 'unknown'
                    }
                ],
                state: 'paid',
                orders: [],
                page: 1,
                total: 1,
            }
        },
        filters: {
            toGaregorian(input) {
                return moment(input, 'jYYYY-jDD-jMM').format('YYYY-MM-DD');
            }
        },
        computed: {
            colorStatus() {
                if (this.state == 'paid') {
                    return 'success'
                } else if (this.state == 'unknown') {
                    return 'warning'
                } else {
                    return 'error'
                }
            }
        },
        created() {
            // this.headers = [
            //     // {
            //     //     text: 'شماره سفارش',
            //     //     align: 'start',
            //     //     sortable: false,
            //     //     value: 'transaction_code',
            //     // },
            //     {text: 'مراجعه کننده', value: 'user'},
            //     {text: 'ایمیل', value: 'email'},
            //     {text: 'موبایل', value: 'mobile'},
            //     {text: 'تاریخ خرید', value: 'date'},
            //     {text: 'ساعت خرید', value: 'time'},
            //     {text: 'محصول', value: 'packages'},
            //     {text: 'ارز', value: 'currency'},
            //     {text: 'مبلغ پرداختی', value: 'final_amount'},
            // ]

        },
        methods: {
            changePaymentDate() {
                let data = {
                    paymentId: this.itemToChange.id,
                    payload: {
                        date: this.newDate
                    }
                };
                EventBus.$emit('setLoading', true);
                this.$store.dispatch('changePaymentDate', data).then(() => {
                    EventBus.$emit('notify', 'green', undefined, 'با موفقیت به ثبت رسید');

                    this.changeDateDialog = false;
                    this.itemToChange = null;
                }).finally(() => {
                    EventBus.$emit('setLoading', false);
                })
            },
            closeRefundDialog() {
                this.refundDialog = false;
                this.itemToChange = null;
            },
            showChangeDateDialog(item) {
                this.itemToChange = item;
                this.newDate = moment(item.date, 'jYYYY-jMM-jDD').format('YYYY-MM-DD');
                this.changeDateDialog = true;
            },
            doneRefunding() {
                this.itemToChange = null;
                this.refundDialog = false;
            },
            refundPayment(item) {
                this.itemToChange = item;
                this.refundDialog = true;
            },
            applyHeader(item) {
                console.log(item);
            },
            setOrder(data) {
                this.lockHeaders = true;
                this.sortBy = [data.header];
                this.sortDesc = [data.order];
            },
            customSort(inputs) {
                return inputs;
            },
            applyFilters(options) {

                this.page = 1;
                this.options = options;
                this.getAdminOrders();
            },
            getAdminOrders() {
                EventBus.$emit('setLoading', true);
                this.$store.dispatch('getAdminFinancialRecords', {
                    page: this.page,
                    options: this.options
                }).then((data) => {

                    if (!this.options.hasOwnProperty('wantsExcel')) {
                        this.orders = data.data.payments;
                        this.total = data.data.pagination.total_pages;
                    } else {
                        delete this.options.wantsExcel;
                    }
                }).finally(() => {
                    EventBus.$emit('setLoading', false);
                })
            },

            selectStatus(el) {
                this.state = el.code;
                this.getAdminOrders();
            },
            setAdminOrders(data) {
                this.orders = [];
                data.forEach(order => {
                    this.orders.push(order);
                    order['customer'] = order.user.firstname + ' ' + order.user.lastname;
                    order['date'] = order.jdatetime.split(' ')[0];
                    order['time'] = order.jdatetime.split(' ')[1];
                });
            },
            getClientProfile(id) {

                this.$router.push({name: 'clientDetail', params: {clientId: id}});
            },
        },
        watch: {
            sortBy(after, before) {
                if (!this.lockHeaders) {
                    if (before != after) {
                        if (this.sortDesc[0] == false) {
                            let obj = {
                                sortBy: after,
                                sortDesc: this.sortDesc,
                                prefix: 'financial'
                            };
                            EventBus.$emit('applySort', obj);
                        }
                    }
                }
                this.lockHeaders = false;
            },
            sortDesc(data) {
                if (!this.lockHeaders) {
                    let obj = {
                        sortBy: this.sortBy,
                        sortDesc: data,
                        prefix: 'financial'
                    };
                    EventBus.$emit('applySort', obj);
                }
                this.lockHeaders = false;
            },
            page() {
                this.getAdminOrders();
                this.$vuetify.goTo(this.$refs.adminOrders.offsetTop);
            },
            '$route': function () {
                this.getAdminOrders();
            }
        }
    }
</script>

<style scoped>

</style>
