<template>
    <div>
        <v-container class="py-12">
            <v-row>
                <v-col cols="6" sm="6" md="3" v-for="(heading,i) in headings" :key="i">
                    <v-card
                            class="mx-auto"
                            color="#26c6da"
                            dark
                            max-width="400"
                    >
                        <v-card-title>
                            <v-icon large class="mx-1">

                                {{heading.icon}}
                            </v-icon>
                            <span class="title font-weight-light">
                            <span>{{heading.text}}</span>
                        </span>
                        </v-card-title>

                        <v-card-text class="headline font-weight-bold">
                            {{heading.value}}
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-card class="elevation-1 my-6" min-height="200">
                <v-card-title>
                    جلسات پیش رو
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <v-row justify="center" align="center">

                        <v-col cols="12" sm="8" md="5" lg="4"
                               v-for="(session,index) in next_four_sessions"
                               :key="index">
                            <SessionCard :mode="'doctor'" :factor="false" :session="session"></SessionCard>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <v-row justify="center" dense>
                <v-col cols="6" sm="4" class="ma-sm-1">
                    <v-btn block height="50"
                           href="https://play.google.com/store/apps/details?id=com.mars.platroom"
                           target="_blank"
                    >
                        <v-icon class=" green--text px-2">fab fa-android</v-icon>
                        <span style="text-decoration: none" class="mt-1">دانلود اپ اندروید</span>

                    </v-btn>
                </v-col>
                <v-col cols="6" sm="4" class="ma-sm-1">
                    <v-btn block height="50"
                           href="https://apps.apple.com/us/app/platroom/id1516231951" target="_blank"
                    >
                        <v-icon class="px-2">fab fa-apple</v-icon>
                        <span style="text-decoration: none" class="mt-1">دانلود اپ IOS</span>
                    </v-btn>
                </v-col>
            </v-row>
            <v-row justify="center" dense>
                <v-col cols="6" sm="4" class="ma-sm-1">
                    <v-btn block height="50"
                           href="http://app.simiaroom.com/apps/win/PlatRoom Setup 1.1.2.exe"
                           target="_blank"
                    >
                        <v-icon class="px-2 blue--text">fab fa-windows</v-icon>
                        <span style="text-decoration: none" class="mt-1">دانلود نسخه ویندوز</span>

                    </v-btn>
                </v-col>
                <v-col cols="6" sm="4" class="ma-sm-1">
                    <v-btn block height="50"
                           href="http://app.simiaroom.com/apps/mac/PlatRoom-1.1.2.dmg" target="_blank"
                    >
                        <v-icon class="px-2">fab fa-apple</v-icon>
                        <span style="text-decoration: none" class="mt-1">دانلود نسخه مک</span>
                    </v-btn>
                </v-col>
            </v-row>
            <!--<v-row justify="center">-->
            <!--<v-col cols="12" sm="6" md="3">-->
            <!--<v-card class="pa-5">-->
            <!--<v-card-text class="">-->
            <!--<v-row>-->
            <!--<v-col cols="8">کل جلسات برگزار شده</v-col>-->
            <!--<v-col cols="4">{{ dashboard.totalSessionCount }}</v-col>-->
            <!--</v-row>-->

            <!--</v-card-text>-->
            <!--</v-card>-->
            <!--</v-col>-->
            <!--&lt;!&ndash;<v-col cols="12" sm="6" md="3">&ndash;&gt;-->
            <!--&lt;!&ndash;<v-card class="pa-5">&ndash;&gt;-->
            <!--&lt;!&ndash;<v-card-text class="">&ndash;&gt;-->
            <!--&lt;!&ndash;<v-row>&ndash;&gt;-->
            <!--&lt;!&ndash;<v-col cols="8">مجموع درآمد</v-col>&ndash;&gt;-->
            <!--&lt;!&ndash;<v-col cols="4">{{ dashboard.totalSales }}</v-col>&ndash;&gt;-->
            <!--&lt;!&ndash;</v-row>&ndash;&gt;-->

            <!--&lt;!&ndash;</v-card-text>&ndash;&gt;-->
            <!--&lt;!&ndash;</v-card>&ndash;&gt;-->
            <!--&lt;!&ndash;</v-col>&ndash;&gt;-->
            <!--<v-col cols="12" sm="6" md="3">-->
            <!--<v-card class="pa-5">-->
            <!--<v-card-text class="">-->
            <!--<v-row>-->
            <!--<v-col cols="8">تعداد مراجعان</v-col>-->
            <!--<v-col cols="4">{{ dashboard.usersCount }}</v-col>-->
            <!--</v-row>-->

            <!--</v-card-text>-->
            <!--</v-card>-->
            <!--</v-col>-->
            <!--<v-col cols="12" sm="6" md="3">-->
            <!--<v-card class="pa-5">-->
            <!--<v-card-text>-->
            <!--<v-row>-->
            <!--<v-col cols="8">جلسات پیش رو</v-col>-->
            <!--<v-col cols="4">{{ dashboard.nextSessionsCount }}</v-col>-->
            <!--</v-row>-->
            <!--</v-card-text>-->
            <!--</v-card>-->
            <!--</v-col>-->
            <!--</v-row>-->
            <!--<counselor-sessions v-if="nextFiveSessions.length" :title="nextTitle" :five-sessions="nextFiveSessions"-->
                                <!--is-five></counselor-sessions>-->
        </v-container>
    </div>
</template>

<script>
    // import CounselorSessions from "../sessions/CounselorSessions";
    import {EventBus} from "../../event-bus";
    import SessionCard from '@/components/sessions/SessionCard'

    export default {
        components: {
            // CounselorSessions,
            SessionCard
        },
        data() {
            return {
                headings: [
                    {
                        key: 'allSessions',
                        text: 'جلسات برگزار شده',
                        icon: 'mdi-heart-box',
                        value: 0
                    },
                    {
                        key: 'futureSessions',
                        text: 'جلسات پیش رو',
                        icon: 'mdi-monitor',
                        value: 0
                    },
                    {
                        key: 'messages',
                        text: 'پیام ها', icon: 'mdi-message', value: '0'
                    },
                    {
                        key: 'patientsCount',
                        text: 'تعداد مراجعین',
                        icon: 'fas fa-users',
                        value: 0
                    },
                ],
                search: '',
                headers: [
                    {
                        text: 'مراجعه کننده',
                        align: 'start',
                        sortable: false,
                        value: 'customer',
                    },
                    {text: 'روز', value: 'jdayName'},
                    {text: 'تاریخ', value: 'date'},
                    {text: 'ساعت شروع', value: 'time'},
                    {text: 'مدت زمان', value: 'period', sortable: false},
                    {text: '', value: 'datetimeago'},
                    {text: '', value: 'action', sortable: false},
                ],
                nextFiveSessions: [],
                dashboard: {
                    totalSessionCount: '',
                    totalSales: '',
                    usersCount: '',
                    nextSessionsCount: '',

                },
                next_four_sessions: [],
            }
        },
        computed: {
            nextTitle() {

                if (this.nextFiveSessions.length) {

                    if (this.nextFiveSessions.length == 5) {
                        return '5 جلسه پیش رو';
                    }
                    else {
                        return this.dashboard.nextSessionsCount + ' جلسه پیش رو';
                    }

                }
                return '';

            }
            // ...mapGetters({
            //     dashboard: 'getCounselorDashboard'
            // })
        },
        methods: {
            getHeading(key) {
                return this.headings.find((item) => item.key == key)
            },
            setHeadings(headings) {
                headings.forEach((heading) => {
                    let hd = this.getHeading(heading.key);
                    hd.value = heading.value;
                })
            }
        },
        created() {
            EventBus.$emit('setLoading', true);

            this.$store.dispatch('getCounselorDashboard').then((data) => {
                let dashboard = data.data;

                this.next_four_sessions = dashboard.next_four_sessions;

                this.setHeadings([
                    {key: 'allSessions', value: dashboard.allSessions},
                    {key: 'futureSessions', value: dashboard.nextSessionsCount},
                    {key: 'messages', value: 0},
                    {key: 'patientsCount', value: dashboard.allClients}
                ]);


                // this.dashboard.totalSessionCount = dashboard.allSessions;
                // this.dashboard.totalSales = dashboard.packagesCount;
                // this.dashboard.usersCount = dashboard.allClients
                // this.dashboard.nextSessionsCount = dashboard.nextSessionsCount;
                // this.nextFiveSessions = dashboard.nextFiveSessions;


                //
                // console.log('Dashboard Data: ', data);
                // this.dashboard = data.data;
                // this.nextFiveSessions = data.data.nextSessionsList;
                // this.nextFiveSessions.forEach(session => {
                //     session['date'] = session.jdatetime.split(' ')[0];
                //     session['time'] = session.jdatetime.split(' ')[1];
                //     session['period'] = '45 دقیقه';
                // })
            }).finally(() => {
                EventBus.$emit('setLoading', false);
            });
        }
    }
</script>

<style scoped>

</style>