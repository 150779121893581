<template>
    <div style="position:relative;">
        <v-col :class="{'pa-2':!sidebar,'pa-0':sidebar} ">
            <v-autocomplete
                    hide-details
                    :items="items"
                    :solo="searchFocused"
                    :solo-inverted="!searchFocused"
                    :flat="!searchFocused"
                    @focus="searchFocused=true"
                    item-text="full_name"
                    item-value="email"
                    return-object
                    @blur="searchFocused=false"
                    :search-input.sync="contactName"
                    ref="searchField"
                    placeholder="جستجو"
                    dense
                    v-model="selectedContact"
                    class="smooth-transition"
            >
                <template v-slot:item="{item}">
                    <v-row dense class="no-gutters">
                        <v-col cols="12" class="subtitle-1"><span>{{item.full_name}}</span></v-col>
                        <v-col cols="12" class="caption grey--text text--darken-1">{{item.email}}
                            <v-divider></v-divider>
                        </v-col>

                    </v-row>
                </template>
            </v-autocomplete>
        </v-col>

        <v-list class="mt-1" subheader>
            <v-list-item-group v-model="activeChat">

                <template v-for="(item, index) in conversations">

                    <v-list-item
                            :key="`parent${index}`"
                            :value="item.id"
                            @click="setActiveConversation(item)"
                            :class="{'red':activeChatId==item.id}"
                    >
                        <v-list-item-avatar color="white--text ">
                            <v-avatar>
                                <img :src='item.avatar!=null ? item.avatar : defaultAvatar' alt="">
                            </v-avatar>
                        </v-list-item-avatar>

                        <v-list-item-content>
                            <v-list-item-title v-text="item.full_name"/>
                            <v-list-item-subtitle>
                                <template v-if="item.last_message != null ">
                                    {{item.last_message.contents}}
                                </template>
                            </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-icon>
                            <div class="grey--text caption">
                                <template v-if="item.last_message != null ">
                                    {{item.last_message.date}}
                                </template>
                            </div>
                        </v-list-item-icon>
                        <template v-if="item.conversation != null">
                            <v-list-item-icon v-if="hasNewMessage(item.conversation.id)">
                                <v-badge :content="newMessageCount(item.conversation.id)"></v-badge>
                            </v-list-item-icon>
                        </template>
                    </v-list-item>
                    <v-divider
                            :key="`chatDivider${index}`"
                            class="my-0"
                    />
                </template>
            </v-list-item-group>
        </v-list>
    </div>
</template>

<script>
    /*eslint-disable*/
    import {EventBus} from "../../event-bus";
    import {mapGetters} from 'vuex';

    export default {
        props: {
            conversations: {
                default: () => {
                    return []
                }
            },
            sidebar: {
                default: false
            }
        },
        computed: {
            ...mapGetters(['getNewMessagesConversations']),
            activeChatId() {
                if (this.activeChat != null || this.activeChat != undefined) {
                    return this.activeChat.id;
                } else {
                    return null;
                }
            }
        },
        methods: {
            hasNewMessage(input) {
                return this.getNewMessagesConversations.find(item => item.conversationId == input);
            },
            newMessageCount(input) {
                let conversation = this.getNewMessagesConversations.find(item => item.conversationId == input);
                return conversation.newMessageCount;
            },
            setActiveConversation(item) {

                if (!this.sidebar) {

                    if (this.activeChat == undefined || this.activeChat != item.id) {
                        let x = {
                            id: item.id,
                            title: item.title
                        };
                        this.$emit('conversationSelected', x);
                    } else if (this.activeChat == item.id) {
                        this.$emit('conversationDeSelected');
                    }
                } else {

                    let x = {
                        id: item.id,
                        title: item.title
                    };
                    this.$emit('conversationSelected', x);
                }
            },
            searchContacts() {

                clearTimeout(this.searchTimeout);

                if (this.contactName != "") {

                    this.searchTimeout = setTimeout(() => {
                        this.loading = true;

                        this.$store.dispatch('adminSearchContactsAJAX', {
                            'name': this.contactName
                        }).then(resp => {
                            this.items = resp.data.contacts;
                        }).finally(() => {
                            this.loading = false;
                        })
                    }, 500);
                }
            }
        },
        data() {
            return {
                selectedContact: null,
                loading: false,
                items: [],
                searchTimeout: null,
                contactName: "",
                searchFocused: false,
                activeChat: null,
                defaultAvatar: require("./../../assets/avatar-default.png")
            }
        },
        watch: {
            contactName(newVal) {
                if (newVal != null) {
                    this.searchContacts();
                }

            },
            selectedContact(newVal) {
                if (newVal != undefined) {
                    this.activeChat = null;
                    this.setActiveConversation({
                        id: this.selectedContact.id,
                        title: this.selectedContact.full_name,
                    });
                }
            }
        }

    }
</script>

<style scoped>

</style>