<template>
    <div>
        <v-row>
            <v-col cols="12" md="4" lg="3" sm="6">
                <v-select v-model="priceToAdd.currency" :items="currencies"
                          item-text="title"
                          item-value="value"
                          outlined label="ارز پرداختی"></v-select>
            </v-col>
            <v-col cols="12" md="4" lg="3" sm="6">
                <v-text-field v-model="priceToAdd.price" outlined label="مبلغ"></v-text-field>
            </v-col>
            <v-col>
                <v-btn @click="addPrice" class="primary mt-3">
                    افزودن
                </v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <template>
                    <v-data-table
                            :headers="headers"
                            :items="prices"
                            :items-per-page="5"
                            class="elevation-1"
                            hide-default-footer
                    >
                        <template v-slot:item.actions="{item}">
                            <v-btn fab x-small color="red white--text">
                                <v-icon small @click="removeItem(item)">
                                    fas fa-trash
                                </v-icon>
                            </v-btn>
                        </template>
                        <template v-slot:item.currency="{item}">
                            {{item.currency | resolveCurrency}}
                        </template>
                    </v-data-table>
                </template>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import {resolveCurrency} from "../../utilities";
    import {mapGetters} from 'vuex';

    export default {
        props: ['allPrices'],
        created() {
            this.prices = this.allPrices;
        },

        data() {
            return {
                prices: [],
                priceToAdd: {
                    currency: null,
                    price: null
                },
                // currencies: [
                //     {title: 'تومان', value: 'rial'},
                //     {title: 'دلار', value: 'dollar'},
                //     {title: 'یورو', value: 'euro'},
                //     {title: 'دلار استرالیا', value: 'aud'},
                //     {title: 'دلار کانادا', value: 'cad'},
                //     {title: 'پوند', value: 'gbp'},
                // ],

                headers: [
                    {text: 'نوع ارز', value: 'currency'},
                    {text: 'مبلغ', value: 'price'},
                    {text: '', value: 'actions'},
                ],
            }
        },
        methods: {
            removeItem(item) {
                this.prices.splice(this.prices.indexOf(item), 1);
                this.$emit('updatePrices', this.prices);
            },
            addPrice() {
                let item = this.prices.find((item) => {

                    if (item.currency == this.priceToAdd.currency) {
                        return item;
                    }

                    return false;
                });
                if (item) {
                    this.removeItem(item);
                }
                this.prices.push({
                    currency: this.priceToAdd.currency,
                    price: Number(this.priceToAdd.price),
                });
                this.priceToAdd.currency = null;
                this.priceToAdd.price = null;
                this.$emit('updatePrices', this.prices);


            },

        },
        computed: {
            ...mapGetters({
                currencies: 'getCurrencies'
            })
        },
        filters: {
            resolveCurrency(input) {
                return resolveCurrency(input);
                // return input == 'rial' ? 'تومان' : 'دلار';
            }
        },
    }
</script>

<style scoped>

</style>