<template>
    <div v-if="rerender">
        <v-dialog
                v-model="editState"
                v-if="itemToEdit!=null"
                max-width="800"
        >
            <changeState @close="closeEditStateForm" @done="finishedEditingState"
                         :itemToEdit="itemToEdit"></changeState>
        </v-dialog>
        <v-dialog
                v-model="showResetDialogSwitch"
                max-width="400px"
        >
            <v-card class="pa-5">
                <v-card-title>از حذف این مورد اطمینان دارید؟</v-card-title>
                <v-card-text>
                </v-card-text>
                <v-card-text></v-card-text>
                <v-card-actions class="">
                    <v-spacer></v-spacer>

                    <v-btn color="primary" @click="showResetDialogSwitch = false">
                        خیر
                    </v-btn>
                    <v-btn class="red white--text elevation-0" @click="resetSession()">
                        بله
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-container>
            <!--<div v-if="this.path == 'dateSessions'">-->
            <!--<v-chip class="ma-2 px-8" v-for="el in dates" :key="el.id" @click="selectDate(el)"-->
            <!--:color="date == el.code ? 'primary': ''">-->
            <!--{{ el.title }}-->
            <!--</v-chip>-->
            <!--</div>-->

            <v-card class="my-8">
                <v-progress-linear
                        indeterminate
                        :active="requesting"
                        color="blue "
                ></v-progress-linear>
                <v-card-text>
                    <TableOptions :hasExport="true"
                                  :searchFilters="searchFilters"
                                  @applyFilters="applyFilters"
                                  :hasSepidarExport="true"
                    ></TableOptions>
                </v-card-text>
                <!--<v-card-title ref="adminSessions">-->
                <!--{{ title }}-->
                <!--<v-spacer></v-spacer>-->
                <!--<v-text-field-->
                <!--solo-->
                <!--v-model="search"-->
                <!--append-icon="mdi-magnify"-->
                <!--label="جستجو"-->
                <!--single-line-->
                <!--hide-details-->
                <!--&gt;</v-text-field>-->
                <!--</v-card-title>-->

                <v-data-table
                        hide-default-footer
                        :headers="headers"
                        :items="sessions"
                        :search="search"
                >
                    <template v-slot:item="{item}">
                        <tr
                                :class="{
                                    'green black--text darken-1' : item.state == 'used',
                                    'orange black--text darken-1' : item.state == 'session_requested',
                                    'orange black--text lighten-2' : item.state == 'doctor_assigned',
                                    'blue black--text lighten-3' : item.state == 'date_fixed'
                                }"
                        >
                            <td>
                                <v-chip >
                                    <a :href="'clients/'+item.user.id" style="text-decoration: none">
                                        {{item.user.full_name}}
                                    </a>
                                </v-chip>

                            </td>
                            <td>
                                <template v-if="item.state == 'date_fixed' || item.state == 'used'">
                                    {{item.fixed_date}}
                                </template>
                                <template v-else>
                                    {{item.request_date}}
                                </template>
                            </td>

                            <td>
                                <template v-if="item.state == 'date_fixed' || item.state == 'used'">
                                    {{item.fixed_time}}
                                </template>
                                <template v-else>
                                    {{item.request_time}}
                                </template>
                            </td>
                            <td>
                                <template v-if="hasCounselor(item)">
                                    {{item.doctor.full_name}}
                                </template>
                            </td>
                            <td style="position:relative">
                                <span class="partially-paid" v-if="item.factorIsPartiallyPaid">پیش پرداخت</span>
                                {{resolveState(item.state)}}
                            </td>
                            <td >
                              {{ item.type | resolveSessionType}}
                            </td>
                            <td>
                                <getSessionLinks :item="item"></getSessionLinks>


                                <v-btn @click="editSessionState(item)" x-small text fab>
                                    <v-icon small>fas fa-edit</v-icon>
                                </v-btn>
                                <v-btn x-small class="primary mx-1" text fab

                                       :to="`assignment/user/${item.user.id}/cycle/${item.cycle.id}/request/${item.id}`">
                                    <v-icon small>fas fa-tv</v-icon>
                                </v-btn>
                                <template v-if="item.state != 'used'">
                                    <v-btn fab x-small class="red white--text elevation-0 mx-1"
                                           @click="showResetDialog(item)">

                                        <v-icon small class="">
                                            fas fa-trash
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <template
                                        v-if="item.state == 'doctor_assigned' || item.state == 'date_fixed' || item.state == 'used'">
                                    <v-btn fab class="elevation-0" x-small @click="showDetails(item)" >
                                        <v-icon small class="mx-2">
                                            fas fa-search
                                        </v-icon>
                                    </v-btn>
                                </template>
                            </td>
                        </tr>
                    </template>

                    <!--<template v-slot:item.actions="{ item }">-->
                    <!---->

                    <!--&lt;!&ndash;<div @click="getClientProfile(item.user.id)" style="cursor: pointer;">&ndash;&gt;-->
                    <!--&lt;!&ndash;{{ item.user.full_name }}&ndash;&gt;-->
                    <!--&lt;!&ndash;</div>&ndash;&gt;-->
                    <!--</template>-->
                </v-data-table>
            </v-card>
            <div class="text-center" v-if="!fiveSession">
                <v-pagination
                        v-model="page"
                        :length="total"
                ></v-pagination>
            </div>
        </v-container>
    </div>
</template>

<script>
    /*eslint-disable*/
    import {mapGetters} from 'vuex';
    import moment from 'moment-jalaali';

    import {EventBus} from "../../event-bus";
    import {
        adminSessionFilters,
        dateSessionFilters,
        adminClientRequestedSessionFilters,
        adminClientUsedSessionFilters
    } from "../../searchFilters";
    import TableOptions from '@/components/TableOptions.vue';
    import ChangeState from '@/components/ChangeState.vue';

    import getSessionLinks from '@/components/getSessionLinks.vue';

    export default {
        components: {
            TableOptions,
            ChangeState,
            getSessionLinks
        },
        props: {
            mode: {
                default: null
            },
            title: {
                default: 'جلسات',
                type: String
            },
            fiveSession: Boolean
        },
        data() {
            return {
                doctors: [],
                itemToEdit: null,
                editState: true,
                counselorStates: [
                    'doctor_assigned',
                    'used',
                    'date_fixed'
                ],
                requesting: false,
                options: {},
                searchFilters: [],
                resetItem: null,
                showResetDialogSwitch: false,
                rerender: false,
                // loading: true,
                search: '',
                headers: [
                    {
                        text: 'نام و نام خانوادگی',
                        align: 'start',
                        sortable: false,
                        value: 'user.full_name',
                    },
                    {text: 'تاریخ', value: 'fixed_date'},
                    {text: 'ساعت', value: 'fixed_time'},
                    {text: 'مشاور', value: 'doctor.full_name'},
                    {text: 'وضعیت', value: 'state'},
                    {text: 'نوع', value: 'type'},
                    {text: 'عملیات', value: 'actions'},
                ],
                desserts: [],
                date: moment().format("YYYY-MM-DD"),
                path: this.$router.currentRoute.name,
                dates: [
                    {
                        id: 1,
                        title: 'امروز',
                        code: moment().format("YYYY-MM-DD")
                    },
                    {
                        id: 2,
                        title: 'فردا',
                        code: moment(new Date()).add(1, 'days').format("YYYY-MM-DD")
                    },

                ],
                page: 1,
                total: 1,
                sessions: []
            }
        },
      filters:{
        resolveSessionType(input) {
          let result='';
          switch (input) {
            case 'session':
              result = 'جلسه'
              break;
            case 'question':
              result = 'سوال'
              break;
            case 'test':
              result = 'تست'
              break;
          }
          return result;

        }
      },
        computed: {
            ...mapGetters({
                allDoctors: 'getAdminAllDoctors'
                // sessions: 'getAdminSessions',
                // page: 'page',
                // total: 'total',
            })
        },
        watch: {
            editState(newVal) {
                if (!newVal) {
                    this.closeEditStateForm();
                }
            },
            '$route': function () {
                this.getAdminSessions();
            },
            page() {
                this.getAdminSessions();
                this.$vuetify.goTo(this.$refs.adminSessions.offsetTop);
            }
        },
        created() {
            this.fillDoctors();
        },
        methods: {

            fillDoctors() {
                let promise = new Promise((resolve, reject) => {
                    if (!this.allDoctors.length) {
                        this.$store.dispatch("getAllDoctors").then(res => {
                            this.doctors = res.data.doctors;
                            resolve();
                        }).catch(err => {
                            console.log(err);
                        });
                    } else {

                        this.doctors = this.allDoctors;
                        resolve();
                    }
                })

                promise.then(() => {
                    let mappedDoctors = this.doctors.map((doctor) => {
                        return {
                            title: doctor.full_name, value: doctor.doctor_profile.id
                        };
                    });
                    adminSessionFilters.find((item) => {
                        if (item.key == 'doctor_ids') {
                            item.options = mappedDoctors;
                        }
                    });
                    if (this.$route.name == 'sessions') {
                        this.searchFilters = adminSessionFilters;
                    } else if (this.$route.name == 'clientDetail') {
                        if (this.mode == 'usedSessions') {
                            this.searchFilters = adminClientUsedSessionFilters;
                        } else if (this.mode == 'requestedSessions') {
                            this.searchFilters = adminClientRequestedSessionFilters;
                        }

                    } else if (this.$route.name == 'dateSessions') {
                        this.searchFilters = dateSessionFilters;
                    }
                    this.rerender = true;
                });
            },
            finishedEditingState() {
                EventBus.$emit('notify', 'green', undefined, 'با موفقیت تغییر یافت');
                this.closeEditStateForm();
                this.getAdminSessions();
            },
            closeEditStateForm() {
                this.itemToEdit = null;
                this.editState = false;
            },
            editSessionState(session) {
                this.itemToEdit = session;
                this.editState = true;
            },
            resolveState(state) {
                switch (state) {
                    case 'used':
                        return 'برگزار شده';
                        break;
                    case 'session_requested':
                        return 'درخواست جلسه';
                        break;
                    case 'doctor_assigned':
                        return 'تخصیص یافته';
                        break;
                    case 'date_fixed':
                        return 'زمان ست شده';
                        break;
                }
            },
            hasCounselor(session) {
                return this.counselorStates.includes(session.state);
            },
            applyFilters(options) {
                this.page = 1;
                this.options = options;
                this.getAdminSessions();
            },
            showDetails(item) {

                this.$router.push({name: 'adminSessionDetails', params: {sessionId: item.id}});
            },
            resetSession() {
                this.$store.dispatch('adminResetSession', this.resetItem.id).then(() => {
                    EventBus.$emit('notify', 'green', undefined, 'با موفقیت حذف شد');
                    this.showResetDialogSwitch = false;
                    this.getAdminSessions();
                })
            },
            showResetDialog(item) {
                this.resetItem = item;
                this.showResetDialogSwitch = true;
            },

            getAdminSessions() {
                EventBus.$emit('setLoading', true);
                if (this.$router.currentRoute.name == 'sessions') {
                    // this.loading = true;
                    // let options = {
                    //     'state': 'date_fixed|used',
                    //     "orderBy": "session_date:desc"
                    // };
                    this.requesting = true;

                    this.$store.dispatch('getAdminSessions', {page: this.page, options: this.options}).then((data) => {
                        // console.log('Admin sessions: ', data);
                        // // this.loading = false;
                        // this.setAdminSessions(data.data);
                        this.sessions = data.data.sessions;
                        this.total = data.data.pagination.total_pages;
                        this.requesting = false;
                    }).finally(() => {
                        EventBus.$emit('setLoading', false);
                    })
                    this.requesting = false;
                } else if (this.$router.currentRoute.name == 'dateSessions') {
                    // this.loading = true;
                    let options = {};
                    this.requesting = true;
                    this.$store.dispatch('getAdminSessions', {
                        options: {
                            from_date: moment().format("YYYY-MM-DD"),
                            to_date: moment().add(1, 'days').format("YYYY-MM-DD"),
                            ...this.options
                        },
                        page: this.page
                    }).then((data) => {
                        this.requesting = false;
                        // console.log('Admin sessions based on date: ', data);
                        // this.setAdminSessions(data.data);
                        // this.total = data.paginate.totalPages;
                        // this.loading = false;
                        this.sessions = data.data.sessions;
                        this.total = data.data.pagination.total_pages;
                    }).finally(() => {
                        EventBus.$emit('setLoading', false);
                    })
                } else if (this.$router.currentRoute.name == 'clientDetail') {
                    // this.loading = true;
                    this.requesting = true;
                    this.$store.dispatch('getAdminSessions', {
                        page: this.page,
                        options: {
                            user_id: this.$route.params.clientId,
                            ...this.options,
                        }
                    }).then((data) => {
                        this.requesting = false;

                        // console.log('Admin client sessions: ', data);
                        // this.setAdminSessions(data.data);
                        // this.total = data.paginate.totalPages;
                        if (!this.options.hasOwnProperty('wantsExcel')) {
                            this.sessions = data.data.sessions;
                            this.total = data.data.pagination.total_pages;
                        } else {
                            delete this.options.wantsExcel;
                        }

                    }).finally(() => {
                        EventBus.$emit('setLoading', false);
                    })
                }
            },
            // nextPage() {
            //     this.$store.dispatch('pageChangeHandle', {value: 'next', page: this.page, url: '/admin/users/list?usertype=customer', commitName: 'setAdminUsers'});
            //     console.log('page', this.page)
            // },
            // previousPage() {
            //     this.$store.dispatch('pageChangeHandle', {value: 'previous', page: this.page, url: '/admin/users/list?usertype=customer', commitName: 'setAdminUsers'});
            //     console.log('page', this.page)
            // },
            // specificPage(page) {
            //     this.$store.commit('SET_SPECIFIC_PAGE', page);
            //     this.$store.dispatch('pageChangeHandle', {value: '', page: page, url: '/admin/users/list?usertype=customer', commitName: 'setAdminUsers'});
            //     console.log('page', page)
            // },
            selectDate(el) {
                this.date = el.code;
                this.$router.push(`${el.code}`);
                // this.loading = true;
                this.getAdminSessions();
            },
            setAdminSessions(data) {
                this.sessions = [];
                data.forEach(session => {
                    this.sessions.push(session);
                    session['date'] = session.jdatetime.split(' ')[0];
                    session['time'] = session.jdatetime.split(' ')[1];
                    session['session'] = session.sessionCount + ' از ' + session.orderCount;
                });
            },
            getClientProfile(id) {
                this.$router.push({name: 'clientDetail', params: {clientId: id}});
            },
        }
    }
</script>

<style >
    .partially-paid {
        font-size: .6em;
        cursor: pointer;
        font-weight: bolder;
        position: absolute;
        right: -5px;
        top: -5px;
        padding: 2px 3px;
        background-color: rgba(230, 0, 0, 1);
        color: white;
        border-radius: 5px;
        z-index: 1;
    }
</style>
