export default {
  packages: {
    types: [
      { title: "جلسات", value: "session" },
      { title: "سوال", value: "question" },
      { title: "تست", value: "test" },
    ],
  },


  

  withDefault(array, defaultKey, objectIteratedKey = "value") {
    if (!array || !defaultKey) return [];

    if (typeof array === Object) array = Object.entires(array);

    return array.map((i) => {
      if (i[objectIteratedKey] === defaultKey) i.default = true;
      return i;
    });
  },
};
