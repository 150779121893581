/*eslint-disable*/
import Vue from 'vue'
import Vuex from 'vuex'
import {ajaxPost, ajaxGet} from '../utilities.js';
import LS from '../LocalStorateAPI';
import {EventBus} from "../event-bus";
import Axios from "axios";

// MODULES
import auth from './modules/auth';
import client from './modules/client';
import counselor from './modules/counselor';
import admin from './modules/admin';


Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        client,
        counselor,
        admin,
        auth
    },
    state: {
        // currencies: [
        //     {title: 'تومان', value: 'rial'},
        //     {title: 'دلار', value: 'dollar'},
        //     {title: 'یورو', value: 'euro'},
        //     {title: 'دلار استرالیا', value: 'aud'},
        //     {title: 'دلار کانادا', value: 'cad'},
        //     {title: 'پوند', value: 'gbp'},
        // ],
        errors: {},
        lockLoading: false,
        inviter: LS.get('inviter'),
        preflight: LS.get('preflight'),
        minHeight: window.innerHeight,
        platform: LS.get('platform'),
        loading: false,
        bcCode: localStorage.getItem('bcCode'),
        snackbar: {
            show: false,
            type: 'success'
        },
        currency: null,
        user: {
            isLoggedIn: localStorage.getItem('isLoggedIn'),
            role: localStorage.getItem('role')
        },
        localization: {
            default: 'fa',
            languages: [
                {
                    title: 'english',
                    shortCode: 'en'
                },
                {
                    title: 'فارسی',
                    shortCode: 'fa'
                }
            ],
            languageCodes: [
                'fa', 'en'
            ],
            local: localStorage.getItem('local') ? localStorage.getItem('local') : 'fa',
            direction: localStorage.getItem('direction') ? localStorage.getItem('direction') : 'rtl'
        },
        baseUrl: "https://api.simiaroom.com/api/v1",
        userInfo: JSON.parse(localStorage.getItem('profile')),
        messages: [],
        activeConversationId: null,
        activeChatUser: {
            id: null,
            title: null
        },
        newMessages: LS.get('newMessages') || [],
        newMessageCount: LS.get('newMessageCount') || 0,
        scrollToBottom: false,

    },
    mutations: {
        SET_SCROLL_FALSE(state) {
            state.scrollToBottom = false;
        },
        SET_NEW_MESSAGE(state, payload) {
            let conversation = state.newMessages.find(item => item.conversationId == payload.conversation_id);
            if (conversation != undefined && conversation != null) {
                conversation.newMessageCount++;
            } else {
                state.newMessages.push({
                    conversationId: payload.conversation_id,
                    newMessageCount: 1
                })
            }

            state.newMessageCount++;
            LS.set('newMessages', state.newMessages);
            LS.set('newMessageCount', state.newMessageCount);
        },

        SET_MESSAGES(state, data) {
            state.activeConversationId = data.conversationId;
            state.messages = data.messages;
        },
        SET_ACTIVE_CHAT(state, data) {
            state.activeChatUser.id = data.id;
            state.activeChatUser.title = data.title;
        },
        PREPEND_MESSAGE(state, message) {
            console.log(message);
            state.messages.push(message);
            state.scrollToBottom = true;
        },
        setInviter(state, inviterCode) {
            state.inviter = inviterCode;
            LS.set('inviter', inviterCode);
        },
        removeInviter(state) {
            state.inviter = null;
            LS.remove('inviter');
        },
        setPreflight(state, preflight) {
            state.preflight = preflight;
            LS.set('preflight', preflight);
            Axios.defaults.headers["X-PRE-FLIGHT"] = preflight;
        },
        setAlertErrors(state, errors) {
            state.errors = errors;
        },
        setMinHeight(state) {
            state.minHeight = window.innerHeight;
        },
        setPlatformData(state, platform) {
            state.platform = platform;
            LS.set('platform', platform);
        },
        changeLocal(state, lang) {
            state.localization.local = lang;

            if (lang == 'fa') {
                LS.set('direction', 'rtl');
                // state.localization.isRTL = true;
                state.localization.direction = 'rtl';
                console.log('here in  rtl ');
            } else {
                localStorage.setItem('direction', 'ltr');
                // state.localization.isRTL = false;
                state.localization.direction = 'ltr';
                console.log('here in  ltr');
            }

            localStorage.setItem('local', lang);
            EventBus.$emit('languageIsChanged');
        },
        setLoading(state, value) {

            if (!state.lockLoading) {
                state.lockLoading = true;
                state.loading = value;
                setTimeout(() => {
                    state.lockLoading = false;
                }, 200);
            }
        },
        setSnackbar(state, obj) {
            state.snackbar = obj;
            console.log('snackbar', state.snackbar);
        },

        setUserInfo(state, info) {
            state.userInfo = info;
            state.bcCode = info.bc_code;

            state.newMessages = info.newMessages;
            let newMessageCount = 0;
            info.newMessages.forEach((item) => {
                newMessageCount += item.newMessageCount;
            });
            state.newMessageCount = newMessageCount;
            LS.set('newMessageCount', newMessageCount);
            LS.set('newMessages', info.newMessages);

            LS.set('bcCode', info.bc_code);
            LS.set('profile', info);
        },

        UPDATE_NEW_MESSAGES(state, newMessages) {
            state.newMessages = newMessages;
            let newMessageCount = 0;
            newMessages.forEach((item) => {
                newMessageCount += item.newMessageCount;
            });
            state.newMessageCount = newMessageCount;
            LS.set('newMessageCount', newMessageCount);
            LS.set('newMessages', newMessages);
        },
        resetUserInfo(state) {
            state.userInfo = {};
        },
        // setOrders(state, orders) {
        //     state.orders = [];
        //     orders.forEach(order => {
        //         state.orders.push(order);
        //     });
        // },
        // setProductList(state, productList) {
        //     LS.set('productList', productList);
        //     state.productList = productList;
        // },
        //
        // addItemToCart: (state, item) => {
        //     for (let i = 0; i < state.cartItems.length; i++) {
        //         if (state.cartItems[i].title === item.title) {
        //             state.cartItems[i].qty++;
        //             return;
        //         }
        //     }
        //     state.cartItems.push({...item, qty: 1});
        // },
        // increaseQuantity: (state, item) => {
        //     item.qty++;
        // },
        // decreaseQuantity: (state, item) => {
        //     if (item.qty > 0) {
        //         item.qty--;
        //     }
        // },
        // removeItem: (state, item) => {
        //     let cart = state.cartItems;
        //     cart.splice([cart.indexOf(item)], 1);
        // },
        // calculateSum: (state, items) => {
        //     let sum = 0;
        //     items.forEach(item => {
        //         sum += item.price * item.qty;
        //     });
        //     state.sumPrice = sum;
        // },
        // // -----------------------------------------sessions----------------------------------------- //
        // 'SET_SESSIONS'(state, sessions) {
        //     state.sessions = [];
        //     sessions.forEach(session => {
        //         state.sessions.push(session);
        //     });
        // },
        // 'SET_NEXT_SESSION'(state, obj) {
        //     state.nextSession = obj
        // },
        // 'EMPTY_SESSIONS'(state) {
        //     state.sessions = [];
        // },
        // 'SET_PAGINATE'(state, paginate) {
        //     state.totalPages = paginate.totalPages;
        //     state.limit = paginate.limit;
        //     console.log('success')
        // },
        // 'SET_NEXT_PAGE'(state) {
        //     state.page += 1;
        //     console.log(state.page);
        // },
        // 'SET_PREVIOUS_PAGE'(state) {
        //     state.page -= 1;
        // },
        // 'SET_SPECIFIC_PAGE'(state, page) {
        //     state.page = page;
        // }
        // -----------------------------------------sessions----------------------------------------- //
    },
    actions: {
        gotMessage({state, commit}, payload) {
            let parsed = JSON.parse(payload.payload);
            if (parsed.data.message.conversation_id == state.activeConversationId) {
                commit('PREPEND_MESSAGE', parsed.data.message);
                payload.socket.client.emit('message_read', {messageId: parsed.data.message.id})
            } else {
                commit('SET_NEW_MESSAGE', parsed.data.message);
            }
            // commit('SET_MESSAGES',payload);
        },
        setInviter({commit}, inviterCode) {
            commit('setInviter', inviterCode);
        },
        removeInviter({commit}) {
            commit('removeInviter');
        },
        getPlatformInfo({commit, state}) {
            return ajaxGet(state.baseUrl + "/platformInfo").then((data) => {
                commit('setPlatformData', data.data.platform);
                state.currency = data.data.currency;
            })
        },
        getCountries({state}) {
            return ajaxGet(state.baseUrl + "/countries", true).then((data) => {
                return data;
            })
        },
        changeLocal({commit}, lang) {
            return new Promise((resolve) => {
                commit('changeLocal', lang);
                resolve(true);
            });
        },
        checkLogin({commit, state, getters}) {
            return ajaxGet(state.baseUrl + "/checkLogin" + '?preflight=' + getters.getPreflight).then((resp) => {
                commit('setPreflight', resp.data.preflight);

            }).catch((err) => {
                commit('setPreflight', err.response.data.data.preflight);
            });
            // .then(resp => {
            //     // if (resp.statusCode && resp.statusCode != 200) {
            //     //     localStorage.removeItem('loggedIn');
            //     //     // commit('resetUserInfo')
            //     // }
            //     // console.log('logged in')
            //     // commit('setUser', {isLoggedIn: true, role: 'customer'});
            //     // commit('setUserInfo', resp.data);
            // })
            // .catch(err => {
            //     // console.log('not logged in');
            //     localStorage.removeItem('loggedIn');
            // });
        },
        // login({commit, state, dispatch}, payload) {
        //     return new Promise((resolve, reject) => {
        //         ajaxPost(state.baseUrl + "/users/login", payload, false).then(({data}) => {
        //
        //             this.dispatch('getUserInfo').then((resp) => {
        //                 commit('setUser', data);
        //                 resolve();
        //             })
        //         }).catch((err) => {
        //             reject(err)
        //         })
        //     });
        // },
        register({commit, state}, payload) {
            return new Promise((resolve, reject) => {
                ajaxPost(state.baseUrl + "/register", payload, false).then(data => {
                    console.log('register', data)
                    resolve(data);
                }).catch(err => {
                    reject(err);
                })
            })
        },
        emailVerify({commit, state}, payload) {
            return new Promise((resolve, reject) => {
                ajaxGet(state.baseUrl + `/verify/${payload}`).then((data) => {
                    console.log('emailverify', data);
                    resolve(data);
                })
            })
        },
        resetPassword({commit, state}, payload) {
            return new Promise((resolve, reject) => {
                ajaxPost(state.baseUrl + "/forgotPassword", payload, false).then((data) => {
                    resolve(data);
                })
            })
        },
        logOut: ({commit, state}) => {
            return new Promise((resolve) => {
                EventBus.$emit('disconnectSocket', state.bcCode);
                state.auth.isLoggedIn = false;
                state.auth.loggedIn = false;
                state.bcCode = null;
                state.userInfo = {};
                client.state.activeCycle = null;
                delete Axios.defaults.headers.common["Authorization"];
                // localStorage.setItem('isLoggedIn', false);
                // localStorage.setItem('profile', {});
                // localStorage.clear();
                LS.remove([
                    'isLoggedIn',
                    'role',
                    'profile',
                    'loggedIn',
                    'token',
                    'activeCycle',
                    'allDoctors',
                    'bcCode',
                    'adminAllClients'
                ]);
                resolve();
            });


        },
        getUserInfo: ({commit, state, dispatch}) => {
            // commit('setLoading', true);
            return new Promise((resolve, reject) => {
                ajaxGet(state.baseUrl + "/user/profile").then(({data}) => {

                    EventBus.$emit('resetSocket', data.profile.bc_code);
                    commit('setUser', {isLoggedIn: true, role: data.profile.role});
                    commit('setUserInfo', data.profile);
                    // localStorage.setItem('profile', data);
                    // commit('setLoading', false);

                    resolve(data);
                })
            })
        },
        saveCycleTimes({commit}, data) {
            return ajaxPost(this.state.baseUrl + "/admin/sessions/request/assign", data);

        },
        saveUserInfo: ({commit, state}, payload) => {
            return new Promise((resolve, reject) => {
                ajaxPost(state.baseUrl + "/user/profile", payload, true).then(({data}) => {
                    resolve();
                }).catch((err) => {
                    reject(err);
                })
            })
        },
        joinG2M: ({commit, state}, sessionId) => {
            return ajaxGet(state.baseUrl + "/sessions/joinG2M/" + sessionId, true).then((data) => {
                console.log('G2M', data);
                return data;
            })
        },
        joinBBB: ({commit, state}, sessionId) => {
            return ajaxGet(state.baseUrl + "/sessions/join/" + sessionId, true).then((data) => {
                console.log('BBB', data);
                return data;
            })
        },

        // getFinancialList({commit, state}) {
        //     return new Promise((resolve, reject) => {
        //         ajaxGet(state.baseUrl + "/users/order/list", true).then(resp => {
        //             console.log('orders', resp);
        //             const orders = resp.data;
        //             commit('SET_PAGINATE', resp.paginate);
        //             commit('setOrders', orders);
        //             resolve(orders);
        //         })
        //     })
        // },
        // getProductList: ({commit, state}, payload) => {
        //     return new Promise((resolve, reject) => {
        //         ajaxGet(state.baseUrl + "/orders/productlist", true).then(({data}) => {
        //             console.log('productList', data);
        //             commit('setProductList', data);
        //             resolve(data);
        //         })
        //     })
        // },
        // addToCart: ({commit, state}, payload) => {
        //     commit('addItemToCart', payload);
        // },
        // increaseQuantity: ({commit, state}, payload) => {
        //     commit('increaseQuantity', payload);
        // },
        // decreaseQuantity: ({commit, state}, payload) => {
        //     commit('decreaseQuantity', payload);
        // },
        // removeItem: ({commit, state}, payload) => {
        //     commit('removeItem', payload);
        // },
        // calculateSum: ({commit, state}, items) => {
        //     commit('calculateSum', items);
        // },
        // validateVoucher: ({commit, state}, data) => {
        //     return new Promise((resolve, reject) => {
        //         ajaxPost(state.baseUrl + "/orders/voucher", data).then(resp => {
        //             if (resp.statusCode && resp.statusCode != 200) {
        //                 reject(resp);
        //             }
        //             resolve(resp);
        //             console.log(resp)
        //         }).catch(err => {
        //             if (err.response.status == 401) {
        //                 console.log('user is not logged in ');
        //                 this.dispatch('logOut');
        //             }
        //             reject(err);
        //         });
        //     })
        // },
        // // -----------------------------------------sessions----------------------------------------- //
        // getNextSession: ({commit, state}) => {
        //     return ajaxGet(state.baseUrl + "/sessions/next", true).then((data) => {
        //         return data;
        //     })
        // },
        // getSessions: ({commit, state}, data) => {
        //     return ajaxGet(state.baseUrl + "/sessions/history?page=" + data.page, true).then((data) => {
        //         return data;
        //     })
        // },
        // // pageChangeHandle: ({commit, state}, {value, page}) => {
        // //     return new Promise((resolve, reject) => {
        // //         ajaxGet(state.baseUrl + `/sessions/history?page=${page}`).then(({data, paginate}) => {
        // //             console.log('here', paginate);
        // //             commit('SET_SESSIONS', data);
        // //             commit('SET_PAGINATE', paginate);
        // //             console.log('next', state.sessions);
        // //             resolve();
        // //         })
        // //     })
        // // },
        //
        // pageChangeHandle: ({commit, state}, {value, page, url, commitName}) => {
        //     return new Promise((resolve, reject) => {
        //         ajaxGet(state.baseUrl + `${url}?page=${page}`, true).then(({data, paginate}) => {
        //             console.log('here', paginate);
        //             commit(commitName, data);
        //             commit('SET_PAGINATE', paginate);
        //             console.log('next', data);
        //             resolve();
        //         })
        //     })
        // },
        // pageChangeHandleWithType: ({commit, state}, {value, page, url, commitName, usertype}) => {
        //     return new Promise((resolve, reject) => {
        //         ajaxGet(state.baseUrl + `${url}?page=${page + usertype}`, true).then(({data, paginate}) => {
        //             console.log('here', paginate);
        //             commit(commitName, data);
        //             commit('SET_PAGINATE', paginate);
        //             console.log('next', data);
        //             resolve();
        //         })
        //     })
        // },
        // pageChangeHandleWithStatus: ({commit, state}, {value, page, url, commitName, status}) => {
        //     return new Promise((resolve, reject) => {
        //         ajaxGet(state.baseUrl + `${url}?page=${page + status}`, true).then(({data, paginate}) => {
        //             console.log('here', paginate);
        //             commit(commitName, data);
        //             commit('SET_PAGINATE', paginate);
        //             console.log('next', data);
        //             resolve();
        //         })
        //     })
        // },
        //
        // getStartSessionForClient: ({commit, state}, sessionId) => {
        //     return ajaxGet(state.baseUrl + "/sessions/start/" + `${sessionId}`, true).then(data => {
        //        return data;
        //     })
        // },
        // // -----------------------------------------sessions----------------------------------------- //
        //
        // sendOrder({commit, state}, order) {
        //     return new Promise((resolve, reject) => {
        //         ajaxPost(state.baseUrl + "/orders/payment", order).then(({data}) => {
        //             window.location.href = data;
        //             resolve(data);
        //         })
        //     })
        // },
        // postConsultInfo({commit, state}, consultInfo) {
        //     return new Promise((resolve, reject) => {
        //         Axios.post(state.baseUrl + "/users/savetimebooks", consultInfo, {
        //             headers: {
        //                 Authorization: "Bearer " + (localStorage.getItem("token") || "")
        //             }
        //         })
        //             .then(resp => {
        //                 if (resp.statusCode && resp.statusCode != 200) {
        //                     reject(resp);
        //                 }
        //                 resolve(resp);
        //             })
        //             .catch(err => {
        //                 reject(err);
        //             });
        //     })
        // },
        // postConsultationInfo({commit, state}, problem) {
        //     return new Promise((resolve, reject) => {
        //         ajaxPost(state.baseUrl + "/users/consultinfo", problem).then((data) => {
        //             console.log(data);
        //             resolve(data);
        //         })
        //     })
        // },
        // assignConsult({commit, state}) {
        //     commit('setLoading', true);
        //     return new Promise((resolve, reject) => {
        //         ajaxGet(state.baseUrl + "/users/consulthas", true).then((data) => {
        //             commit('setLoading', false);
        //             resolve(data);
        //         })
        //     })
        // },
        // getCandidateCounselor({commit, state}) {
        //     return new Promise((resolve, reject) => {
        //         ajaxGet(state.baseUrl + "/sessions/candidate", true).then((data) => {
        //             resolve(data);
        //         })
        //     })
        // },
        // saveRequest({commit, state}, request) {
        //     return new Promise((resolve, reject) => {
        //         ajaxPost(state.baseUrl + "/users/saverequest", request).then((data) => {
        //             console.log(data);
        //             resolve(data);
        //         })
        //     })
        // },
        // postSessionsBook({commit, state}, sessionsBook) {
        //     return new Promise((resolve, reject) => {
        //         ajaxPost(state.baseUrl + "/sessions/book", sessionsBook).then((data) => {
        //             console.log(data);
        //             resolve(data);
        //         })
        //     })
        // },

    },
    getters: {
        // getCurrencies:state => state.currencies,
        getInviter: state => state.inviter,
        getPreflight: state => state.preflight,
        getErrors: state => state.errors,
        getPlatform: state => state.platform,
        loading: state => state.loading,
        snackbar: state => state.snackbar,
        userIsLoggedIn: state => state.auth.loggedIn,
        getLocal: state => state.localization.local,
        getCurrentLanguage: state => state.localization.default,
        isRTL: state => state.localization.direction == 'rtl',
        getDirection: state => state.localization.direction,
        getSupportedLanguages: state => state.localization.languages,
        userHasRole: (state) => (role) => {
            if (role != undefined) {
                return state.auth.role == role ? true : false;
            } else {
                return false;
            }
        },
        userHasRolesArray: (state) => roles => {
            if (roles != undefined) {
                return roles.includes(state.auth.role);
            } else {
                return true;
            }
        },
        getUserInfo: (state) => {
            return state.userInfo
        },
        getUserCurrency: state => state.currency,
        getMinHeight: state => state.minHeight,
        getBaseUrl: state => state.baseUrl,
        getBcCode: state => state.bcCode,
        getMessages: state => state.messages,
        getConversationId: state => state.activeConversationId,
        getActiveChat: state => state.activeChatUser,
        getNewMessageCount: state => state.newMessageCount,
        getNewMessagesConversations: state => state.newMessages,
        getScrollToBottom: state => state.scrollToBottom,
        // getOrders: state => state.orders,
        // getProductList: state => state.productList,
        // getCartItems: state => state.cartItems,
        // getSumPrice: state => state.sumPrice,
        // -----------------------------------------sessions----------------------------------------- //
        // sessions: state => state.sessions,
        // nextSession: state => state.nextSession,
        // page: state => state.page,
        // total: state => state.totalPages
        // -----------------------------------------sessions----------------------------------------- //
    }
})
