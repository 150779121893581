<template>
    <v-stepper v-model="step">
        <v-stepper-header>
            <v-stepper-step
                    :complete="step > 1"
                    step="1"
                    editable
            >
                ثبت کاربر
            </v-stepper-step>

            <v-divider></v-divider>
            <v-stepper-step
                    :complete="step > 2"
                    step="2"
            >
                ثبت رکورد مالی
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step
                    :complete="step > 3"
                    step="3"
            >
                ثبت جلسه
            </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
            <v-stepper-content step="1">
                <AdminClientExpressEntryFirstStep
                        :clients="clients"
                        :loadingClients="loadingClients"
                        @userSelected="saveUserId"
                        @newUser="getAllClients"></AdminClientExpressEntryFirstStep>
            </v-stepper-content>
            <v-stepper-content step="2">
                <AdminClientExpressEntrySecondStep @done="paymentDone"
                                                   :clients="clients"
                                                   v-if="clients.length"
                                                   :mode="'embed'"
                                                   :userId="user_id"></AdminClientExpressEntrySecondStep>
            </v-stepper-content>

        </v-stepper-items>
        <v-stepper-content step="3">
            <AdminClientExpressEntryThirdStep @done="sessionAdded"
                                              @getProfile="getClientProfile"
                                              v-if="profile!=null"
                                              :profile="profile"
                                              :userId="user_id"></AdminClientExpressEntryThirdStep>
        </v-stepper-content>
    </v-stepper>
</template>

<script>

    import {mapGetters} from 'vuex';
    import AdminClientExpressEntryFirstStep
        from '@/components/Admin/user/expressEntrySteps/AdminClientExpressEntryFirstStep'
    import AdminClientExpressEntrySecondStep
        from '@/views/admin/AdminFinancialAddNewPayment'
    import AdminClientExpressEntryThirdStep
        from '@/components/Admin/user/expressEntrySteps/AdminClientExpressEntryThirdStep'
    import moment from 'moment-timezone';

    export default {
        computed: {
            ...mapGetters({
                allClients: 'getAdminAllClients'
            })
        },
        components: {
            AdminClientExpressEntryFirstStep,
            AdminClientExpressEntrySecondStep,
            AdminClientExpressEntryThirdStep
        },
        created() {
            this.getAllClients();
        },
        methods: {
            getAllClients() {
                this.loadingClients=true;
                this.$store.dispatch("getAllPatients").then(res => {

                    this.clients = res.data.clients;
                    this.loadingClients = false;
                }).catch(err => {
                    this.loadingClients = false;
                    console.log(err);
                }).finally(() => {
                    this.loadingClients=false
                });

                // return new Promise((resolve) => {
                //     if (!this.allClients.length) {
                //         this.$store.dispatch("getAllPatients").then(res => {
                //             this.doctors = res.data.clients;
                //             resolve();
                //         }).catch(err => {
                //             console.log(err);
                //         });
                //     } else {
                //         this.clients = this.allClients;
                //         resolve();
                //     }
                // })

            },
            getClientProfile() {
                this.$store.dispatch('getUserProfile', {
                    userid: this.user_id
                }).then((data) => {

                    this.profile = data.data.client;
                    this.profile.birthDate = moment(this.profile.birthDate, 'YYYY-MM-DD').format('YYYY-MM-DD');
                })
            },
            sessionAdded() {
            },
            saveUserId(id) {
                this.user_id = id;
                this.getAllClients();
                this.step++;
            },
            paymentDone() {
                this.step++;
            }
        },
        data() {
            return {
                clients: [],
                newUser: false,
                step: 1,
                user_id: null,
                profile: null
            }
        },
        watch: {
            user_id() {
                this.getClientProfile();
            }
        }
    }
</script>

<style scoped>

</style>