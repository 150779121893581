<template>
    <v-container>
        <v-card class="my-8">

            <v-card-title ref="adminReports">
                {{title}}
                <v-spacer></v-spacer>
            </v-card-title>
            <v-card-text>
                <v-data-table
                        hide-default-footer
                        :headers="headers"
                        :items="reports"
                        mobile-breakpoint="300"
                >
                    <template v-slot:item.name="{ item }">
                        <v-chip @click="downloadReport(item)" style="cursor: pointer;">
                            {{ item.name}}
                        </v-chip>
                    </template>

                    <template v-slot:item.actions="{ item }">
                        <v-btn class="my-2 elevation-1" color="primary" @click="downloadReport(item)" fab small
                               v-if="!isDownloading(item)">
                            <v-icon small>fas fa-download</v-icon>
                        </v-btn>
                        <div style="min-width:50px" v-else>

                            <v-progress-linear
                                    v-model="getDownloading(item).value"
                                    query
                            ></v-progress-linear>

                        </div>
                    </template>

                </v-data-table>
            </v-card-text>
        </v-card>
        <div class="text-center">
            <v-pagination
                    v-model="page"
                    :length="total"
            ></v-pagination>
        </div>
    </v-container>
</template>

<script>
    /*eslint-disable*/

    import {EventBus} from "../../../event-bus";
    import FileDownload from 'js-file-download';
    import Axios from 'axios';

    export default {
        created() {
            this.getAdminReports();
        },
        props: {
            getReportAction: {
                default: 'adminSepidarExports'
            },
            title: {
                default: 'گزارشات مالی'
            },
            type: {
                default: 'payment'
            }
        },

        data() {
            return {
                downloading: [],

                requesting: false,

                headers: [
                    {
                        text: 'نام گزارش',
                        align: 'start',
                        sortable: true,
                        value: 'name',
                    }, {
                        text: 'از تاریخ',
                        align: 'center',
                        sortable: true,
                        value: 'from_date',
                    }, {
                        text: 'تا تاریخ',
                        align: 'center',
                        sortable: true,
                        value: 'to_date',
                    },
                    {
                        text: 'تاریخ گزارش',
                        align: 'center',
                        sortable: true,
                        value: 'generated_at',
                    },
                    {
                        align: 'center',
                        sortable: false,
                        value: 'actions',
                    }
                ],
                reports: [],
                page: 1,
                total: 1
            }
        },
        methods: {
            getDownloading(item) {
                return this.downloading.find(downloadItem => downloadItem.id == item.id);
            },
            removeFromDownloading(item) {

                this.downloading.splice(this.downloading.indexOf(item), 1)
                // let item = this.downloading.find(item => item.id == id);
                // if (item != undefined) {
                //
                // }
            },
            isDownloading(item) {
                let hasItem = this.downloading.find(downloadItem => downloadItem.id == item.id)
                if (hasItem != undefined) {
                    return true;
                }
                return false;
            },
            ajaxDownload(url, filename, downloadItem) {

                try {
                    return new Promise((resolve, reject) => {

                        Axios({
                            method: 'GET',
                            url,
                            withCredentials: true,
                            headers: {
                                Authorization: this.$store.getters.getAccessToken
                            },
                            responseType: 'blob',
                            onDownloadProgress: (progress) => {
                                downloadItem.value = parseInt(Math.round((progress.loaded / progress.total) * 100))
                            }
                        }).then(resp => {
                            FileDownload(resp.data, filename);
                            resolve();
                        }).catch((err) => {
                            if (!url.includes('checkLogin')) {
                                // EventBus.$emit('notify', 'red', undefined, err.response.data.message);
                            }
                            reject(err);
                        }).finally(() => {
                            this.removeFromDownloading(downloadItem);
                            // store.commit('setLoading', false);
                        });
                    });
                } catch (err) {
                    // EventBus.$emit('notify', 'red', undefined, 'خطایی رخ داده است');
                    // store.commit('setLoading', false);
                }
            },
            getAdminReports() {
                EventBus.$emit('setLoading', true);
                this.$store.dispatch(this.getReportAction, {
                    page: this.page,
                    type: this.type
                }).then((resp) => {
                    this.total = resp.data.pagination.total_pages;
                    this.reports = resp.data.reports;
                    // this.loading = false;
                }).finally(() => {
                    EventBus.$emit('setLoading', false);
                })

            },

            downloadReport(item) {
                let x = this.downloading.push({
                    id: item.id,
                    state: true,
                    value: 0
                });

                this.ajaxDownload('https://api.simiaroom.com/api/v1/admin/reports/' + item.id, item.name, this.downloading[x - 1]);
            },

        },
        watch: {
            page() {
                this.getAdminReports();
                this.$vuetify.goTo(this.$refs.adminReports.offsetTop);
            }
        }
    }

</script>

<style scoped>

</style>