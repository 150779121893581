<template>
    <div class="alertstransition">
        <v-alert type="error" dense v-if="Object.keys(errors).length" class="alertstransition">
            <template v-for="(keyErrors) in errors">
                <v-fade-transition :key="keyErrors">
                    <v-list-item
                            v-for="err in keyErrors" :key="err" dense
                    >
                        <v-list-item-content dense>
                            {{err}}
                        </v-list-item-content>
                    </v-list-item>
                </v-fade-transition>

            </template>
        </v-alert>
    </div>
</template>

<script>
    /*eslint-disable*/
    import {mapGetters} from 'vuex';

    export default {
        methods: {
            translateError(error) {
                return this.$vuetify.lang.t('errorCode_' + error);
            }
        },
        computed: {
            ...mapGetters({
                errors: 'getErrors'
            })
        }

    }
</script>

<style scoped>
    .alertstransition {
        -webkit-transition: all .3s ease-in-out;
        -moz-transition: all .3s ease-in-out;
        -ms-transition: all .3s ease-in-out;
        -o-transition: all .3s ease-in-out;
        transition: all .3s ease-in-out;
    }
</style>