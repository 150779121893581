<template>
    <div>
        <v-dialog v-model="showDialog" max-width="400">
            <v-card v-if="itemToChange != null">
                <v-divider></v-divider>
                <v-card-title>
                    {{action == 'reject'? 'لغو درخواست' : 'تایید درخواست'}}
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="pa-5">
                    آیا نسبت به عملیات مورد نظر اطمینان دارید؟
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="error elevation-0" text @click="showDialog = false">
                        خیر
                    </v-btn>
                    <v-btn class="primary elevation-0" text @click="refundPayment">
                        بله
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-container fluid>
            <v-card class="my-8">
                <v-card-title>
                    درخواست های بازپرداخت
                </v-card-title>
                <v-card-text>
                    <TableOptions :hasExport="true" :searchFilters="searchFilters"
                                  prefix="financial"
                                  @setOrderAndDesc="setOrder"
                                  @applyFilters="applyFilters"></TableOptions>
                </v-card-text>
                <v-data-table
                        hide-default-footer
                        :headers="headers"
                        :items="orders"
                        :sort-by.sync="sortBy"
                        :sort-desc.sync="sortDesc"
                        :search="search"
                        :custom-sort="customSort"
                        single-expand
                        :expanded.sync="expanded"
                        show-expand
                >
                    <template v-slot:expanded-item="{ headers, item }">


                        <td :colspan="headers.length" class="pa-0 ma-0">

                            <v-data-table hide-default-footer
                                          dense
                                          :headers="parentHeaders"
                                          :items="[item.parent]"
                            >
                                <template v-slot:item="{item}">
                                    <tr>
                                        <td>{{item.transaction_code}}</td>
                                        <td>{{item.user}}</td>
                                        <td>{{item.email}}</td>
                                        <td>{{item.mobile}}</td>
                                        <td>{{item.date}}</td>
                                        <td>{{item.time}}</td>
                                        <td>{{item.method ? item.method.title:''}}</td>
                                        <td class="px-0" style="border: none;direction: ltr;">
                                            <div class="text--secondary"
                                                 v-for="(el) in item.packages" :key="el.id">
                                                {{ el.title }}
                                                <v-icon x-small>mdi-close</v-icon>
                                                {{el.count}}
                                            </div>
                                        </td>
                                        <td>{{item.currency}}</td>
                                        <td>
                                            <!--<template v-if="item.currency=='دلار'">-->
                                                <!--{{item.final_amount}}-->
                                            <!--</template>-->
                                            <!--<template v-else>-->
                                                {{Number(item.final_amount).toLocaleString()}}
                                            <!--</template>-->
                                        </td>
                                        <td>{{item.actions}}</td>
                                    </tr>
                                </template>
                            </v-data-table>
                            <!--<PriceComponentTable :allPrices="item.prices"></PriceComponentTable>-->

                        </td>


                    </template>
                    <!--<template v-slot:header.date="{header}">-->
                    <!--<v-btn @click="applyHeader(header)" class="elevation-0" small text>تاریخ</v-btn>-->
                    <!--</template>-->
                    <template v-slot:item.user="{ item }">
                        <v-chip @click="getClientProfile(item.user_id)">{{ item.user }}</v-chip>
                    </template>

                    <template v-slot:item.actions="{ item }">
                        <v-btn x-small fab class="green mx-1" @click="acceptPayment(item)">
                            <v-icon x-small class="white--text">fas fa-check</v-icon>
                        </v-btn>
                        <v-btn x-small fab class="red mx-1">
                            <v-icon x-small class="white--text" @click="rejectPayment(item)">fas fa-trash</v-icon>
                        </v-btn>
                    </template>

                    <template v-slot:item.packages="{ item }">

                        <td class="px-0" style="border: none;">
                            <div class="text--secondary"
                                 v-for="(el) in item.packages" :key="el.id">
                                {{ el.title }}
                                <v-icon x-small>mdi-close</v-icon>
                                {{el.count}}
                            </div>
                        </td>
                    </template>

                    <template v-slot:item.final_amount="{item}">
                        <!--<template v-if="item.currency=='دلار'">-->
                            <!--{{item.final_amount}}-->
                        <!--</template>-->
                        <!--<template v-else>-->
                            {{Number(item.final_amount).toLocaleString()}}
                        <!--</template>-->
                    </template>

                </v-data-table>
            </v-card>
            <div class="text-center" v-if="!fiveSession">
                <v-pagination
                        v-model="page"
                        :length="total"
                ></v-pagination>
            </div>
        </v-container>
    </div>
</template>

<script>
    /*eslint-disable*/
    import {mapGetters} from 'vuex'
    import {adminFinancialRefundsFilters} from "../../../searchFilters";
    import TableOptions from '@/components/TableOptions.vue';
    import {EventBus} from "../../../event-bus";


    export default {
        components: {
            TableOptions
        },
        props: {
            title: {
                default: 'سفارشات',
                type: String
            },
            fiveSession: Boolean
        },
        data() {
            return {
                parentHeaders: [
                    {
                        text: 'شماره سفارش', align: 'start', sortable: false, value: 'transaction_code',
                    },
                    {text: 'مراجعه کننده', value: 'user'},
                    {text: 'ایمیل', value: 'email'},
                    {text: 'موبایل', value: 'mobile'},
                    {text: 'تاریخ خرید', value: 'date'},
                    {text: 'ساعت خرید', value: 'time'},
                    {text: 'متد', value: 'method.title'},
                    {text: 'محصول', value: 'packages'},
                    {text: 'ارز', value: 'currency'},
                    {text: 'مبلغ پرداختی', value: 'final_amount'},

                ],
                expanded: [],
                action: null,
                itemToChange: null,
                showDialog: false,
                lockHeaders: false,
                sortBy: [],
                sortDesc: [],
                options: {},
                searchFilters: adminFinancialRefundsFilters,
                // loading: true,
                search: '',
                headers: [

                    {text: 'مراجعه کننده', value: 'user'},
                    {text: 'ایمیل', value: 'email'},
                    {text: 'موبایل', value: 'mobile'},
                    {text: 'تاریخ درخواست', value: 'date'},
                    {text: 'ساعت درخواست', value: 'time'},
                    {text: 'ارز', value: 'currency'},
                    {text: 'مبلغ بازپرداختی', value: 'final_amount'},
                    {text: 'تعداد جلسات', value: 'refund_session_count'},
                    {text: 'عملیات', value: 'actions'},
                ],
                desserts: [],
                status: [
                    {
                        id: 1,
                        title: 'موفق',
                        code: 'paid'
                    },
                    {
                        id: 2,
                        title: 'ناموفق',
                        code: 'failed'
                    },
                    {
                        id: 3,
                        title: 'معلق',
                        code: 'unknown'
                    }
                ],
                state: 'paid',
                orders: [],
                page: 1,
                total: 1,
            }
        },
        computed: {
            colorStatus() {
                if (this.state == 'paid') {
                    return 'success'
                } else if (this.state == 'unknown') {
                    return 'warning'
                } else {
                    return 'error'
                }
            }
        },
        created() {
            // this.headers = [
            //     // {
            //     //     text: 'شماره سفارش',
            //     //     align: 'start',
            //     //     sortable: false,
            //     //     value: 'transaction_code',
            //     // },
            //     {text: 'مراجعه کننده', value: 'user'},
            //     {text: 'ایمیل', value: 'email'},
            //     {text: 'موبایل', value: 'mobile'},
            //     {text: 'تاریخ خرید', value: 'date'},
            //     {text: 'ساعت خرید', value: 'time'},
            //     {text: 'محصول', value: 'packages'},
            //     {text: 'ارز', value: 'currency'},
            //     {text: 'مبلغ پرداختی', value: 'final_amount'},
            // ]

        },
        methods: {
            rejectPayment(item) {
                this.itemToChange = item;
                this.action = 'reject';
                this.showDialog = true;
            },
            acceptPayment(item) {
                this.itemToChange = item;
                this.action = 'accept';
                this.showDialog = true;
            },
            refundPayment() {
                switch (this.action) {
                    case 'accept':


                        this.$store.dispatch('adminAcceptRefundRequest', this.itemToChange.id).then((resp) => {
                            this.$gtm.trackEvent({
                                'event': 'eec.refund',
                                'ecommerce': {
                                    'refund': {
                                        'actionField': {'id': this.itemToChange.parent.transaction_code}         // Transaction ID. Required for purchases and refunds.
                                    }
                                }
                            });
                            this.showDialog = false;
                            EventBus.$emit('notify', 'green', undefined, 'با موفقیت اعمال شد');
                            this.getAdminRefundRequests();
                        }).catch(() => {
                            this.showDialog = false;
                        });
                        break;
                    case 'reject':
                        this.$store.dispatch('adminRejectRefundRequest', this.itemToChange.id).then((resp) => {
                            this.showDialog = false;
                            EventBus.$emit('notify', 'green', undefined, 'با موفقیت رد شد');
                            this.getAdminRefundRequests();
                        });
                        break;
                }
            },
            applyHeader(item) {
                console.log(item);
            },
            setOrder(data) {
                this.lockHeaders = true;
                this.sortBy = [data.header];
                this.sortDesc = [data.order];
            },
            customSort(inputs) {
                return inputs;
            },
            applyFilters(options) {

                this.page = 1;
                this.options = options;
                this.getAdminRefundRequests();
            },
            getAdminRefundRequests() {
                EventBus.$emit('setLoading', true);
                this.$store.dispatch('getAdminRefundRequests', {
                    page: this.page,
                    options: this.options
                }).then((data) => {

                    if (!this.options.hasOwnProperty('wantsExcel')) {
                        this.orders = data.data.payments;
                        this.total = data.data.pagination.total_pages;
                    } else {
                        delete this.options.wantsExcel;
                    }
                }).finally(() => {
                    EventBus.$emit('setLoading', false);
                })
            },

            selectStatus(el) {
                this.state = el.code;
                this.getAdminRefundRequests();
            },
            setAdminOrders(data) {
                this.orders = [];
                data.forEach(order => {
                    this.orders.push(order);
                    order['customer'] = order.user.firstname + ' ' + order.user.lastname;
                    order['date'] = order.jdatetime.split(' ')[0];
                    order['time'] = order.jdatetime.split(' ')[1];
                });
            },
            getClientProfile(id) {

                this.$router.push({name: 'clientDetail', params: {clientId: id}});
            },
        },
        watch: {
            sortBy(after, before) {
                if (!this.lockHeaders) {
                    if (before != after) {
                        if (this.sortDesc[0] == false) {
                            let obj = {
                                sortBy: after,
                                sortDesc: this.sortDesc,
                                prefix: 'financial'
                            };
                            EventBus.$emit('applySort', obj);
                        }
                    }
                }
                this.lockHeaders = false;
            },
            sortDesc(data) {
                if (!this.lockHeaders) {
                    let obj = {
                        sortBy: this.sortBy,
                        sortDesc: data,
                        prefix: 'financial'
                    };
                    EventBus.$emit('applySort', obj);
                }
                this.lockHeaders = false;
            },
            page() {
                this.getAdminRefundRequests();
                this.$vuetify.goTo(this.$refs.adminOrders.offsetTop);
            },
            '$route': function () {
                this.getAdminRefundRequests();
            }
        }
    }
</script>

<style scoped>

</style>
