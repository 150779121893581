<template>
    <v-container>
        <v-dialog v-model="walletModal" max-width="800">
            <AdminUserWallet v-if="walletModal" @close="walletModal = false" @done='updateUserInfo'
                             :userId="$route.params.clientId"></AdminUserWallet>
        </v-dialog>
        <v-row>
            <v-col>
                <v-card class="elevation-2">
                    <v-card-title>
                        اطلاعات کاربر

                        <v-spacer></v-spacer>
                        <template v-if="userHasRole('admin')">
                            <v-btn fab class="mx-1 primary" text small
                                   :loading="!clientIsLoaded"
                                   @click="$router.push({name:'adminClientCalendar',params:{lang:'fa',clientId:$route.params.clientId}})">
                                <v-icon> fas fa-calendar-alt</v-icon>
                            </v-btn>
                            <v-btn fab class="mx-1 primary" text small
                                   :loading="!clientIsLoaded"
                                   @click="openWalletModal">
                                <v-icon> mdi-wallet-plus</v-icon>
                            </v-btn>
                            <ChangePassword :userId="$route.params.clientId"></ChangePassword>
                        </template>
                    </v-card-title>
                    <v-card-title class="pa-0 ma-0">
                        <v-spacer></v-spacer>
                        <v-chip class="mx-1"
                                :class="{'white--text red' : walletItem.balance <0,'blue white--text':walletItem.balance>0}"
                                small
                                v-for="(walletItem,index) in wallet"
                                :key="walletItem"> {{index|resolveCurrency}}
                            {{walletItem.balance}}
                        </v-chip>
                    </v-card-title>

                    <v-card-text>
                        <ClientProfileComponent></ClientProfileComponent>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn class="success px-10" @click="saveProfile">ثبت</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import ClientProfileComponent from '@/components/profiles/components/ClientProfileComponent.vue';
    import {EventBus} from '../../event-bus.js'
    import ChangePassword from '@/components/ChangePassword';
    import {mapGetters} from 'vuex';
    import AdminUserWallet from '@/components/Admin/user/AdminUserWallet';
    import moment from 'moment-timezone';
    import {resolveCurrency} from "../../utilities";

    export default {
        computed: {
            ...mapGetters(['userHasRole'])
        },
        components: {
            ClientProfileComponent,
            ChangePassword,
            AdminUserWallet
        },
        created() {
            EventBus.$on('clientProfileUpdated', this.updateClientInfo);
        },
        beforeDestroy() {
            EventBus.$off('clientProfileUpdated', this.updateClientInfo);
        },
        data() {
            return {
                wallet: [],
                walletModal: false,
                clientIsLoaded: false,
                profile: null
            }
        },

        methods: {
            updateUserInfo() {
                this.walletModal = false;
                this.clientIsLoaded = false;
                EventBus.$emit('setLoading', true);
                this.$store.dispatch('getUserProfile', {
                    userid: this.$router.currentRoute.params.clientId
                }).then((data) => {
                    this.client = data.data.client;
                    this.client.birthDate = moment(this.userInfo.birthDate, 'YYYY-MM-DD').format('YYYY-MM-DD');
                }).finally(() => {
                    this.clientIsLoaded = true;
                    EventBus.$emit('setLoading', false);
                });
            },
            openWalletModal() {
                this.walletModal = true;
            },
            updateClientInfo() {
                let client = this.$store.getters.getClientProfile;
                this.profile = client;
                this.clientIsLoaded = true;
            },

            saveProfile() {
                EventBus.$emit('saveProfileEvent');
            },
            resetProfile() {
                EventBus.$emit('resetProfileEvent');
            }
        },
        filters: {
            resolveCurrency(input) {
                return resolveCurrency(input);

            }
        }


    }
</script>

<style scoped>

</style>