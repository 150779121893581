<template>
    <div>
        <v-container>
            <v-card class="my-8">
                <v-card-title>
                    {{ title }}
                    <!--<v-spacer></v-spacer>-->
                    <!--<v-text-field-->
                    <!--solo-->
                    <!--v-model="search"-->
                    <!--append-icon="mdi-magnify"-->
                    <!--label="جستجو"-->
                    <!--single-line-->
                    <!--hide-details-->
                    <!--&gt;</v-text-field>-->
                </v-card-title>
                <v-card-text>
                    <TableOptions :searchFilters="searchFilters" @applyFilters="applyFilters"></TableOptions>
                </v-card-text>
                <v-data-table
                        hide-default-footer
                        :headers="headers"
                        :items="requests"
                        :search="search"
                >
                    <template v-slot:item.action="{ item }">
                        <v-btn color="primary" @click="requestDetail(item)">
                            جزییات
                        </v-btn>
                    </template>
                </v-data-table>
            </v-card>
            <div class="text-center" v-if="!fiveSession">
                <v-pagination
                        v-model="page"
                        :length="total"
                ></v-pagination>
            </div>
        </v-container>
        <v-row v-if="request != null">
            <v-dialog
                    v-model="dialog"
                    max-width="450"
            >
                <v-card class="d-flex flex-column justify-center align-center">
                    <v-card-title>درخواست {{ request.type }} از {{ request.user.full_name }}</v-card-title>

                    <v-card-text>
                        {{ request.description }}
                    </v-card-text>

                    <v-card-actions>
                        <!--                        <v-spacer></v-spacer>-->

                        <v-btn
                                dark
                                color="red darken-1"
                                @click="dialog = false"
                        >
                            بستن
                        </v-btn>

                        <v-btn
                                dark
                                color="green darken-1"
                                @click="requestUpdate"
                                :loading="loading"
                        >
                            تایید
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';
    import {adminTickets} from "../searchFilters";
    import TableOptions from '@/components/TableOptions.vue';

    export default {
        components: {
            TableOptions
        },
        name: 'requests',
        props: {
            title: {
                default: 'درخواست ها',
                type: String
            },
            fiveSession: Boolean
        },
        data() {

            return {
                options: {},
                requesting: false,
                searchFilters: [],
                total: 1,
                page: 1,
                loading: false,
                loadingBtn: false,
                dialog: false,
                search: '',
                headers: [
                    {
                        text: 'نام و نام خانوادگی',
                        align: 'start',
                        sortable: false,
                        value: 'user.full_name',
                    },
                    // {text: 'مشاور', value: 'counselor'},
                    // {text: 'دلیل درخواست', value: 'desc'},
                    {text: 'تاریخ', value: 'date'},
                    {text: 'ساعت', value: 'time'},
                    {text: 'نوع درخواست', value: 'type'},
                    {text: '', value: 'action'},
                ],
                desserts: [],
                request: null,
                requests: []
            }
        },
        computed: {
            ...mapGetters({
                // requests: 'getAdminRequests',
                // page: 'page',
                // total: 'total',
            })
        },
        created() {
            this.getAdminRequests();
            this.searchFilters = adminTickets;
        },
        methods: {
            applyFilters(options) {
                this.page = 1;
                this.options = options;
                this.getAdminRequests();
            },
            getAdminRequests() {

                this.$store.dispatch('getAdminRequests', {page: this.page, options:this.options}).then((data) => {
                    this.requests = data.data.tickets;
                    this.total = data.data.pagination.total_pages;
                })
            },
            // nextPage() {
            //     this.$store.dispatch('pageChangeHandle', {value: 'next', page: this.page, url: '/admin/users/requests', commitName: 'setAdminRequests'});
            //     console.log('page', this.page)
            // },
            // previousPage() {
            //     this.$store.dispatch('pageChangeHandle', {value: 'previous', page: this.page, url: '/admin/users/requests', commitName: 'setAdminRequests'});
            //     console.log('page', this.page)
            // },
            // specificPage(page) {
            //     this.$store.commit('SET_SPECIFIC_PAGE', page);
            //     this.$store.dispatch('pageChangeHandle', {value: '', page: page, url: '/admin/users/requests', commitName: 'setAdminRequests'});
            //     console.log('page', page)
            // },
            requestDetail(item) {
                this.dialog = true;
                this.request = item;
                // console.log('request', this.request)
            },
            requestUpdate() {
                this.loading = true;
                this.$store.dispatch('updateTicketState', this.request.id).then(() => {
                    this.dialog = false;
                    this.requests.splice(this.requests.indexOf(this.request), 1);
                }).finally(() => {
                    this.loading = false;
                })

            }
        },
        watch: {
            page() {

                this.getAdminRequests();
                // this.$vuetify.goTo(this.$refs.adminSessions.offsetTop);
            }
        }
    }
</script>

<style scoped>

</style>
