<template>

    <v-container fluid class="mt-sm-0 pt-sm-0 grey lighten-5" align="center" justify="center" style="height:100%!important;">
        <v-row class="fill-height align-center justify-center bg-login" justify="center" align="center" style="height:100%!important;">
            <v-col cols="12" sm="9" lg="4" md="6">
                <v-card class="pa-3 elevation-1">
                    <v-tabs centered v-model="window" grow>
                        <v-tabs-slider></v-tabs-slider>
                        <v-tab :key="1" href="#login" :class="{'primary--text selected-border':window=='login'}">
                            {{$vuetify.lang.t('$vuetify.loginLabel')}}
                        </v-tab>
                        <v-tab :key="2" href="#register" :class="{'primary--text selected-border':window=='register'}">
                            {{$vuetify.lang.t('$vuetify.signUpLabel')}}
                        </v-tab>

                        <v-tab-item
                                :value="'login'"
                        >
                            <v-card
                                    flat
                                    tile
                            >
                                <LoginComponent :email="email"></LoginComponent>
                            </v-card>
                        </v-tab-item>


                        <v-tab-item
                                :value="'register'"
                        >
                            <v-card
                                    flat
                                    tile
                            >
                                <SignupComponent @successfullyRegistered="fillLogin"
                                ></SignupComponent>
                            </v-card>
                        </v-tab-item>
                    </v-tabs>
                </v-card>

            </v-col>
            <!--<v-spacer></v-spacer>-->
            <!--<v-col cols="12" lg="5" md="6" :class="{'d-none': isMobile}">-->
            <!--<div class="text-center" :class="{'big-banner': !isMobile}">-->
            <!--<p>-->
            <!--لبت خندون، دلت آروم<br>با سیمیاروم-->
            <!--</p>-->
            <!--</div>-->
            <!--<hr class="ma-3"/>-->
            <!--<div class="text-center">-->
            <!--<p class="ma-2">-->
            <!--مشاوره آنلاین روانشناسی با برترین متخصصان ایرانی-->
            <!--</p>-->
            <!--</div>-->
            <!--</v-col>-->
        </v-row>
    </v-container>
    <!--            </v-parallax>-->

</template>
<script>
    import LoginComponent from '@/components/LoginComponentHome.vue'
    import SignupComponent from '@/components/SignupComponentHome.vue'

    export default {
        components: {
            LoginComponent,
            SignupComponent
        },
        data() {
            return {
                inviter: null,
                email: null,
                window: 1,
                pageHeight: 'auto',
                isMobile: false
            }
        },
        computed: {
            form() {
                return {
                    // name: this.name,
                    // address: this.address,
                    // city: this.city,
                    // state: this.state,
                    // zip: this.zip,
                    // country: this.country,
                    // email: this.email
                }
            },
        },
        watch: {
            pageHeight() {

            },
        },
        created() {
            // if (this.$route.query.hasOwnProperty('inviter')) {
            //     this.$store.dispatch('setInviter', this.$route.query.inviter);
            //     // this.setCookie('inviter', this.$route.query.inviter, 10);
            //     // this.inviter = this.$route.query.inviter;
            // }

            window.addEventListener("resize", this.myEventHandler);

            if (window.innerWidth < 500) {
                this.isMobile = true;
            }
        },
        destroyed() {
            window.removeEventListener("resize", this.myEventHandler);
        },

        methods: {

            setCookie(cname, cvalue, exdays) {
                var d = new Date();
                d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
                var expires = "expires=" + d.toUTCString();
                document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/;";
            },
            fillLogin() {
                this.window = 1;
            },
            myEventHandler() {
                this.pageHeight = window.innerHeight - 100;

                if (window.innerWidth < 500) {
                    this.isMobile = true;
                }
            },

        },
        mounted() {
            this.pageHeight = window.innerHeight - 100;
        }
    }
</script>
<style>
    /*.yekan-font {*/
    /*font-family: YekanWeb !important;*/
    /*}*/
    .big-banner {
        font-size: 3em;
    }

    .bg-login {
        display: flex;
        justify-content: center;
        align-items: center;
        background-image: url("./../assets/loginbg.jpg");
        /*background-image: linear-gradient(to right, rgba(67, 55, 140, 0.7), rgba(134, 194, 59, 0.3)), url("./../assets/loginbg.jpg");*/
        /*background-image: linear-gradient(to right, rgba(67, 55, 140, 0.7), rgba(134, 194, 59, 0.3)), url("../assets/login-2.jpg");*/
        background-position: center;
        background-size: cover;
        min-height: 93vh;
        color: white;
        padding: 2%;
    }

    .selected-border {
        border: 1px solid rgba(0, 0, 150, .2);
        border-top-left-radius: 2px;
        border-top-right-radius: 2px;
    }
</style>