/*eslint-disable*/
class Tracker {
    userData = {};
    user_id = null;
    user_token = null;
    record_type = 'VISIT';//enum('REGISTRATION', 'BUYING', 'VISIT')
    shopping_cart_id = null;
    shopping_cart_amount = 0;

    constructor(record_type='VISIT'  ,user_id=null ,shopping_cart_id=1 , shopping_cart_amount=0,shoppng_cart_currency='USD') {
        this.record_type =record_type;
        this.user_id =user_id;
        this.shopping_cart_id =shopping_cart_id;
        this.shopping_cart_amount =shopping_cart_amount;
        this.shoppng_cart_currency =shoppng_cart_currency;
    }



    prepareData() {
        this.user_token = Date.now();
        this.getReferer();
        this.getCurrentPage();
        this.getConvincepage();
        // console.warn(userData);
        if (this.userData['type'] === undefined) {
            this.userData['type'] = 'DIRECT'
        }
    }

    getCurrentPage() {
        //best way to get refere page
        var url = this.getUrl();

        var text = this.extractUrlBasicData(url);
        // console.warn(text);
        // var qstring = decodeURIComponent(text['search']);
        var data = text['searchObject'];

        if (data['utm_source'] !== undefined)
            this.userData['utm_source'] = data['utm_source'];
        else
            this.userData['utm_source'] = "";
        if (data['utm_campaign'] !== undefined)
            this.userData['utm_campaign'] = data['utm_campaign'];
        else
            this.userData['utm_campaign'] = "";
        if (data['utm_medium'] !== undefined)
            this.userData['utm_meidum'] = data['utm_medium'];
        else
            this.userData['utm_meidum'] = "";
        this.userData['landing_page'] = text['scheme'] + "://" + text['host'] + text['path'];
        if (this.userData['utm_source'] || data['utm_campaign'] || data['utm_term'] || data['utm_content'] || data['gclid']) {
            this.userData['type'] = 'CAMPAGIN';
        }
    }


    getUrl() {
        return window.location.href;
    }

    getConvincepage() {

//        //best way to get refere page
        var url = this.getUrl();
        var text = this.extractUrlBasicData(url);

        this.userData['convince_page'] = text['scheme'] + "://" + text['host'] + text['path'];
    }

    parseQuery(str) {
        if (typeof str != "string" || str.length == 0) return {};
        var s = str.split("&");
        var s_length = s.length;
        var bit, query = {}, first, second;
        for (var i = 0; i < s_length; i++) {
            bit = s[i].split("=");
            first = decodeURIComponent(bit[0]);
            if (first.length == 0) continue;
            second = decodeURIComponent(bit[1]);
            if (typeof query[first] == "undefined") query[first] = second;
            else if (query[first] instanceof Array) query[first].push(second);
            else query[first] = [query[first], second];
        }
        return query;
    }

    getReferer() {
        var url = document.referrer;
        if (!url) {
            this.userData['type'] = 'DIRECT';
            this.userData['refer'] = "";
            return;
        }
        var text = this.extractUrlBasicData(url);

        this.userData['full_url'] = url;
        var qstring = decodeURIComponent(text['query']);
        var data = this.parseQuery(qstring);
        this.userData['query'] = text['search'];

        this.userData['oquery'] = "";
        this.userData['refer'] = text['scheme'] + "://" + text['host'] + text['path'];
        // if (this.userData['query']) {
        if (url.includes('https://www.google.')) {
            this.userData['type'] = 'ORGANIC'
        } else {
            this.userData['type'] = 'HOST'
        }
    }

    extractUrlBasicData(url) {
        var text = this.parseURL(url);
        if (text['scheme']) {
            text['scheme'] = text['scheme'];
        } else {
            text['scheme'] = 'http'
        }
        if (text['host']) {
            text['host'] = text['host'];
        } else {
            text['host'] = ''
        }
        if (text['path']) {
            text['path'] = text['path'];
        } else {
            text['path'] = ''
        }
        // text['scheme'] = (array_key_exists('scheme' ,text))?text['scheme']:'http';
        // text['host'] = (array_key_exists('host' ,text))?text['host']:'';
        // text['path'] = (array_key_exists('path' ,text))?text['path']:'';

        return text;
    }

    parseURL(url) {
        var parser = document.createElement('a'),
            searchObject = {},
            queries, split, i;

        // console.error(parser);
        // Let the browser do the work
        parser.href = url;
        // Convert query string to object
        queries = parser.search.replace(/^\?/, '').split('&');
        for (i = 0; i < queries.length; i++) {
            split = queries[i].split('=');
            searchObject[split[0]] = split[1];
        }
        return {
            scheme: parser.protocol,
            host: parser.host,
            hostname: parser.hostname,
            port: parser.port,
            path: parser.pathname,
            search: parser.search,
            searchObject: searchObject,
            hash: parser.hash
        };
    }

    getCookie(cname) {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    setcookie(cname, value, expire, path) {

        var d = new Date();
        d.setTime(d.getTime() + (expire * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + value + ";" + expires + ";path=" + path + "; Domain=." + location.hostname;
    }

    handLeCookie() {
        this.userData['user_token'] = this.user_token;
        var res = JSON.stringify(this.userData);

        var oldDataStr = this.getCookie('user_tracker_G!@*');

        var oldData = "";
        if (oldDataStr && oldDataStr !== "") {

            oldData = JSON.parse(oldDataStr);
            // console.error(oldData);
        }

        if (oldData !== "") {
            // console.warn(oldData);
            this.user_token = oldData['user_token'];
            this.userData['user_token'] = this.user_token;
            res = JSON.stringify(this.userData);
            if (this.userData['type'] !== 'DIRECT') {
                this.setcookie('user_tracker_G!@*', res, 1000, '/');
                return;
            } else {
                oldData['convince_page'] = this.userData['convince_page'];
                res = JSON.stringify(oldData);
                this.setcookie('user_tracker_G!@*', res, 1000, '/');
                return;
            }
            return;
        } else {
            // console.warn(this.userData);
            res = JSON.stringify(this.userData);
            this.setcookie('user_tracker_G!@*', res, 1000, '/');
            return;
        }

    }


    handleDb() {

        //$user = Users::currentUser();
        var oldDataStr = this.getCookie('user_tracker_G!@*');
        var oldData = this.userData;
        if (oldDataStr !== "")
            oldData = JSON.parse(oldDataStr);


        /**
         * for now only
         * @type {string}
         */
        oldData['record_type'] = this.record_type;
        oldData['shopping_cart_id'] = this.shopping_cart_id;
        oldData['shopping_cart_amount'] = this.shopping_cart_amount;
        oldData['shoppng_cart_currency'] = this.shoppng_cart_currency;
        oldData['user_id'] = this.user_id;
        /**
         * for now only
         * @type {string}
         */


        var xmlHttp = new XMLHttpRequest();
        xmlHttp.onreadystatechange = function()
        {
            if(xmlHttp.readyState == 4 && xmlHttp.status == 200)
            {

                console.warn(xmlHttp.responseText);
            }
        }

        xmlHttp.open("post", "https://tracker.simiaroom.com/new_log");
        xmlHttp.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xmlHttp.send('myArray='+JSON.stringify(oldData));

        // $.post("https://tracker.simiaroom.com/new_log",
        // // $.post("http://tracker/new_log",
        //     oldData,
        //     function (data, status) {
        //         console.warn("Data: " + data + "\nStatus: " + status);
        //     });
        //
        // // this.setcookie('user_tracker_G!@*', res, -1, '/');
        return true;
        return false;
    }

    dispatch() {
        this.prepareData();
        if (this.userData['refer'] && this.userData['refer'].includes(location.hostname.replace('www.', '')))
            this.userData['type'] = 'DIRECT';
        else if (!this.userData['refer'])
            this.userData['type'] = 'DIRECT';

        console.warn(this.userData);
        this.handLeCookie();
        this.handleDb();

    }
}

export default Tracker;