<template>
    <div>
        <v-window v-model="step" :reverse="this.$store.getters.isRTL">
            <v-window-item :value="1">
                <v-card-text class="pa-0 ma-0">
                    <v-form ref="signupform" @keyup.native.enter="register">
                        <div class="py-2" style="font-size: 1.2em;">ایمیل</div>
                        <v-text-field
                                hide-details
                                @keydown.enter.prevent='register'
                                v-model="mutableEmail"
                                type="email"
                                :label="$vuetify.lang.t('$vuetify.emailLabel')"
                                required
                                outlined
                                :hint="$vuetify.lang.t('$vuetify.emailSignUpCaption')"

                                :rules="emailRules">
                        </v-text-field>
                        <div class="py-2" style="font-size: 1.2em;">موبایل</div>
                        <v-row dense>


                            <v-col cols="4" order="1">
                                <v-autocomplete
                                        hide-details
                                        id="ccode"
                                        @keydown.enter.prevent='register'
                                        placeholder="کد کشور"
                                        v-model="selectedCountry"
                                        :items="countries"
                                        outlined
                                        :filter="phoneFilter"
                                        item-text="title"
                                        return-object
                                        required
                                        :rules="requiredRule"
                                >
                                    <template v-slot:item="{item}">
                                        <country-flag :country='item.shortCode'></country-flag>
                                        <span>{{item.title}}</span>
                                    </template>
                                    <template v-slot:selection="{item}">
                                        <v-spacer></v-spacer>
                                        <span style="direction: ltr">{{item.code}}</span>
                                        <!--<country-flag :country='item.shortCode' size='normal'></country-flag>-->
                                    </template>
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="8" dense>
                                <v-text-field
                                        hide-details
                                        order="2"
                                        prepend-inner-icon="phone"
                                        type="text"
                                        single-line
                                        v-model="phone"
                                        :label="$vuetify.lang.t('$vuetify.phoneLabel')"
                                        class="text-left"
                                        style="text-align: left;direction: ltr;"
                                        outlined
                                        placeholder="ex : (555) 123 4567"
                                        :rules="mobileRules"
                                >
                                    <!--<template v-slot:prepend>-->
                                    <!---->
                                    <!--</template>-->
                                </v-text-field>
                            </v-col>


                        </v-row>
                        <div class="py-2" style="font-size: 1.2em;">کلمه عبور</div>
                        <v-text-field
                                :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                                @click:append="showPass = !showPass"
                                :type="showPass ? 'text' : 'password'"
                                v-model="password"
                                :label="$vuetify.lang.t('$vuetify.passwordLabel')"
                                outlined
                                :rules="passwordRules"

                        ></v-text-field>
                        <div class="py-2" style="font-size: 1.2em;">تکرار کلمه عبور</div>
                        <v-text-field

                                :append-icon="showConfirmPass ? 'mdi-eye' : 'mdi-eye-off'"
                                @click:append="showConfirmPass = !showConfirmPass"
                                :type="showConfirmPass ? 'text' : 'password'"
                                v-model="confirmPassword"
                                :label="$vuetify.lang.t('$vuetify.passwordConfirmationLabel')"
                                outlined
                                :rules="passwordConfirmRules"
                        ></v-text-field>
                        <!--<span class="caption grey&#45;&#45;text text&#45;&#45;darken-1">-->
                        <!--{{$vuetify.lang.t('$vuetify.passwordSignUpCaption')}}-->
                        <!--</span>-->
                        <!--<vue-phone-number-input-->
                        <!--no-validator-state-->
                        <!--:translations="translations"-->
                        <!--no-use-browser-locale-->
                        <!--clearable-->
                        <!--v-model="phone">-->
                        <!--</vue-phone-number-input>-->
                        <template v-if="inviter!=null">
                            <div class="px-2">
                            کد معرف شما :
                            <v-chip small class="blue white--text">{{inviter}}</v-chip>
                            </div>
                        </template>

                        <v-checkbox v-model="privacyPolicy" class="text-center">
                            <template v-slot:label>
                                <div>
                                    <a
                                            target="_blank"
                                            style="text-decoration: none;"
                                            href="https://simiaroom.com/%D8%B4%D8%B1%D8%A7%DB%8C%D8%B7-%D8%B6%D9%88%D8%A7%D8%A8%D8%B7-%D9%85%D8%B4%D8%A7%D9%88%D8%B1%D9%87/"
                                            @click.stop
                                    >
                                        شرایط و ضوابط
                                    </a>
                                    را خوانده و میپذیرم.
                                </div>
                            </template>
                        </v-checkbox>
                    </v-form>
                </v-card-text>
                <!--<v-card-text v-show="registerErr.show" class="red&#45;&#45;text text-center">{{ registerErr.msg }}</v-card-text>-->
            </v-window-item>
        </v-window>

        <v-divider></v-divider>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                    color="primary"
                    @click="register"
                    block
                    height="60"
            >
                ثبت نام
            </v-btn>
        </v-card-actions>
    </div>
    <!--</v-card>-->
</template>
<script>
    /*eslint-disable*/
    import {parsePhoneNumberFromString, AsYouType} from 'libphonenumber-js'
    import {EventBus} from "../event-bus";
    import Tracker from '../tracker';
    import {countriesCodes} from '../countriesCodes';

    export default {
        data() {
            return {
                selectedCountry: null,
                countries: countriesCodes,

                inviter: null,
                privacyPolicy: false,
                phone: '',
                password: "",
                confirmPassword: "",
                emailRules: [
                    v => !!v || this.$vuetify.lang.t('$vuetify.emailErrRequired'),
                    v => /.+@.+\..{2}/.test(v) || this.$vuetify.lang.t('$vuetify.emailErrValid'),
                ],
                showPass: false,
                showConfirmPass: false,
                registerErr: {
                    show: false,
                    msg: '',
                },
                mutableEmail: '',
            }
        },

        props: {
            email: String,

            step: {
                type: Number,
                default: 1
            },
            // inviter: {
            //     default: null
            // }
        },

        created() {

            // this.countries = countryCodes;
            this.mutableEmail = this.email;
            this.inviter = this.$store.getters.getInviter;
        },

        watch: {
            phone() {
                // let p = this.phone;

                // if (this.phone.startsWith('00')) {
                //     this.phone = this.phone.substr(2);
                //     this.phone = '+' + this.phone;
                // }
                //
                // if (this.phone.startsWith('098')) {
                //     this.phone = this.phone.substr(1);
                //     this.phone = '+' + this.phone;
                // }
                //
                // if (this.phone.startsWith('09')) {
                //     this.phone = this.phone.substr(1);
                //     this.phone = '+98' + this.phone;
                // }
                if (this.phone.startsWith(0)) {
                    this.phone = this.phone.replace(0, '');
                }

                let x = new AsYouType().input(this.selectedCountry.code + this.phone);
                x = x.replace(this.selectedCountry.code, '');
                this.phone = x;

            }
        },
        filters: {},
        computed: {
            requiredRule() {
                return [
                    v => !!v || 'کد کشور را انتخاب نمایید'
                ];
            },
            passwordRules() {
                return [
                    v => !!v || this.$vuetify.lang.t('$vuetify.passwordErrRequired'),
                    v => v.length >= 8 || 'حداقل طول کلمه عبور 8 کاراکتر می باشد'
                ];
            },
            mobileRules() {
                return [
                    v => !!v || this.$vuetify.lang.t('$vuetify.passwordErrRequired'),
                ];
            },
            passwordConfirmRules() {
                return [
                    v => !!v || 'پر کردن فیلد تکرار پسورد الزامیست',
                    v => (v === this.password) || 'کلمه عبور و تکرار آن یکسان نمی باشند'
                ];
            },
            currentTitle() {
                switch (this.step) {
                    case 1:
                        return this.$vuetify.lang.t('$vuetify.signUpLabel')
                    case 2:
                        return this.$vuetify.lang.t('$vuetify.setAPassword')
                    default:
                        return 'Account created'
                }
            },
        },
        methods: {
            isCodeAutoComplete(event) {
                return event.target.id == 'ccode';
            },
            phoneFilter(item, queryText, itemText) {

                if (item == null) {
                    return false;
                }
                if (item.header) {
                    return false;
                }

                const hasValue = val => val != null ? val : '';

                const text = hasValue(itemText);
                const code = hasValue(item.code);
                const query = hasValue(queryText);

                return text.toString()
                    .toLowerCase()
                    .indexOf(query.toString().toLowerCase()) > -1 || code.indexOf(query.toString().toLowerCase()) > -1;
            },
            register(event) {

                if (this.isCodeAutoComplete(event)) {
                    return;
                }

                if (this.$refs.signupform.validate()) {

                    let phone = this.selectedCountry.code + this.phone.replace(' ', '');
                    const phoneNumber = parsePhoneNumberFromString(phone);
                    if (phoneNumber) {

                        if (!phoneNumber.isValid()) {

                            EventBus.$emit('notify', 'red', undefined, 'شماره تلفن معتبر نیست.');
                            return;
                        }

                    } else {
                        EventBus.$emit('notify', 'red', undefined, 'شماره تلفن معتبر نیست.');
                        return;
                    }

                    if (!this.privacyPolicy) {
                        EventBus.$emit('notify', 'red', undefined, 'موافقت با شرایط و ضوابط الزامیست.');
                        return;
                    }

                    if (this.confirmPassword === this.password) {

                        let newUser = {
                            email: this.mutableEmail,
                            password: this.password,
                            password_confirmation: this.confirmPassword,
                            mobile: phoneNumber.number,
                        };

                        if (this.inviter != null) {
                            newUser.inviter = this.inviter;
                        }
                        // if(this.$router.currentRoute.name == 'preorder') localStorage.setItem('preorder', this.$route.params.orderId);
                        EventBus.$emit('setLoading', true);
                        this.$store.dispatch('register', newUser).then((data) => {

                            this.$store.dispatch('removeInviter');
                            EventBus.$emit('notify', 'green', 6000, 'ثبت نام با موفقیت انجام شد');
                            this.$emit('successfullyRegistered');
                            if (this.$router.currentRoute.name == 'preorder') {
                                this.$emit('userLoggedInAfterRegister', newUser);

                            } else {
                                this.$store.commit('setSnackbar', {show: true, type: 'checkEmail'});
                            }
                            new Tracker("REGISTRATION", data.data.userId).dispatch()
                        }).catch((err) => {
                            console.log('sign up err', err.response.status);
                            if (err.response.status) {
                                this.registerErr = {
                                    show: true,
                                    msg: 'ایمیل وارد شده تکراری است'
                                }
                            }
                            // else if (500 <= err.response.s < 600) {
                            //     this.$store.commit('setSnackbar', {show: true, type: 'err500'});
                            // }
                        }).finally(() => {
                            EventBus.$emit('setLoading', false);
                        })
                    } else if (this.confirmPassword !== this.password) {
                        this.registerErr = {
                            show: true,
                            msg: 'کلمه عبور را با دقت وارد کنید'
                        }
                    }
                } else {
                    return;
                }


                // if (this.$el.nodeName.toLowerCase() === "form") {
                //     this.$el.onsubmit = cb;
                // } else {
                //     const forms = this.$el.getElementsByTagName("form");
                //     for (let i = 0; i < forms.length; i++) {
                //         const form = forms[i];
                //         if (form) form.onsubmit = cb;
                //     }
                // }


            },
            back() {
                this.step--;
                this.registerErr = {
                    show: false,
                    msg: ''
                }
            }
        },
    }
</script>
<style>

</style>