<template>
    <div>
        <template>
            <v-data-table
                    class="elevation-0 pa-2 ma-0"
                    dense
                    :headers="headers"
                    :items="prices"
                    :items-per-page="5"
                    hide-default-footer
            >
                <template v-slot:item.currency="{item}">
                    {{item.currency | resolveCurrency}}
                </template>

                <template v-slot:item.price="{item}">
                    <!--<template v-if="item.currency=='دلار'">-->
                        <!--{{item.price}}-->
                    <!--</template>-->
                    <!--<template v-else>-->
                        {{Number(item.price).toLocaleString()}}
                    <!--</template>-->

                </template>

            </v-data-table>
        </template>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';
    import {resolveCurrency} from "../../utilities";
    export default {
        props: ['allPrices'],
        created() {
            this.prices = this.allPrices;
        },
        computed:{
            ...mapGetters({
                currencies:'getCurrencies'
            })
        },

        data() {
            return {
                prices: [],
                priceToAdd: {
                    currency: null,
                    price: null
                },
                // currencies: [
                //     {title: 'تومان', value: 'rial'},
                //     {title: 'دلار', value: 'dollar'},
                // ],

                headers: [
                    {text: 'نوع ارز', value: 'currency'},
                    {text: 'مبلغ', value: 'price'},
                ],
            }
        },
        filters: {
            resolveCurrency(input) {
                return resolveCurrency(input);
                // return input == 'rial' ? 'تومان' : 'دلار';
            }
        },

    }
</script>

<style scoped>

</style>