<template>
    <div>

        <v-container>
            <v-card class="my-8">

                <v-card-title ref="adminClients">
                    {{ title }}
                    <v-spacer></v-spacer>
                    <v-btn color="primary" class="mt-5" :to="{name: 'addNewClient'}">افزودن مراجع جدید</v-btn>
                    <!--<v-spacer></v-spacer>-->
                    <!--<v-text-field-->
                    <!--solo-->
                    <!--v-model="search"-->
                    <!--append-icon="mdi-magnify"-->
                    <!--label="جستجو"-->
                    <!--single-line-->
                    <!--hide-details-->
                    <!--&gt;</v-text-field>-->
                </v-card-title>
                <v-card-text>
                    <TableOptions
                            :hasExport="true"
                            :searchFilters="searchFilters"
                            @applyFilters="applyFilters"
                            :hasSepidarExport="true"
                            :custom="'user-join'"
                    ></TableOptions>
                </v-card-text>

                <v-data-table
                        hide-default-footer
                        :headers="headers"
                        :items="clients"
                        :search="search"

                        mobile-breakpoint="300"
                >
                    <template v-slot:item.full_name="{ item }">

                        <div style="position: relative;">
                            <div v-if="item.leadUser">
                                <a target="_blank" :href="'clients/'+item.id" class="lead-badge"
                                   style="text-decoration: none">lead</a>
                            </div>
                            <div v-if="item.newUser">
                                <a target="_blank" :href="'clients/'+item.id" class="new-user-badge"
                                   style="text-decoration: none">new</a>
                            </div>
                        </div>
                        <v-chip :href="'clients/'+item.id">
                            <a @click.prevent="getClientProfile(item.id)" :href="'clients/'+item.id"
                               style="text-decoration: none">{{ item.full_name}}</a>
                        </v-chip>

                    </template>
                    <template v-slot:item.action="{ item }">
                        <v-btn class="my-2" color="primary" @click="getClientProfile(item.id)" fab small>
                            <v-icon small>fas fa-user-alt</v-icon>
                        </v-btn>
                    </template>
                </v-data-table>
            </v-card>
            <div class="text-center" v-if="!fiveSession">
                <v-pagination
                        v-model="page"
                        :length="total"
                ></v-pagination>
            </div>
        </v-container>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'
    import {adminClients} from "../../searchFilters";
    import TableOptions from '@/components/TableOptions.vue';
    import {EventBus} from "../../event-bus";
    /*eslint-disable*/
    export default {
        components: {
            TableOptions
        },
        props: {
            // title: {
            //     default: 'مراجعان',
            //     type: String
            // },
            fiveSession: Boolean
        },
        data() {
            return {
                searchFilters: [],
                options: {},
                requesting: false,
                title: 'مراجعان',
                path: this.$router.currentRoute.name,
                // loading: true,
                search: '',
                headers: [
                    {
                        text: 'نام و نام خانوادگی',
                        align: 'start',
                        sortable: true,
                        value: 'full_name',
                    }, {
                        text: 'ایمیل',
                        align: 'start',
                        sortable: false,
                        value: 'email',
                    }, {
                        text: 'تلفن',
                        align: 'start',
                        sortable: false,
                        value: 'cel',
                    }, {
                        text: 'جلسات خریداری شده',
                        align: 'start',
                        sortable: false,
                        value: 'userPackageDetails.all',
                    }, {
                        text: 'جلسات برگزار شده',
                        align: 'start',
                        sortable: false,
                        value: 'userPackageDetails.used',
                    }, {
                        text: 'جلسات باقی مانده',
                        align: 'start',
                        sortable: false,
                        value: 'userPackageDetails.available',
                    }, {
                        text: 'درخواست ها',
                        align: 'start',
                        sortable: false,
                        value: 'userPackageDetails.request',
                    },
                    // {text: 'اطلاعات کاربر', value: 'action', sortable: false},
                ],
                desserts: [],
                clients: [],
                page: 1,
                total: 1
            }
        },
        computed: {
            ...mapGetters({
                // clients: 'getAdminUsers',
                // page: 'page',
                // total: 'total',
            }),
        },
        created() {
            this.searchFilters = adminClients;
            // this.getAdminUsers();
        },
        methods: {
            applyFilters(options) {
                this.page = 1;
                this.options = options;
                this.getAdminUsers();
            },
            getAdminUsers() {
                if (this.path == 'clients') {
                    // this.loading = true;

                    EventBus.$emit('setLoading', true);
                    this.$store.dispatch('getClientsList', {
                        page: this.page, options: {
                            type: 'patient',
                            ...this.options
                        }
                    }).then((data) => {
                        if (data != undefined) {
                            this.total = data.data.pagination.total_pages;
                            this.clients = data.data.users;
                        }

                    }).finally(() => {
                        EventBus.$emit('setLoading', false);
                    })
                } else if (this.path == 'nocycle') {
                    // this.loading = true;
                    // let options = {
                    //     session_state: "session_requested",
                    //     ...this.options
                    // };
                    EventBus.$emit('setLoading', true);
                    this.$store.dispatch('getClientsList', {
                        page: this.page, options: {
                            session_state: "session_requested",
                            ...this.options
                        }
                    }).then((data) => {
                        this.total = data.data.pagination.total_pages;
                        this.clients = data.data.users;
                        // this.setAdminClients(data);
                        // this.loading = false;
                    }).finally(() => {
                        EventBus.$emit('setLoading', false);
                    })
                } else if (this.path == 'notime') {
                    // this.loading = true;
                    this.title = 'تعیین وقت نشده ها';
                    // let options = {
                    //     "type": "patient",
                    //     "time_not_set": true
                    // };
                    this.$store.dispatch('getClientsList', {
                        page: this.page, options: {
                            "type": "patient",
                            "time_not_set": true,
                            ...this.options
                        }
                    }).then((data) => {

                        this.total = data.data.pagination.total_pages;
                        this.clients = data.data.users;
                        // this.loading = false;
                    })
                } else if (this.path == 'notconfirmtime') {
                    // this.loading = true;
                    this.title = 'تایید زمان نشده ها',
                        EventBus.$emit('setLoading', true);
                    this.$store.dispatch('getNotconfirmtimeUsers', {page: this.page}).then((data) => {
                        console.log('clients: ', data);
                        this.total = data.pagination.total_pages;
                        this.setAdminClients(data);
                        // this.loading = false;
                    }).finally(() => {
                        EventBus.$emit('setLoading', false);
                    })
                }
            },

            getClientProfile(id) {
                this.$router.push('clients/' + `${id}`);
            },
            setAdminClients(data) {
                this.clients = [];
                data.data.forEach(client => {
                    client['fullname'] = client.firstname + ' ' + client.lastname;
                    this.clients.push(client);
                });
            }
        },
        watch: {
            page() {
                this.getAdminUsers();
                this.$vuetify.goTo(this.$refs.adminClients.offsetTop);
            }
        }
    }
</script>

<style scoped>
    .lead-badge {
        font-size: .7em;
        cursor: pointer;
        font-weight: bolder;
        position: absolute;
        right: -5px;
        top: -5px;
        padding: 2px 3px;
        background-color: rgba(0, 0, 230, 1);
        color: white;
        border-radius: 5px;
        z-index: 10;
    }

    .new-user-badge {
        font-size: .7em;
        cursor: pointer;
        font-weight: bolder;
        position: absolute;
        right: -5px;
        top: -5px;
        padding: 2px 3px;
        background-color: rgba(230, 0, 0, 1);
        color: white;
        border-radius: 5px;
        z-index: 10;
    }
</style>