<template>
    <v-container v-if="session!= null">
        <CounselorClientProfile :client="clientStartSession" :cycles="[]"></CounselorClientProfile>
        <v-card>
            <v-card-title>
                دوره درمان
            </v-card-title>
            <v-card-text>
                <v-expansion-panels>
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            موضوع : {{session.cycle.heading}}
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <div>شرح :</div>
                            {{session.cycle.problem_description}}
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-card-text>
        </v-card>
        <v-alert
                dark
                dense
                class="primary mt-5 py-0 elevation-1"
        >
            <!--<v-row align="center">-->
            <!---->
            <!--<v-col class="grow">-->
            <!--تا {{session.diff_time}} دیگر جلسه ۴۵ دقیقه ای مشاوره شما با-->
            <!--<strong class="secondary&#45;&#45;text">{{ session.user.full_name }}</strong>-->
            <!--آغاز می شود. لطفا پیش از شروع جلسه، از کیفیت سرعت اینترنت خود اطمینان حاصل فرمایید.-->
            <!--</v-col>-->
            <!--</v-row>-->
            <v-row align="center">
                <template v-if="isPassed == 0">
                    <v-col class="grow">
                        تا {{session.diff_time}} دیگر مشاوره شما با <strong class="secondary--text">{{
                        session.user.full_name}}</strong> آغاز می شود. لطفا پیش از شروع جلسه، از کیفیت سرعت اینترنت
                        خود
                        اطمینان حاصل فرمایید.
                    </v-col>
                </template>
                <template v-if="isPassed == 1">
                    <v-col class="grow">
                        جلسه با <strong class="secondary--text">{{
                        session.user.full_name}}</strong>
                        در حال برگزاری می باشد.
                    </v-col>
                </template>
                <template v-if="isPassed == 2">
                    <v-col class="grow">
                        جلسه با <strong class="secondary--text">{{
                        session.user.full_name}}</strong>
                        برگزار شده است.
                    </v-col>
                </template>
            </v-row>
        </v-alert>
        <v-card align="center" class="py-5" v-if="session.state =='date_fixed'">
            <!--<v-card-text style="color: red">زمان شروع جلسه: {{ startSession.countDownDate }}</v-card-text>-->
            <!--            <stop-watch class="my-3" initial-minutes="120" initial-seconds="60"></stop-watch>-->
            <!--<Countdown-->
            <!--:starttime="startDateTime"-->
            <!--:endtime="endDateTime"-->
            <!--trans='{-->
            <!--"day":"Day",-->
            <!--"hours":"Hours",-->
            <!--"minutes":"Minuts",-->
            <!--"seconds":"Seconds",-->
            <!--"expired":"زمان جلسه گذشته است.",-->
            <!--"running":"جلسه آغاز شده است.",-->
            <!--"upcoming":"زمان باقیمانده تا شروع جلسه",-->
            <!--"status": {-->
            <!--"expired":"Expired",-->
            <!--"running":"Running",-->
            <!--"upcoming":"Future"-->
            <!--}}'-->
            <!--&gt;</Countdown>-->
            <!--            <v-btn>تست فنی</v-btn>-->
            <!--<v-card-text class="mt-8">برای شروع جلسه یکی از پلتفرم های زیر را انتخاب نمایید.</v-card-text>-->
            <!--<div class="text-center">-->
            <!--<v-btn class="ma-2" color="warning" dark @click="joinG2M" :loading="loadingG2M">-->
            <!--GO TO MEETING-->
            <!--</v-btn>-->
            <!--<v-btn class="ma-2" color="primary" @click="joinBBB" :loading="loadingBBB">-->
            <!--BBB-->
            <!--</v-btn>-->
            <!--</div>-->


            <v-row align="center" justify="end">
                <v-col class="text-center">
                    <EnterSessionBtn v-if="session != null" :session="session" :role="'doctor'"></EnterSessionBtn>
                </v-col>


            </v-row>

            <!--<Net :nonce="nonce"></Net>-->
        </v-card>
    </v-container>
</template>

<script>
    /*eslint-disable*/
    import CounselorClientProfile from '../clients/CounselorClientProfile';
    // import Countdown from "../Countdown";
    import moment from 'moment'
    import Net from "../Net";
    import EnterSessionBtn from "./EnterSessionBtn";
    // import {mapGetters} from 'vuex';

    export default {
        data() {
            return {
                isPassed: 0,
                currentDateTime: null,
                startDateTime: null,
                endDateTime: null,
                loadingG2M: false,
                loadingBBB: false,
                startSession: null,
                clientStartSession: null,
                nonce: null,
                session: null
            }
        },
        components: {
            CounselorClientProfile,
            // Countdown,
            Net,
            EnterSessionBtn
        },
        computed: {
            // ...mapGetters({
            // startSession: 'getStartSession',
            // clientStartSession: 'getClientStartSession'
            // })
        },
        created() {
            this.getSessionDetails();

            // this.getClient();
            // this.getCountdownInputs();
            // console.log('Counselor start session: ', this.startSession)
        },
        methods: {
            getSessionDetails() {
                this.$store.dispatch('getStartSession', this.$route.params.sessionId).then((resp) => {
                    this.clientStartSession = resp.data.session.user;
                    this.session = resp.data.session;

                    if (this.session.fixed_date_time_g != null) {
                        let sessionTime = moment(this.session.fixed_date_time_g, 'YYYY-MM-DD HH:mm');
                        let sessionOffset = moment(this.session.fixed_date_time_g, 'YYYY-MM-DD HH:mm');
                        sessionOffset.add(45, 'minutes');
                        let now = moment();
                        if (now.isBefore(sessionTime)) {
                            this.isPassed = 0;
                        } else if (now.isBetween(sessionTime,sessionOffset)) {
                            this.isPassed = 1;
                        } else if (now.isAfter(sessionOffset)) {
                            this.isPassed = 2;
                        }
                    }

                    // this.currentDateTime = moment().utc().format('YYYY/MM/DD HH:MM');
                    // this.startDateTime = moment(resp.data.session.fixed_date, 'jYYYY/jMM/jDD').format('YYYY/MM/DD HH:mm');
                    // console.log("----------------------START-SELECTION----------------------");
                    //
                    // console.log(moment(resp.data.session.fixed_date).format('YYYY/MM/DD HH:mm'));
                    // console.log("+-+-+-+-+-+-+-+-+-+-+-+-END-SELECTION+-+-+-+-+-+-+-+-+-+-+-");
                    // this.endDateTime = moment(this.startDateTime).add(45, 'm').toDate();

                });
            },
            getCountdownInputs() {
                this.$store.dispatch('getStartSession', this.$route.params.sessionId).then((data) => {
                    this.startSession = data.data;
                    this.currentDateTime = moment().utc().format('YYYY/MM/DD HH:MM');
                    this.startDateTime = this.startSession.countDownDate;
                    this.endDateTime = moment(this.startDateTime).add(45, 'm').toDate();
                    // this.getClient();

                    this.nonce = data.data.nonce;
                })
                // console.log('end', this.endDateTime);
            },
            getClient() {
                this.$store.dispatch('getClientStartSession', this.startSession.customerId).then((data) => {
                    this.clientStartSession = data.data;
                })
            },
            joinG2M() {
                this.loadingG2M = true;
                this.$store.dispatch('joinG2M', this.$route.params.sessionId).then((data) => {
                    window.open(data.data, '_blank');
                    this.loadingG2M = false;
                })
            },
            joinBBB() {
                this.loadingBBB = true;
                this.$store.dispatch('joinBBB', this.$route.params.sessionId).then((data) => {
                    window.open(data.data, '_blank');
                    this.loadingBBB = false;
                })
            }
        }
    }
</script>

<style>

</style>