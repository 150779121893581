<template>
    <v-container justify="center" align="center">
        <v-expansion-panels

        >
            <v-expansion-panel
                    v-for="(record,index) in records" :key="index"
            >
                <v-expansion-panel-header>

                    <v-row dense>
                        <v-col>پرداخت :
                            {{record.payment_data.final_amount}}
                        </v-col>
                        <v-col>تاریخ خرید :
                            {{record.payment_data.date}}
                        </v-col>
                        <v-col>
                            تعداد بسته ها :
                            {{record.packages.length}}
                        </v-col>
                    </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <!--v-for="(pack,index) in record.packages" :key="index"-->
                    <v-data-table
                            dense
                            :items="record.packages"
                            :headers="headers"
                            hide-default-footer
                    ></v-data-table>
                    <!--<v-row>-->
                    <!--<v-col>عنوان : {{pack.title}}</v-col>-->
                    <!--<v-col>استفاده شده : {{pack.used}}</v-col>-->
                    <!--<v-col>باقی مانده : {{pack.available}}</v-col>-->
                    <!--<v-col>همه جلسات : {{pack.all}}</v-col>-->
                    <!--</v-row>-->
                    <!--<v-divider></v-divider>-->
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>

        <v-row class="text-center" v-if="!fiveSession">
            <v-pagination
                    v-model="page"
                    :length="total"
            ></v-pagination>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        props: ['userId'],
        created() {
            this.getPurchasedPackages();
        },
        data() {
            return {
                page: 1,
                total: 1,
                records: []
            }
        },
        computed: {
            headers() {
                return [
                    {text: 'عنوان', value: 'title'},
                    {text: 'تعداد', value: 'count'},
                    {text: 'استفاده شده', value: 'used'},
                    {text: 'باقی مانده', value: 'available'},
                    {text: 'همه جلسات', value: 'all'},
                ]
            }
        },
        methods: {
            getPurchasedPackages() {
                this.$store.dispatch('getAdminClientPurchasedPackages', {
                    id: this.userId,
                    page: this.page
                }).then((res) => {
                    this.records = res.data.results;
                    this.total = res.data.total;
                })
            }
        },
        watch: {
            page() {
                this.getPurchasedPackages();
            }
        }
    }
</script>

<style scoped>

</style>